import ListItem from '@mui/material/ListItem'
import ListItemButton, { listItemButtonClasses } from '@mui/material/ListItemButton'
import ListItemText, { listItemTextClasses } from '@mui/material/ListItemText'
import { styled } from '@mui/material/styles'
import Switch from '@mui/material/Switch'
import Tooltip from '@mui/material/Tooltip'
import React from 'react'

import { ColumnPickerAPI } from './use-column-picker'

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  [`&.${listItemButtonClasses.disabled}`]: {
    opacity: 1,
    [`.${listItemTextClasses.primary}`]: {
      color: theme.palette.grey['300'],
    },
  },
}))

export interface ColumnPickerColumnListItemProps {
  name: string
  label: string
  api: ColumnPickerAPI
  activeFilterColumns: string[]
}

export default function ColumnPickerColumnListItem({
  name,
  label,
  api,
  activeFilterColumns,
}: ColumnPickerColumnListItemProps) {
  const isActiveFilterColumn = api.showColumnsForActiveFilters && activeFilterColumns.includes(name)
  const checked = api.isColumnVisible(name) || isActiveFilterColumn

  const handleToggleColumnVisibility = () => {
    if (api.isColumnVisible(name)) {
      api.hideColumn(name)
    } else {
      api.showColumn(name)
    }
  }

  return (
    <Tooltip
      placement={'top'}
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -20],
            },
          },
        ],
      }}
      title={isActiveFilterColumn && 'This column is automatically shown due to an active filter'}>
      <ListItem disablePadding>
        <StyledListItemButton
          disabled={isActiveFilterColumn}
          onClick={handleToggleColumnVisibility}
          disableRipple>
          <ListItemText primary={label} />
          <Switch
            checked={checked}
            color={'success'}
            disabled={isActiveFilterColumn}
            onChange={handleToggleColumnVisibility}
          />
        </StyledListItemButton>
      </ListItem>
    </Tooltip>
  )
}
