import SelectMenu from '../../select-menu/select-menu'

export interface ChartSizeTypeConfig<D> {
  id: string
  label: string
  getValue: (d: D) => number | null
  formatter: (value: number | null, item: D) => string
}

interface ChartSizeTypeSelectProps<Data> {
  options: ChartSizeTypeConfig<Data>[]
  value: ChartSizeTypeConfig<Data>
  onChange: (value: ChartSizeTypeConfig<Data>) => void
}

/**
 * Changes which sizing-type to use for a chart. This setting impacts the area
 * items take up in the chart.
 */
export default function ChartSizeTypeSelect<Data>({
  options,
  value,
  onChange,
}: ChartSizeTypeSelectProps<Data>) {
  return (
    <SelectMenu
      label={'Size'}
      onChange={onChange}
      options={options.map((option) => ({
        label: option.label,
        value: option,
      }))}
      value={value}
    />
  )
}
