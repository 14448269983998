import { loadingButtonClasses } from '@mui/lab/LoadingButton'
import { buttonClasses } from '@mui/material/Button'
import { Components, Theme } from '@mui/material/styles'

export function MuiLoadingButton(theme: Theme): Components['MuiLoadingButton'] {
  return {
    styleOverrides: {
      root: {
        [`&.${buttonClasses.containedPrimary}`]: {
          // Make spinner white in primary loading button
          [`.${loadingButtonClasses.loadingIndicator}`]: {
            color: 'white',
          },

          // Make transition between disabled/enabled less harsh
          transition: theme.transitions.create('opacity', {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shortest,
          }),
        },
      },
    },
  }
}
