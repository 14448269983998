import { ChevronLeftIcon, ChevronRightIcon } from '@gain/components/icons'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import { zoom } from 'd3'
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'
import { animated, useSpring } from 'react-spring'

const StyledRoot = styled('div')({
  position: 'relative',
})

const StyledIconButtonContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(2),
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '50%',
}))

interface VerticalBarChartContainerProps {
  containerWidth: number
  containerHeight: number
  chartHeight: number
  marginLeft: number
  disableScroll?: boolean
}

export default function VerticalBarChartContainer({
  containerWidth,
  containerHeight,
  chartHeight,
  marginLeft,
  disableScroll,
  children,
}: PropsWithChildren<VerticalBarChartContainerProps>) {
  const [scrollOffsetY, setScrollOffsetY] = useState(0)
  const maxOffsetY = useMemo(() => chartHeight - containerHeight, [chartHeight, containerHeight])
  const style = useSpring({
    transform: `translate(${marginLeft}px, ${scrollOffsetY}px)`,
  })

  const handleScrollDown = useCallback(
    () => setScrollOffsetY((prev) => Math.max(prev - containerWidth, -maxOffsetY)),
    [maxOffsetY, containerWidth]
  )

  const handleScrollUp = useCallback(
    () => setScrollOffsetY((prev) => Math.min(prev + containerWidth, 0)),
    [containerWidth]
  )

  const scroll = useMemo(() => {
    return zoom()
  }, [])

  useEffect(() => {
    scroll
      .scaleExtent([1, 1])
      .translateExtent([
        [0, 0],
        [containerHeight, 0],
      ])
      .extent([
        [0, 0],
        [chartHeight, 0],
      ])
      .on('zoom', (currentEvent) => {
        setScrollOffsetY(currentEvent.transform.y)
      })
  }, [chartHeight, scroll, containerHeight])

  useEffect(() => {
    if (scrollOffsetY < -maxOffsetY) {
      setScrollOffsetY(-maxOffsetY)
    }
  }, [scrollOffsetY, maxOffsetY, containerHeight, chartHeight])

  return (
    <StyledRoot>
      {!disableScroll && (
        <StyledIconButtonContainer>
          <StyledIconButton
            disabled={scrollOffsetY === 0}
            onClick={handleScrollUp}>
            <ChevronLeftIcon />
          </StyledIconButton>
          <StyledIconButton
            disabled={scrollOffsetY === -maxOffsetY || containerHeight >= chartHeight}
            onClick={handleScrollDown}>
            <ChevronRightIcon />
          </StyledIconButton>
        </StyledIconButtonContainer>
      )}
      <svg
        height={containerHeight}
        width={containerWidth}>
        <animated.g style={style}>{children}</animated.g>
      </svg>
    </StyledRoot>
  )
}
