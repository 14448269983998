import { isChildOfDescendantAnchorWithHref } from '@gain/utils/dom'
import { isCmdClickEvent } from '@gain/utils/event'
import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import { styled, Theme } from '@mui/material/styles'
import { SxProps } from '@mui/system'
import clsx from 'clsx'
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useHistory } from 'react-router'

import { virtualTableClasses } from '../../virtual-table'
import { CardContext } from './card-context'

export const cardClasses = generateUtilityClasses('Card', ['root', 'hover'])

interface StyledProps {
  fullHeight?: boolean
  hover: boolean
  variant?: 'default' | 'outlined'
}

const StyledCard = styled('div', {
  shouldForwardProp: (propName) =>
    !['fullHeight', 'hover', 'variant', 'sx'].includes(propName as string),
})<StyledProps>(({ theme, fullHeight, hover, variant = 'default' }) => ({
  backgroundColor: theme.palette.common.white,
  boxShadow: variant === 'outlined' ? theme.palette.shadow.level1c : theme.palette.shadow.level1d,
  borderRadius: 8,
  transition: theme.transitions.create(['box-shadow', 'transform'], {
    duration: theme.transitions.duration.shortest,
    easing: theme.transitions.easing.easeInOut,
  }),
  padding: theme.spacing(0, 0, 1, 0),
  display: 'flex',
  flexDirection: 'column',

  ...(hover &&
    variant === 'default' && {
      cursor: 'pointer',

      '&:hover': {
        boxShadow: '0 16px 24px -12px rgba(0, 0, 0, 0.1)',
        transform: 'scale(1.01)',
      },

      // Prevents weird scroll issues in tables
      [`& .${virtualTableClasses.container}`]: {
        overflow: 'hidden',
      },
    }),

  ...(fullHeight && {
    height: '100%',
  }),

  ...(hover &&
    variant === 'outlined' && {
      cursor: 'pointer',
    }),

  ...(variant === 'outlined' && {
    border: `1px solid ${theme.palette.divider}`,
  }),
}))

export interface CardProps extends Omit<StyledProps, 'hover'> {
  sx?: SxProps<Theme>
  children?: React.ReactNode
  href?: string | false | null
  onClick?: () => void
  className?: string
}

export const Card = forwardRef<HTMLDivElement, CardProps>(
  ({ href = null, onClick, variant, children, className, ...props }, ref) => {
    const innerRef = useRef<HTMLDivElement>(null)
    useImperativeHandle(ref, () => innerRef.current as HTMLDivElement)
    const history = useHistory()
    const context = useMemo(
      () => ({
        href: href || null,
      }),
      [href]
    )

    const handleClick = useCallback(
      (event: React.MouseEvent) => {
        // If there's an on click handler, call that directly and stop
        if (onClick) {
          onClick()
          return
        }

        // Bail out if href is not properly configured
        if (
          !href ||
          !innerRef.current ||
          !(event.target instanceof HTMLElement || event.target instanceof SVGElement)
        ) {
          return
        }

        // Continue with click logic for the href prop
        const isAnchorWithHref =
          event.target instanceof HTMLAnchorElement && event.target.hasAttribute('href')
        const isDescendant = innerRef.current.contains(event.target)
        const isInsideAnchorWithHref = isChildOfDescendantAnchorWithHref(
          event.target,
          innerRef.current
        )

        if (!isAnchorWithHref && !isInsideAnchorWithHref && isDescendant) {
          if (isCmdClickEvent(event)) {
            window.open(history.createHref({ pathname: href }))
          } else {
            history.push(href)
          }
        }
      },
      [href, onClick, history, innerRef]
    )

    const [hover, setHover] = useState(false)
    const isClickable = !!href || onClick !== undefined
    const handleMouseEnter = useCallback(() => setHover(true), [])
    const handleMouseLeave = useCallback(() => setHover(false), [])

    return (
      <CardContext.Provider value={context}>
        <StyledCard
          ref={innerRef}
          className={clsx(className, cardClasses.root, {
            [cardClasses.hover]: isClickable && hover,
          })}
          hover={isClickable}
          onClick={handleClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          variant={variant}
          {...props}>
          {children}
        </StyledCard>
      </CardContext.Provider>
    )
  }
)

export default Card
