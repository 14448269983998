import { isDefined } from './is-defined'

function getHostname(url: URL | string): string {
  if (typeof url === 'string') {
    try {
      const parsed = new URL(url)
      return parsed.hostname
    } catch (e) {
      return url.toLowerCase()
    }
  }

  return url.hostname
}

export const formatUrlHostname = (
  url: URL | string | null | undefined,
  stripWww?: boolean
): string | null | undefined => {
  if (!isDefined(url)) {
    return url
  }

  const hostname = getHostname(url)

  if (stripWww && hostname.startsWith('www.')) {
    return hostname.substring(4)
  }

  return hostname
}
