import { ManagementPositionType } from './lib/rpc-shared-model'
import { Option } from './shared-model-interfaces'

export const MANAGER_POSITION_OPTIONS: ReadonlyArray<Option<ManagementPositionType>> = [
  {
    label: 'CEO',
    value: ManagementPositionType.ChiefExecutiveOfficer,
  },
  {
    label: 'Co-CEO',
    value: ManagementPositionType.CoChiefExecutiveOfficer,
  },
  {
    label: 'Managing director',
    value: ManagementPositionType.ManagingDirector,
  },
  {
    label: 'Director',
    value: ManagementPositionType.Director,
  },
  {
    label: 'Chairman',
    value: ManagementPositionType.Chairman,
  },
  {
    label: 'Board member',
    value: ManagementPositionType.BoardMember,
  },
  {
    label: 'Representative',
    value: ManagementPositionType.Representative,
  },
]
