import { UserProfile } from '@gain/rpc/app-model'
import { isBefore } from 'date-fns/isBefore'
import { parseJSON } from 'date-fns/parseJSON'
import { startOfDay } from 'date-fns/startOfDay'
import { noop } from 'lodash'
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'

import ProjectCodeDialog from './project-code-dialog'
import UserContext, { UserContextType } from './user-context'

export interface UserContextProviderProps {
  userProfile: UserProfile | null | undefined
  onProjectCodeChange?: (projectCode: string | null) => void
  children: ReactNode
}

export function UserContextProvider({
  children,
  userProfile,
  onProjectCodeChange = noop,
}: UserContextProviderProps) {
  const [projectCodeDialogOpen, setProjectCodeDialogOpen] = useState(false)

  useEffect(() => {
    if (!userProfile || !userProfile.featureSessionTracking) {
      return
    }

    if (userProfile.sessionTrackingProjectCodeUpdatedAt) {
      const startOfToday = startOfDay(new Date())
      const updatedAt = parseJSON(userProfile.sessionTrackingProjectCodeUpdatedAt)

      if (isBefore(updatedAt, startOfToday)) {
        setProjectCodeDialogOpen(true)
      }
    }

    if (userProfile.featureSessionTracking && !userProfile.sessionTrackingProjectCode) {
      setProjectCodeDialogOpen(true)
    }
  }, [
    userProfile?.sessionTrackingProjectCodeUpdatedAt,
    userProfile?.featureSessionTracking,
    userProfile?.sessionTrackingProjectCode,
    setProjectCodeDialogOpen,
  ])

  const value = useMemo(
    () =>
      ({
        userProfile,
        openProjectCodeDialog: () => setProjectCodeDialogOpen(true),
      } as UserContextType),
    [userProfile]
  )

  const handleProjectCodeChange = useCallback(
    (newProjectCode: string | null) => {
      onProjectCodeChange(newProjectCode)
      setProjectCodeDialogOpen(false)
    },
    [onProjectCodeChange]
  )

  const handleClose = useCallback(() => {
    if (!userProfile?.sessionTrackingProjectCode) {
      return
    }

    setProjectCodeDialogOpen(false)
  }, [userProfile?.sessionTrackingProjectCode])

  return (
    <UserContext.Provider value={value}>
      <ProjectCodeDialog
        onClose={handleClose}
        onProjectCodeChange={handleProjectCodeChange}
        open={projectCodeDialogOpen}
        projectCode={userProfile?.sessionTrackingProjectCode}
        TransitionProps={{
          mountOnEnter: true,
          unmountOnExit: true,
        }}
      />
      {children}
    </UserContext.Provider>
  )
}
