import { ChevronLeftIcon, ChevronRightIcon } from '@gain/components/icons'
import IconButton, { iconButtonClasses } from '@mui/material/IconButton'
import { alpha, styled } from '@mui/material/styles'
import { zoom } from 'd3'
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'
import { animated, useSpring } from 'react-spring'

const StyledRoot = styled('div')({
  position: 'relative',
})

const StyledIconButtonContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(2),
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  transition: theme.transitions.create(['opacity']),
  borderRadius: '50%',

  [`&.${iconButtonClasses.disabled}`]: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    opacity: 0.5,
  },
}))

interface HorizontalBarChartContainerProps {
  containerWidth: number
  containerHeight: number
  chartWidth: number
  marginTop: number
}

export default function HorizontalBarChartContainer({
  containerWidth,
  containerHeight,
  chartWidth,
  marginTop,
  children,
}: PropsWithChildren<HorizontalBarChartContainerProps>) {
  const [scrollOffsetX, setScrollOffsetX] = useState(0)
  const maxOffsetX = useMemo(() => chartWidth - containerWidth, [chartWidth, containerWidth])
  const style = useSpring({
    transform: `translate(${scrollOffsetX}px, ${marginTop}px)`,
  })

  const handleScrollRight = useCallback(
    () => setScrollOffsetX((prev) => Math.max(prev - containerWidth, -maxOffsetX)),
    [maxOffsetX, containerWidth]
  )

  const handleScrollLeft = useCallback(
    () => setScrollOffsetX((prev) => Math.min(prev + containerWidth, 0)),
    [containerWidth]
  )

  const scroll = useMemo(() => {
    return zoom()
  }, [])

  useEffect(() => {
    scroll
      .scaleExtent([1, 1])
      .translateExtent([
        [0, 0],
        [containerWidth, 0],
      ])
      .extent([
        [0, 0],
        [chartWidth, 0],
      ])
      .on('zoom', (currentEvent) => {
        setScrollOffsetX(currentEvent.transform.x)
      })
  }, [chartWidth, scroll, containerWidth])

  useEffect(() => {
    if (scrollOffsetX < -maxOffsetX) {
      setScrollOffsetX(-maxOffsetX)
    }
  }, [scrollOffsetX, maxOffsetX, containerWidth, chartWidth])

  return (
    <StyledRoot>
      <StyledIconButtonContainer>
        {chartWidth > containerWidth && (
          <>
            <StyledIconButton
              color={'primary'}
              disabled={scrollOffsetX === 0}
              onClick={handleScrollLeft}>
              <ChevronLeftIcon color={'primary'} />
            </StyledIconButton>
            <StyledIconButton
              color={'primary'}
              disabled={scrollOffsetX === -maxOffsetX || containerWidth >= chartWidth}
              onClick={handleScrollRight}>
              <ChevronRightIcon color={'primary'} />
            </StyledIconButton>
          </>
        )}
      </StyledIconButtonContainer>
      <svg
        height={containerHeight}
        width={containerWidth}>
        <animated.g style={style}>{children}</animated.g>
      </svg>
    </StyledRoot>
  )
}
