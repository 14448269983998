import Flag from '@gain/components/flag'
import { AssetListItem } from '@gain/rpc/app-model'
import React from 'react'

import { ColumnConfig } from '../../../../../common/virtual-table'
import AssetContentLink from '../../../../../features/asset/asset-content-link'
import FinancialValue from '../../../../../features/financial/financial-value'

export const similarCompaniesColumns: ColumnConfig<AssetListItem>[] = [
  {
    headerName: 'Similar companies',
    field: 'name',
    sortable: false,
    width: 0,
    flex: 1,
    renderCell: ({ row }) => (
      <AssetContentLink
        asset={row}
        id={row.id}
        logoFileUrl={row.logoFileUrl}
        name={row.name}
      />
    ),
  },
  {
    field: 'region',
    headerName: 'Region',
    align: 'center',
    width: 48,
    sortable: false,
    renderCell: ({ value }) => (value ? <Flag code={value} /> : <>-</>),
  },
  {
    field: 'revenueEur',
    headerName: 'Revenue',
    align: 'right',
    width: 100,
    sortable: false,
    renderCell: ({ value }) => <FinancialValue amount={value} />,
  },
]
