import {
  BookOpenIcon,
  HelpCircleIcon,
  MessageCircleIcon,
  MonitorIcon,
  PhoneIcon,
} from '@gain/components/icons'
import Link from '@mui/material/Link'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import React, { MouseEvent, useState } from 'react'

import { useZendesk, ZENDESK_HELP_CENTER_URL } from '../../zendesk'
import MenuListItem from '../menu-list-item'

export default function HelpMenuOptions() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLDivElement>(null)

  const { enableChat, isLoading: isChatLoading } = useZendesk()

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <MenuListItem
        icon={<HelpCircleIcon fontSize={'inherit'} />}
        label={'Help'}
        onClick={handleClick}
      />

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}>
        <MenuItem
          component={Link}
          href={ZENDESK_HELP_CENTER_URL}
          rel={'noopener noreferrer'}
          target={'_blank'}>
          <ListItemIcon>
            <BookOpenIcon />
          </ListItemIcon>
          <ListItemText primary={'Help Center'} />
        </MenuItem>

        <MenuItem
          disabled={isChatLoading}
          onClick={enableChat}>
          <ListItemIcon>
            <MessageCircleIcon />
          </ListItemIcon>
          <ListItemText primary={'Message support'} />
        </MenuItem>

        <MenuItem
          component={Link}
          href={'tel:+442039742133'}>
          <ListItemIcon>
            <PhoneIcon />
          </ListItemIcon>
          <ListItemText primary={'Call support'} />
        </MenuItem>

        <MenuItem
          component={Link}
          href={'https://marketing.gain.pro/user.webinars'}
          rel={'noopener noreferrer'}
          target={'_blank'}>
          <ListItemIcon>
            <MonitorIcon />
          </ListItemIcon>
          <ListItemText primary={'Webinars'} />
        </MenuItem>
      </Menu>
    </>
  )
}
