import { useFormatCurrencyCallback } from '@gain/utils/currency'
import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { scaleLinear } from 'd3'
import React, { forwardRef } from 'react'
import { useMeasure } from 'react-use'

const Line = styled((props: React.SVGProps<SVGLineElement>) => (
  <line
    strokeLinecap={'round'}
    {...props}
  />
))(({ theme }) => ({
  stroke: theme.palette.divider,
  strokeWidth: 2,
}))

const Bar = styled(
  forwardRef<SVGLineElement, React.SVGProps<SVGLineElement>>((props, ref) => (
    <line
      ref={ref}
      strokeLinecap={'round'}
      {...props}
    />
  ))
)(({ theme }) => ({
  stroke: theme.palette.info.main,
  strokeWidth: 2,
  opacity: 0.6,
}))

interface InnerEbitdaValuesRangeProps {
  values: number[]
  width: number
  height: number
}

function InnerEbitdaValuesRange({ values, width, height }: InnerEbitdaValuesRangeProps) {
  const formatCurrency = useFormatCurrencyCallback()
  const chartMargin = {
    y: (height - 6) / 2,
    x: 12,
  }
  const innerWidth = width - chartMargin.x * 2
  const innerHeight = height - chartMargin.y * 2

  const position = scaleLinear()
    .domain([Math.min(...values), Math.max(...values)])
    .range([0, innerWidth])

  return (
    <svg
      height={height}
      width={width}>
      <g transform={`translate(${chartMargin.x}, ${chartMargin.y})`}>
        <Line
          x1={-4}
          x2={innerWidth + 4}
          y1={innerHeight / 2}
          y2={innerHeight / 2}
        />
        {values.map((value, index) => (
          <Tooltip
            key={index}
            title={formatCurrency(value)}
            disableInteractive>
            <Bar
              x1={position(value)}
              x2={position(value)}
              y1={0}
              y2={innerHeight}
            />
          </Tooltip>
        ))}
      </g>
    </svg>
  )
}

const StyledContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  maxWidth: '100%',
  flex: 1,
})

const StyledChartContainer = styled('div')({
  display: 'inline-flex',
  flex: 1,
  overflow: 'hidden',
})

export interface EbitdaValuesRangeProps {
  ebitdaValues: number[]
  className?: string
}

export default function EbitdaValuesRange({ ebitdaValues, className }: EbitdaValuesRangeProps) {
  const formatCurrency = useFormatCurrencyCallback()
  const min = Math.min(...ebitdaValues)
  const max = Math.max(...ebitdaValues)
  const [ref, { width, height }] = useMeasure<HTMLDivElement>()

  if (ebitdaValues.length === 0) {
    return <>-</>
  }

  return (
    <StyledContainer className={className}>
      <Typography
        color={'text.secondary'}
        variant={'body2'}>
        {formatCurrency(min, { disablePrefix: true })}
      </Typography>
      <StyledChartContainer ref={ref}>
        {width && (
          <InnerEbitdaValuesRange
            height={height}
            values={ebitdaValues}
            width={width}
          />
        )}
      </StyledChartContainer>
      <Typography
        color={'text.secondary'}
        variant={'body2'}>
        {formatCurrency(max, { disablePrefix: true })}
      </Typography>
    </StyledContainer>
  )
}
