import OverflowContainer from '@gain/components/overflow-container'
import { AssetListItem } from '@gain/rpc/app-model'
import { useMemo } from 'react'

import { Tag } from '../../../common/tag'
import { ASSET_LIST_PATH } from '../../../routes/utils'
import {
  autocompleteIncludeFilterValue,
  filterModelToQueryString,
  filterValueGroup,
  filterValueItem,
} from '../../filter/filter-bar'

interface AssetTags {
  tagIds: number[]
  tags: string[]
}

export default function AssetTags({ tagIds, tags }: AssetTags) {
  return useMemo(() => {
    if (tagIds.length === 0) {
      return <>-</>
    }

    return (
      <OverflowContainer
        chipSize={'small'}
        lineHeight={16}
        maxLines={1}
        disableObserver>
        {tagIds.map((tagId, index) => (
          <Tag
            key={tagId}
            color={'info'}
            href={[
              ASSET_LIST_PATH,
              filterModelToQueryString<AssetListItem, 'tagIds'>([
                filterValueGroup(
                  filterValueItem('tagIds', autocompleteIncludeFilterValue([tagId]))
                ),
              ]),
            ].join('?')}
            label={tags[index]}
          />
        ))}
      </OverflowContainer>
    )
  }, [tagIds, tags])
}
