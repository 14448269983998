import { IndustryMarketSegmentListItem } from '@gain/rpc/app-model'
import React from 'react'

import Card, { CardHeader, CardProps } from '../../../../common/card/card'
import Table from '../../../../common/table'
import { assetIndustryMarketColumns } from './card-companies-related-industries.hooks'

export interface CardAssetShareholdersProps extends CardProps {
  segments: IndustryMarketSegmentListItem[]
}

export default function CardCompaniesRelatedIndustries({
  segments,
  ...cardProps
}: CardAssetShareholdersProps) {
  return (
    <Card {...cardProps}>
      <CardHeader title={'Related industries'} />

      <Table
        columns={assetIndustryMarketColumns}
        rows={segments}
      />
    </Card>
  )
}
