import Accordion, { accordionClasses, AccordionProps } from '@mui/material/Accordion'
import { styled } from '@mui/material/styles'

export const CheckboxAccordion = styled(
  ({ TransitionProps, children, ...props }: AccordionProps) => (
    <Accordion
      TransitionProps={{
        ...TransitionProps,
        unmountOnExit: true,
      }}
      {...props}>
      {children}
    </Accordion>
  )
)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  boxShadow: 'none',
  minHeight: 32,
  paddingLeft: 1,
  [`&.${accordionClasses.expanded}`]: {
    marginBottom: 0,
    marginTop: 0,
  },
  '&:before': {
    display: 'none',
  },
}))
