import {
  AssetListItem,
  IndustryMarketSegmentListItem,
  InvestorProfileStrategy,
} from '@gain/rpc/app-model'
import { useFormatCurrencyCallback } from '@gain/utils/currency'
import { isCmdClickEvent } from '@gain/utils/event'
import { isNumber } from '@gain/utils/typescript'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'
import { extent } from 'd3'
import React, { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'

import { chartColorSet } from '../../../common/chart/chart-colors'
import {
  ChartGroup,
  ChartGroupByConfig,
  ChartGroupBySelect,
  getPreferredChartGroupBy,
  useChartGroups,
} from '../../../common/chart/chart-groups'
import ChartLegend from '../../../common/chart/chart-legend'
import { ChartSizeTypeConfig, ChartSizeTypeSelect } from '../../../common/chart/chart-select'
import { MeasureDimensions } from '../../../common/responsive'
import { generateAssetPagePath } from '../../../routes/utils'
import { AssetChartSizeType, useAssetChartSizeTypes } from '../chart-utils/asset-chart-size-types'
import { AssetChartGroupType } from '../chart-utils/asset-group-by-configs'
import useGroupByOptions from '../chart-utils/use-group-by-options'
import BarChart from '../charts/bar-chart'

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(0, 3),
}))

export interface CompanyOverviewChartProps {
  assets: AssetListItem[]
  industrySegments?: IndustryMarketSegmentListItem[]
  investorStrategies?: InvestorProfileStrategy[]
  disableGroupBy?: boolean
  defaultGroupTypeId?: AssetChartGroupType
  orientation?: 'horizontal' | 'vertical'
  fixedHeight?: number
}

export default function CompanyBarChart({
  assets,
  industrySegments,
  investorStrategies,
  disableGroupBy,
  defaultGroupTypeId,
  orientation = 'horizontal',
  fixedHeight,
}: CompanyOverviewChartProps) {
  const formatCurrency = useFormatCurrencyCallback()
  const assetSizeTypes = useAssetChartSizeTypes()
  const [sizeType, setSizeType] = useState<ChartSizeTypeConfig<AssetListItem>>(assetSizeTypes[0])
  const groupByOptions = useGroupByOptions(industrySegments, investorStrategies, assets)
  const [groupBy, setGroupBy] = useState<ChartGroupByConfig<AssetListItem>>(
    getPreferredChartGroupBy(groupByOptions, defaultGroupTypeId)
  )
  const groups = useChartGroups(assets, groupBy)
  const [visibleGroups, setVisibleGroups] = useState<ChartGroup<AssetListItem>[]>([])
  const history = useHistory()

  const handleGetId = useCallback((d) => d.id, [])
  const handleGetLabel = useCallback((d) => d.name || '-', [])
  const handleGetColor = useCallback(
    (item: AssetListItem) =>
      groups.find((group) => group.value === groupBy.getValue(item))?.color || chartColorSet[0],
    [groups, groupBy]
  )
  const handleGetTooltip = useCallback(
    (item: AssetListItem) =>
      [handleGetLabel(item), sizeType.formatter(sizeType.getValue(item), item)].join(' - '),
    [handleGetLabel, sizeType]
  )

  const handleGetValueFormatter = useCallback(
    (value: number | null, item: AssetListItem) => {
      // Display a number in millions without any formatting; this works
      // best when comparing bar charts heights and to save space.
      return [
        AssetChartSizeType.EstEV,
        AssetChartSizeType.Revenue,
        AssetChartSizeType.GrossMargin,
        AssetChartSizeType.Ebitda,
      ].includes(sizeType.id as AssetChartSizeType)
        ? formatCurrency(value, {
            format: 'millions',
            disableSuffix: true,
            disablePrefix: true,
          })
        : sizeType.formatter(value, item)
    },
    [formatCurrency, sizeType]
  )

  const filteredAssets = useMemo(() => {
    return visibleGroups
      .sort((visibleGroupA, visibleGroupB) => {
        const [, maxGroupA] = extent(visibleGroupA.items.map(sizeType.getValue).filter(isNumber))
        const [, maxGroupB] = extent(visibleGroupB.items.map(sizeType.getValue).filter(isNumber))

        return (maxGroupA || 0) > (maxGroupB || 0) ? -1 : 1
      })
      .reduce((groupAssets, group) => {
        return groupAssets.concat(
          group.items.sort((assetA, assetB) => {
            const valueA = sizeType.getValue(assetA)
            const valueB = sizeType.getValue(assetB)

            if (valueA && valueB) {
              return valueA > valueB ? -1 : 1
            } else if (valueA && !valueB) {
              return -1
            } else {
              return 0
            }
          })
        )
      }, [] as AssetListItem[])
  }, [sizeType, visibleGroups])

  const handleOnBarClick = useCallback(
    (item: AssetListItem, event: MouseEvent) => {
      const assetPagePath = generateAssetPagePath({
        id: item.id,
        name: item.name,
      })

      if (isCmdClickEvent(event)) {
        window.open(history.createHref({ pathname: assetPagePath }))
      } else {
        history.push(assetPagePath)
      }
    },
    [history]
  )

  useEffect(() => {
    setGroupBy(getPreferredChartGroupBy(groupByOptions, defaultGroupTypeId))
  }, [groupByOptions, defaultGroupTypeId])

  useEffect(() => {
    setVisibleGroups(groups)
  }, [groups])

  return (
    <StyledRoot>
      <Grid
        spacing={2}
        container>
        <Grid
          alignItems={'center'}
          justifyContent={'end'}
          spacing={2}
          container
          item>
          <Grid item>
            <ChartSizeTypeSelect
              onChange={setSizeType}
              options={assetSizeTypes}
              value={sizeType}
            />
          </Grid>
          {!disableGroupBy && (
            <Grid item>
              <ChartGroupBySelect
                onChange={setGroupBy}
                options={groupByOptions}
                value={groupBy}
              />
            </Grid>
          )}
        </Grid>
        <Grid
          xs={12}
          item>
          <MeasureDimensions fixedHeight={fixedHeight}>
            {({ width }) => (
              <BarChart
                data={filteredAssets}
                getColor={handleGetColor}
                getId={handleGetId}
                getLabel={handleGetLabel}
                getTooltip={handleGetTooltip}
                getValue={sizeType.getValue}
                height={fixedHeight}
                onBarClick={handleOnBarClick}
                orientation={orientation}
                valueFormatter={handleGetValueFormatter}
                width={width}
              />
            )}
          </MeasureDimensions>
        </Grid>
        {!disableGroupBy && (
          <Grid
            xs={12}
            item>
            <Divider sx={{ mx: -3 }} />
            <ChartLegend
              groups={groups}
              onChange={setVisibleGroups}
              value={visibleGroups}
            />
          </Grid>
        )}
      </Grid>
    </StyledRoot>
  )
}
