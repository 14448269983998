import Tooltip from '@gain/components/tooltip'
import { AnnualReportFileEntry } from '@gain/utils/annual-report'
import { ReactElement } from 'react'

import { AnnualReportsTooltipTitle } from './annual-reports-tooltip-title'

export interface AnnualReportsTooltipProps {
  annualReportFileEntries: AnnualReportFileEntry[]
  children: ReactElement
}

export default function AnnualReportsTooltip({
  annualReportFileEntries,
  children,
}: AnnualReportsTooltipProps) {
  return (
    <Tooltip
      title={<AnnualReportsTooltipTitle annualReportFileEntries={annualReportFileEntries} />}
      variant={'preview'}
      disablePadding>
      {children}
    </Tooltip>
  )
}
