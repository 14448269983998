import { ArticleListItem } from '@gain/rpc/app-model'
import { useElementWidth } from '@gain/utils/dom'
import { styled } from '@mui/material/styles'
import { useRef } from 'react'

import Card, { CardHeader, CardProps } from '../../../../common/card/card'
import { CardContentEmpty } from '../../../../common/card/card'
import ListAssetNews from '../../../../features/asset/asset-news-list'
import TableAssetNews from '../../../../features/asset/asset-news-table'

const StyledCard = styled(Card)({
  display: 'flex',
  flexDirection: 'column',
})

export type AssetNewsCardProps = CardProps & {
  articles: ArticleListItem[]
}

export function AssetNewsCard({ articles, ...rest }: AssetNewsCardProps) {
  const ref = useRef<HTMLDivElement>(null)
  const width = useElementWidth(ref)

  return (
    <StyledCard
      {...rest}
      ref={ref}>
      <CardHeader title={'News'} />
      {width >= 544 && <TableAssetNews articles={articles} />}
      {width < 544 && <ListAssetNews articles={articles} />}
      {articles.length === 0 && <CardContentEmpty message={'No news articles available'} />}
    </StyledCard>
  )
}

export default AssetNewsCard
