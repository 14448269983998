import { AssetListItem } from '@gain/rpc/app-model'
import * as echarts from 'echarts/core'
import { isObject } from 'lodash'
import { useCallback, useState } from 'react'

import { EChartsEventHandler } from '../../../common/echart/use-e-charts-zr-event-handler'
import { useOpenLink } from '../../../common/use-open-link'
import { generateAssetPagePath } from '../../../routes/utils'

/**
 * ActiveBubble is the bubble that is being hovered upon with its position,
 * dimension and asset information. Because this data is derived from EChart
 * event data it is unrelated to the normal chart bubble type.
 */
export interface ActiveBubble {
  x: number
  y: number
  width: number
  height: number
  asset: AssetListItem
}

/**
 * Handles the hover highlight effect and click-behaviour for a single bubble.
 */
export default function useActiveBubble(
  assets: AssetListItem[]
): [
  activeBubble: ActiveBubble | null,
  onMouseMove: EChartsEventHandler,
  onMouseOut: EChartsEventHandler,
  onClick: EChartsEventHandler
] {
  const openLink = useOpenLink()
  const [activeBubble, setActiveBubble] = useState<ActiveBubble | null>(null)

  // Handle the mouse move event. It determines which bubble in the chart is
  // currently hovered upon.
  const handleMouseMove = useCallback(
    (event: echarts.ECElementEvent) => {
      // We need the actual mouse move event, otherwise we can't determine the
      // position of the mouse pointer.
      if (!event.event) {
        return
      }

      // Set active bubble
      if (event.event.target && isObject(event.data)) {
        const rect = event.event.target.getBoundingRect()
        const assetId = isObject(event.data) ? parseInt(event.data['name']) : null

        // Find active asset
        const activeAsset = assets.find((asset) => asset.id === assetId)
        if (!activeAsset) {
          return
        }

        // Update the active bubble if the asset changed. We avoid unnecessary
        // updates to avoid the tooltip arrow from (very slightly) moving around.
        setActiveBubble((prev) =>
          prev?.asset.id !== activeAsset.id ? { ...rect, asset: activeAsset } : prev
        )
      }
    },
    [assets]
  )

  // Disable active bubble on mouse out
  const handleMouseOut = useCallback(() => {
    setActiveBubble(null)
  }, [])

  // When clicking on a bubble, navigate to the asset page
  const handleClick = useCallback(
    (event: echarts.ECElementEvent) => {
      if (!activeBubble) {
        return
      }

      const assetPagePath = generateAssetPagePath({
        id: activeBubble.asset.id,
        name: activeBubble.asset.name,
      })

      if (event.event?.event) {
        openLink(assetPagePath, event.event.event)
      }
    },
    [activeBubble, openLink]
  )

  return [activeBubble, handleMouseMove, handleMouseOut, handleClick]
}
