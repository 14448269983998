import { ListItemKey } from '@gain/rpc/list-model'
import Autocomplete from '@mui/material/Autocomplete'
import { formLabelClasses } from '@mui/material/FormLabel'
import InputAdornment from '@mui/material/InputAdornment'
import { inputBaseClasses } from '@mui/material/InputBase'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import React, { useEffect, useState } from 'react'

import {
  AutocompleteOption,
  FilterConfigAutocomplete,
} from '../../filter-config/filter-config-model'
import FilterAutocompleteIncludeModeSelect from './filter-autocomplete-include-mode-select'
import { useAutocompleteOptions } from './filter-autocomplete-utils'

const StyledTextField = styled(TextField)(({ theme }) => ({
  [`& > .${inputBaseClasses.root}`]: { boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.06)' },
  [`& .${formLabelClasses.root}`]: theme.typography.body2,
}))

const StyledInputSelectContainer = styled('div')(({ theme }) => ({
  borderLeft: `1px solid ${theme.palette.divider}`,
  height: 20,
  padding: theme.spacing(0, 1),
  display: 'flex',
  alignItems: 'center',
}))

function isOptionEqualToValue(option: AutocompleteOption, value: AutocompleteOption) {
  return option.value === value.value
}

export interface FilterAutocompleteInputProps<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
> {
  filter: FilterConfigAutocomplete<Item, FilterField>
  onSelectOption: (option: AutocompleteOption) => void
}

export default function FilterAutocompleteInput<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>({ filter, onSelectOption }: FilterAutocompleteInputProps<Item, FilterField>) {
  const [open, setOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const options = useAutocompleteOptions(inputValue, null, filter.fetchOptions)

  const hasInputValue = inputValue.length > 0

  // Show autocomplete options when there is an input value, hide it otherwise
  useEffect(() => {
    setOpen(hasInputValue)
  }, [hasInputValue])

  return (
    <Autocomplete
      filterOptions={(items) => items}
      filterSelectedOptions={false}
      forcePopupIcon={false}
      fullWidth={false}
      getOptionLabel={(option: AutocompleteOption) => option.label}
      inputValue={inputValue}
      isOptionEqualToValue={isOptionEqualToValue}
      loading={options.loading}
      noOptionsText={`No ${filter.pluralName} found`}
      onChange={(_, value) => {
        setInputValue('')
        onSelectOption(value)
      }}
      onClose={() => setOpen(false)}
      onFocus={() => setOpen(hasInputValue)}
      onInputChange={(_, value, reason) => {
        if (reason !== 'reset') {
          setInputValue(value)
        }
      }}
      open={open}
      options={options.value}
      renderInput={(params) => (
        <StyledTextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: !filter.disableToggleIncludeMode && (
              <InputAdornment position={'end'}>
                <StyledInputSelectContainer>
                  <FilterAutocompleteIncludeModeSelect filter={filter} />
                </StyledInputSelectContainer>
              </InputAdornment>
            ),
          }}
          placeholder={`Search ${filter.singularName}`}
          size={'small'}
          variant={'outlined'}
        />
      )}
      renderTags={() => null}
      size={'small'}
      autoHighlight
      autoSelect
      disableClearable
    />
  )
}
