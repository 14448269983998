import { useInvestorList } from '@gain/api/app/hooks'
import { AssetShareholder, FilterableInvestorListItem, InvestorListItem } from '@gain/rpc/app-model'
import { ListArgs } from '@gain/rpc/list-model'
import { listFilter } from '@gain/rpc/utils'
import { sortAssetShareholdersByShare } from '@gain/utils/asset'
import { isDefined, noop } from '@gain/utils/common'
import { useCallback, useEffect, useMemo } from 'react'

import AssetShareholderListItem from './asset-shareholder-list-item'

export interface AssetShareholderListProps {
  shareholders: AssetShareholder[]
  maxItems?: number
  onOverflowChange?: (nrOfOverflowingItems: number) => void
}

export function AssetShareholderList({
  shareholders,
  maxItems,
  onOverflowChange = noop,
}: AssetShareholderListProps) {
  const visibleShareholders = useMemo(() => {
    return shareholders
      .slice(0, isDefined(maxItems) ? maxItems : shareholders.length)
      .sort(sortAssetShareholdersByShare)
  }, [shareholders, maxItems])

  const investors = useInvestorList(
    useCallback(() => {
      if (shareholders.length === 0) {
        return null
      }

      return {
        filter: [
          listFilter(
            'id',
            '=',
            shareholders.map((shareholder) => shareholder.investorId)
          ),
        ],
        limit: shareholders.length,
      } as ListArgs<InvestorListItem, FilterableInvestorListItem>
    }, [shareholders])
  )

  const investorMap = useMemo(
    () =>
      investors.data.items.reduce(
        (acc, current) => ({
          ...acc,
          [current.id]: current,
        }),
        {} as Record<number, InvestorListItem>
      ),
    [investors.data]
  )

  useEffect(() => {
    if (!isDefined(maxItems)) {
      return
    }

    if (investors.data.items.length > maxItems) {
      onOverflowChange(investors.data.items.length - maxItems)
    }
  }, [investors.data.items, maxItems, onOverflowChange])

  return (
    <>
      {visibleShareholders.map((shareholder) => (
        <AssetShareholderListItem
          key={shareholder.id}
          investor={investorMap[shareholder.investorId]}
          share={shareholder.share}
        />
      ))}
    </>
  )
}

export default AssetShareholderList
