import { CurrencyListItem, LegalEntity } from '@gain/rpc/app-model'
import { FinancialResult } from '@gain/utils/financials'
import { useCallback, useState } from 'react'

import FinancialsCard from './financials-card'

export interface LegalEntityFinancialsCardProps {
  legalEntity: LegalEntity
  financialResults: FinancialResult[]
  hideAutomated?: boolean
  enableFinancialsFrom?: boolean
}

export default function LegalEntityFinancialsCard({
  legalEntity,
  financialResults,
  hideAutomated = false,
  enableFinancialsFrom = false,
}: LegalEntityFinancialsCardProps) {
  const [currency, setCurrency] = useState<CurrencyListItem | null>(null)

  const handleCurrencyChange = useCallback((newCurrency: CurrencyListItem) => {
    setCurrency(newCurrency)
  }, [])

  return (
    <FinancialsCard
      automated={!hideAutomated && (legalEntity.automaticallyCreated || true)}
      currency={currency}
      dataCurrency={legalEntity.currency}
      exportContainerProps={{
        method: 'data.exportLegalEntityFinancials',
        params: {
          id: legalEntity.id,
        },
      }}
      financialResults={financialResults}
      financialsFromEntity={enableFinancialsFrom ? legalEntity : undefined}
      onCurrencyChange={handleCurrencyChange}
    />
  )
}
