import { formatYear } from '@gain/utils/date'
import { formatPercentage } from '@gain/utils/number'
import { useTableRowHoverContext } from '@gain/utils/table'
import Chip from '@mui/material/Chip'
import React from 'react'

import Sparkline, { mapSparklineValues } from '../../../../common/sparkline'

export interface PercentageTableCellProps {
  percentage: number | null
  year?: number | null
  years?: number[]
  results?: number[]

  // In finance some numbers are not sensible, e.g. EBITDA % > 100%. If this
  // happens, we can display 'n.m.' (not meaningful) instead of the number.
  enableNotMeaningful?: boolean
}

/**
 * Percentage table cell to display a percentage (e.g. EBITDA %). When hovered
 * it will display a sparkline of the historic values if those are available.
 */
export function PercentageTableCell({
  percentage,
  years,
  year,
  results,
  enableNotMeaningful = false,
}: PercentageTableCellProps) {
  const hover = useTableRowHoverContext()

  if (hover && years && results) {
    const financials = mapSparklineValues(years, results)
    if (financials && percentage) {
      return (
        <Sparkline
          label={formatPercentage(percentage, { round: 0 })}
          values={financials}
        />
      )
    }
  }

  if (percentage === null) {
    return '-'
  }

  const isNotMeaningful = enableNotMeaningful && (percentage < -100 || percentage > 100)

  return (
    <>
      {isNotMeaningful ? 'n.m.' : formatPercentage(percentage)}

      {year && (
        <>
          &nbsp;
          <Chip
            label={formatYear(year, 'short')}
            size={'small'}
          />
        </>
      )}
    </>
  )
}
