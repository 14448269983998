import { AssetListItem } from '@gain/rpc/app-model'
import { useSplitList, useVisibleListItems } from '@gain/utils/list'
import React from 'react'

import { CardContentEmpty } from '../../../../../common/card/card'
import ViewMoreButton from '../../../../../common/view-more-button'
import VirtualTable from '../../../../../common/virtual-table'
import { similarCompaniesColumns } from './asset-market-similar-companies-columns'

export interface AssetMarketSimilarCompaniesProps {
  assetList: AssetListItem[]
  loading: boolean
}

export default function AssetMarketSimilarCompanies({
  assetList,
  loading,
}: AssetMarketSimilarCompaniesProps) {
  const [initialAssets, additionalAssets] = useSplitList(assetList, 4)
  const [rows] = useVisibleListItems(initialAssets, additionalAssets)

  if (rows.length === 0 && !loading) {
    return <CardContentEmpty message={'No data available'} />
  }

  return (
    <>
      <VirtualTable
        columns={similarCompaniesColumns}
        loadingInitial={loading}
        RowComponentProps={{
          hover: false,
        }}
        rows={rows}
        variant={'inline'}
      />

      <ViewMoreButton
        amount={additionalAssets.length}
        // href is not needed as the card itself is clickable
        href={undefined}
      />
    </>
  )
}
