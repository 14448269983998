import { useRpcClient } from '@gain/api/swr'
import Snackbar from '@gain/components/snackbar'
import { TicketAttributeType, TicketType } from '@gain/rpc/app-model'
import { yupResolver } from '@hookform/resolvers/yup'
import LoadingButton from '@mui/lab/LoadingButton'
import Alert from '@mui/material/Alert'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { generatePath, useHistory } from 'react-router-dom'
import * as Yup from 'yup'

import DialogHeader from '../../../common/dialog-header'
import { ASSET_PAGE_PATH } from '../../utils'

interface FormData {
  feedback: string
}

const FormSchema = Yup.object().shape({
  feedback: Yup.string().required('Feedback field is required'),
})

const SNACKBAR_KEY = 'company-feedback-shared'

function createExternalLink(path: string) {
  return [window.location.protocol, [window.location.host, path].join('')]
    .filter(Boolean)
    .join('//')
}

export interface ShareFeedbackDialogProps {
  assetId: number
  assetName: string
  assetRegion: string
  open: boolean
  onClose: () => void
}

export default function ShareFeedbackDialog({
  assetId,
  assetName,
  assetRegion,
  open,
  onClose,
}: ShareFeedbackDialogProps) {
  const fetcher = useRpcClient()
  const [error, setError] = useState<null | string>(null)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  const form = useForm<FormData>({
    defaultValues: {
      feedback: '',
    },
    resolver: yupResolver(FormSchema),
  })

  const onSubmit: SubmitHandler<FormData> = async (formData) => {
    setSubmitting(true)
    setError(null)
    try {
      const assetUrl = createExternalLink(
        history.createHref({
          pathname: generatePath(ASSET_PAGE_PATH, {
            name: assetName,
            id: assetId,
          }),
        })
      )
      await fetcher({
        method: 'account.createTicket',
        params: {
          type: TicketType.ShareCompanyFeedback,
          body: formData.feedback,
          attributes: [
            { type: TicketAttributeType.CompanyName, value: assetName },
            { type: TicketAttributeType.GainProCompanyUrl, value: assetUrl },
            { type: TicketAttributeType.CompanyRegion, value: assetRegion },
          ],
        },
      })
      enqueueSnackbar(undefined, {
        key: SNACKBAR_KEY,
        content: () => (
          <Snackbar
            id={SNACKBAR_KEY}
            message={'Your feedback has been received. We will get back to you shortly.'}
          />
        ),
        preventDuplicate: true,
      })
      onClose()
    } catch {
      setError('An error occurred while submitting the form, please try again later.')
    }

    setSubmitting(false)
  }

  return (
    <Dialog
      maxWidth={'sm'}
      onClose={onClose}
      open={open}
      TransitionProps={{
        onExited: () => form.reset(),
      }}
      fullWidth>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        noValidate>
        <DialogHeader
          onCloseClick={onClose}
          title={'Give feedback on company profile'}
        />

        <DialogContent>
          <Grid
            rowSpacing={2}
            container>
            {error && (
              <Grid
                xs={12}
                item>
                <Alert severity={'error'}>{error}</Alert>
              </Grid>
            )}
            <Grid
              xs={12}
              item>
              <TextField
                label={'Company name'}
                name={'companyName'}
                value={assetName}
                disabled
                fullWidth
              />
            </Grid>
            <Grid
              xs={12}
              item>
              <TextField
                label={'Feedback'}
                maxRows={10}
                minRows={10}
                placeholder={'Please enter your feedback here.'}
                fullWidth
                multiline
                {...form.register('feedback')}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <LoadingButton
            color={'primary'}
            disabled={!form.formState.isDirty || !form.formState.isValid}
            loading={submitting}
            type={'submit'}
            variant={'contained'}
            fullWidth>
            Send
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}
