import { useIndustryMarketSegmentList } from '@gain/api/app/hooks'
import { IndustryMarketSegmentListItem } from '@gain/rpc/app-model'
import { listFilter } from '@gain/rpc/utils'
import React from 'react'

import { ColumnConfig } from '../../../../common/table'
import ContentLinkIndustry from '../../../../features/industry/industry-content-link'

export function useAssetIndustryMarketSegments(assetId: number) {
  return useIndustryMarketSegmentList({
    filter: [listFilter<IndustryMarketSegmentListItem>('linkedAssetIds', '=', assetId)],
    limit: 20,
    sort: [
      {
        field: 'name',
        direction: 'desc',
      },
    ],
  })
}

export const assetIndustryMarketColumns: Array<ColumnConfig<IndustryMarketSegmentListItem>> = [
  {
    headerName: 'Industry',
    field: 'name',
    renderCell: ({ row }) => (
      <ContentLinkIndustry
        id={row.industryId}
        logoFileUrl={row.industrySmallImageFileUrl}
        title={row.industryTitle}
      />
    ),
  },
  {
    headerName: 'Region',
    field: 'industryScope',
    align: 'right',
    width: 100,
  },
]
