import { useIsBrowserExtension } from '@gain/modules/browser-extension'
import { data, ObjectType } from '@gain/rpc/app-model'
import { useCallback, useEffect, useRef } from 'react'

import { rpcClient } from '../../api/rpc-client'

export type CombinedPathType =
  | 'home'
  | 'search'
  | 'help'
  | 'settings'
  | 'article'
  | 'deal'
  | 'segment'
  | 'bookmark'
  | 'filtered'
  | 'investor_strategy'
  | 'conference'
  | `${ObjectType}`

const BROWSER_EXTENSION = 'Browser Extension'
const PAGE_VIEW = 'PageView'
const DOWNLOAD = 'Download'
const UPLOAD = 'Upload'
const COUNTRY = 'Country'
const LIST = 'list'
const TAB = 'tab'

export interface PageViewOptions {
  id?: number
  list?: boolean
  tab?: string | undefined
  requireTab?: boolean
  country?: string
}

export function useTrackPageView(path: CombinedPathType, options?: PageViewOptions) {
  const isBrowserExtension = useIsBrowserExtension()
  const previousId = useRef<number>(0)
  const previousTab = useRef<string | undefined>(undefined)

  useEffect(() => {
    if (!path) {
      return
    }

    const isDifferentId = (options?.id || -1) !== previousId.current
    const isDifferentTab = options?.tab !== previousTab.current

    if ((isDifferentId || isDifferentTab) && (!options?.requireTab || options?.tab !== undefined)) {
      const pageView = isBrowserExtension ? `${PAGE_VIEW} ${BROWSER_EXTENSION}` : PAGE_VIEW

      let pageViewAction = `${pageView} ${path}`
      if (options?.list && options?.tab) {
        pageViewAction = `${pageView} ${LIST} ${path} ${TAB} ${options.tab}`
      } else if (options?.list) {
        pageViewAction = `${pageView} ${LIST} ${path}`
      } else if (options?.tab) {
        pageViewAction = `${pageView} ${path} ${TAB} ${options.tab}`
      }

      const actions: string[] = [pageView, pageViewAction]
      if (options?.country) {
        actions.push(`${COUNTRY} ${path} ${options.country}`)
      }

      rpcClient.rpc(
        data.trackActivity({
          actions,
        })
      )
    }

    previousId.current = options?.id || -1
    previousTab.current = options?.tab
  }, [isBrowserExtension, path, options])
}

export function useTrackDownload() {
  return useCallback((action: string, category: string) => {
    const actions: string[] = [DOWNLOAD, `${DOWNLOAD} ${action} ${category}`]

    rpcClient.rpc(
      data.trackActivity({
        actions,
      })
    )
  }, [])
}

export function useTrackDUpload() {
  return useCallback((action: string, category: string) => {
    const actions: string[] = [UPLOAD, `${UPLOAD} ${action} ${category}`]

    rpcClient.rpc(
      data.trackActivity({
        actions,
      })
    )
  }, [])
}
