import { useRpcClient } from '@gain/api/swr'
import { SlotHome, SlotName } from '@gain/components/slot'
import Head from '@gain/modules/head'
import { UserListItem } from '@gain/rpc/app-model'
import {
  formatListArgs,
  listFilter,
  listFilters,
  listSort,
  serializeListSort,
} from '@gain/rpc/utils'
import Hidden from '@mui/material/Hidden'
import React, { useCallback, useRef, useState } from 'react'

import { DropdownMenuOptions } from '../../../common/dropdown-menu'
import { ListPageActions } from '../../../common/list-page/list-page-actions'
import { ListPageHeaderContainer } from '../../../common/list-page/list-page-header-container'
import ListPageTitle from '../../../common/list-page/list-page-title'
import MainContainer from '../../../common/main-container'
import { ExportButton } from '../../../features/export/export-button'
import {
  checkboxList,
  createFilterMap,
  FilterModel,
  filterValueGroup,
  filterValueItem,
  option,
} from '../../../features/filter/filter-bar'
import { FilterConfig } from '../../../features/filter/filter-bar/filter-config/filter-config-model'
import ListView, { ListViewApi } from '../../../features/list-view'
import { useTrackPageView } from '../../../features/planhat/use-track-activity'
import TeamMemberDetailsDialog from '../../../features/team-members-list/team-member-details-dialog'
import { TeamMemberListItem } from '../../../features/team-members-list/team-member-list-item'
import TeamMembersInviteButton from '../../../features/team-members-list/team-members-invite-button'
import { userListItemTableColumns } from '../../../features/user-list-item-table/user-list-item-table-columns'

const TEAM_MEMBER_FILTER_FIELD = ['status'] as const

export type TeamMemberFilterField = (typeof TEAM_MEMBER_FILTER_FIELD)[number]

const TEAM_MEMBER_FILTER_MAP = createFilterMap<UserListItem, typeof TEAM_MEMBER_FILTER_FIELD>(
  checkboxList('status', 'Status', [
    option('Active', 'active'),
    option('Deactivated', 'deactivated'),
    option('Invited', 'invited'),
    option('Not invited', 'notInvited'),
  ])
)

/**
 * The list of filters in the "+ Add filter" dropdown menu.
 */
const TEAM_MEMBER_FILTER_MENU: DropdownMenuOptions<
  FilterConfig<UserListItem, TeamMemberFilterField>
> = [TEAM_MEMBER_FILTER_MAP.status]

const TEAM_MEMBER_DEFAULT_FILTERS: FilterModel<UserListItem, TeamMemberFilterField> = [
  filterValueGroup(filterValueItem('status')),
]

function useGetUserListItem() {
  const rpcClient = useRpcClient()

  return useCallback(
    async (userId: number) => {
      const result = await rpcClient({
        method: 'admin.listUsers',
        params: formatListArgs<UserListItem>({
          filter: listFilters(listFilter('userId', '=', userId)),
          limit: 1,
        }),
      })

      return result.items[0] || null
    },
    [rpcClient]
  )
}

export default function SettingsTeamMembersRoute() {
  useTrackPageView('settings', { tab: 'team-members', requireTab: true })

  const getUserListItem = useGetUserListItem()
  const listViewApi =
    useRef<ListViewApi<'admin.listUsers', UserListItem, UserListItem, TeamMemberFilterField>>(null)
  const [dialogUser, setDialogUser] = useState<UserListItem | null>(null)
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  const handleRenderPageActions = useCallback(
    (api: ListViewApi<'admin.listUsers', UserListItem, UserListItem, TeamMemberFilterField>) => {
      return [
        <Hidden smDown>
          <ExportButton
            method={'admin.exportUsers'}
            params={{
              filter: api.queryParams.filter,
              columns: [],
              filename: 'Users export - Gain.pro.xlsx',
              search: api.search || '',
              sort: api.sort.map(serializeListSort),
            }}
            variant={'text'}
          />
        </Hidden>,
        <TeamMembersInviteButton
          onUserInvited={() => {
            // Force refresh the user list
            api.swr.mutate()
          }}
        />,
      ]
    },
    []
  )

  return (
    <>
      <Head>
        <title>Team members</title>
      </Head>
      {dialogUser && (
        <TeamMemberDetailsDialog
          onClose={() => setDialogOpen(false)}
          onUserUpdated={async () => {
            listViewApi.current?.swr.mutate()

            if (dialogUser) {
              setDialogUser(await getUserListItem(dialogUser.userId))
            }
          }}
          open={dialogOpen}
          user={dialogUser}
        />
      )}
      <MainContainer fluid>
        <ListPageHeaderContainer>
          <ListPageTitle>Team members</ListPageTitle>
          <ListPageActions>
            <SlotHome slotName={SlotName.Tabs} />
          </ListPageActions>
        </ListPageHeaderContainer>
        <ListView
          addFilterMenu={TEAM_MEMBER_FILTER_MENU}
          apiRef={listViewApi}
          defaultFilterModel={TEAM_MEMBER_DEFAULT_FILTERS}
          defaultSort={[listSort<UserListItem>('firstName', 'asc')]}
          filterBarSearchLabel={'Name or email'}
          filterBarSearchPlaceholder={'E.g. William Davis'}
          filterConfigMap={TEAM_MEMBER_FILTER_MAP}
          md={{
            variant: 'virtual-table',
            VariantProps: {
              columns: userListItemTableColumns,
              onRowClick: ({ row }) => {
                setDialogUser(row)
                setDialogOpen(true)
              },
            },
          }}
          method={'admin.listUsers'}
          renderPageActions={handleRenderPageActions}
          xs={{
            variant: 'list',
            VariantProps: {
              renderListItem: (user) => <TeamMemberListItem user={user} />,
            },
          }}
          disableAddFilter
          disableDeleteFilter
          disableOrFilter
        />
      </MainContainer>
    </>
  )
}
