import Head from '@gain/modules/head'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import React from 'react'

import { useTrackPageView } from '../../../features/planhat/use-track-activity'
import IndustryResearchUpdates from './industry-research-updates'
import { ListUpdates } from './list-updates'
import NewsUpdates from './news-updates'

const StyledRoot = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
}))

export default function SettingsEmailRoute() {
  useTrackPageView('settings', { tab: 'email', requireTab: true })

  return (
    <>
      <Head>
        <title>Email preferences</title>
      </Head>

      <StyledRoot maxWidth={'md'}>
        <Grid
          direction={'column'}
          spacing={2}
          container>
          <Grid item>
            <Typography variant={'h2'}>Email preferences</Typography>
          </Grid>
          <Grid item>
            <NewsUpdates />
          </Grid>
          <Grid item>
            <IndustryResearchUpdates />
          </Grid>
          <Grid item>
            <ListUpdates />
          </Grid>
        </Grid>
      </StyledRoot>
    </>
  )
}
