import { useIndustryMarketSegmentList, useLegalEntity } from '@gain/api/app/hooks'
import Head from '@gain/modules/head'
import { Asset, AssetCon, AssetPro, IndustryMarketSegmentListItem } from '@gain/rpc/app-model'
import { AssetProfileType } from '@gain/rpc/shared-model'
import { listFilter } from '@gain/rpc/utils'
import { hasGroupRatings, ratingInvestmentCriteriaGroups } from '@gain/utils/investment-criteria'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'
import { createSvgIcon } from '@mui/material/utils'
import React, { useMemo } from 'react'

import CardIconList, { cardIconListClasses } from '../../../common/card/card-icon-list'
import IndustryEsgAssessmentCard from '../../../features/esg/esg-assessment-card'
import CardInvestmentCriteria from '../../../features/investment-criteria/investment-criteria-card'
import InvestmentCriteriaCard from '../../../features/investment-criteria/investment-criteria-card'

const ProIcon = createSvgIcon(
  <svg
    fill={'none'}
    height={'24'}
    viewBox={'0 0 24 24'}
    width={'24'}
    xmlns={'http://www.w3.org/2000/svg'}>
    <circle
      cx={'12'}
      cy={'12'}
      fill={'#45BFA2'}
      fillOpacity={'0.08'}
      r={'12'}
    />
    <path
      d={'M17.3332 8L9.99984 15.3333L6.6665 12'}
      stroke={'#45BFA2'}
      strokeLinecap={'round'}
      strokeLinejoin={'round'}
      strokeWidth={'1.5'}
    />
  </svg>,
  'Pro'
)

const ConIcon = createSvgIcon(
  <svg
    fill={'none'}
    height={'24'}
    viewBox={'0 0 24 24'}
    width={'24'}
    xmlns={'http://www.w3.org/2000/svg'}>
    <circle
      cx={'12'}
      cy={'12'}
      fill={'#ED5565'}
      fillOpacity={'0.08'}
      r={'12'}
    />
    <path
      d={'M16 8L8 16'}
      stroke={'#ED5565'}
      strokeLinecap={'round'}
      strokeLinejoin={'round'}
      strokeWidth={'1.5'}
    />
    <path
      d={'M8 8L16 16'}
      stroke={'#ED5565'}
      strokeLinecap={'round'}
      strokeLinejoin={'round'}
      strokeWidth={'1.5'}
    />
  </svg>,
  'Con'
)

const StyledCardIconList = styled(CardIconList)({
  [`& .${cardIconListClasses.cardContent}`]: {
    paddingTop: 6,
    paddingBottom: 6,
  },
})

function useArguments(items: Array<AssetCon | AssetPro> | undefined) {
  return useMemo(() => {
    if (!items) {
      return []
    }
    return items.map((argument) => argument.text)
  }, [items])
}

interface AssetAssessmentProps {
  asset: Asset
}

export default function AssetAssessment({ asset }: AssetAssessmentProps) {
  const pros = useArguments(asset.pros)
  const cons = useArguments(asset.cons)

  const { data } = useIndustryMarketSegmentList({
    filter: [
      listFilter<IndustryMarketSegmentListItem>('linkedAssetIds', '=', asset.id),
      listFilter<IndustryMarketSegmentListItem>('ratingOverall', '>=', 0),
    ],
  })

  const legalEntity = useLegalEntity(
    !asset.rating
      ? asset.legalEntities.find(({ financials }) => financials)?.legalEntityId ?? null
      : null
  )
  const legalEntityHasRatings = useMemo(() => {
    return ratingInvestmentCriteriaGroups.some((group) =>
      hasGroupRatings(group, legalEntity.data?.rating)
    )
  }, [legalEntity.data?.rating])

  return (
    <>
      <Head>
        <title>{asset.generalInfo?.name} - Assessment</title>
      </Head>
      <Grid
        spacing={2}
        container>
        {(pros.length > 0 || cons.length > 0) && (
          <Grid
            spacing={2}
            container
            item>
            <Grid
              md={6}
              xs={12}
              item>
              <StyledCardIconList
                icon={<ProIcon fontSize={'inherit'} />}
                items={pros}
                title={'Pros'}
                fullHeight
              />
            </Grid>
            <Grid
              md={6}
              xs={12}
              item>
              <StyledCardIconList
                icon={<ConIcon fontSize={'inherit'} />}
                items={cons}
                title={'Cons'}
                fullHeight
              />
            </Grid>
          </Grid>
        )}

        {legalEntityHasRatings && asset.profileType === AssetProfileType.Automated && (
          <Grid
            xs={12}
            item>
            <InvestmentCriteriaCard
              rating={legalEntity.data?.rating}
              ratingFromLegalEntity={legalEntity.data}
              variant={'grouped'}
            />
          </Grid>
        )}

        {asset.rating && (
          <Grid
            xs={12}
            item>
            <CardInvestmentCriteria rating={asset.rating} />
          </Grid>
        )}
        {data && data.items?.length > 0 && (
          <Grid
            xs={12}
            item>
            <IndustryEsgAssessmentCard segments={data.items} />
          </Grid>
        )}
      </Grid>
    </>
  )
}
