import { useDialogState } from '@gain/utils/dialog'

import ShareFeedbackBar from '../../../common/share-feedback-bar'
import ShareFeedbackDialog from './share-feedback-dialog'
import ShareFeedbackInfoDialog from './share-feedback-info-dialog'

export interface ShareFeedbackAlertProps {
  assetId: number
  assetName: string
  assetRegion: string
}

export default function ShareFeedbackAlert({
  assetId,
  assetName,
  assetRegion,
}: ShareFeedbackAlertProps) {
  const [shareFeedbackDialogOpen, handleOpenFeedbackDialog, handleCloseFeedbackDialog] =
    useDialogState()
  const [shareFeedbackInfoDialogOpen, handleOpenInfoDialog, handleCloseInfoDialog] =
    useDialogState()

  return (
    <>
      <ShareFeedbackDialog
        assetId={assetId}
        assetName={assetName}
        assetRegion={assetRegion}
        onClose={handleCloseFeedbackDialog}
        open={shareFeedbackDialogOpen}
      />

      <ShareFeedbackInfoDialog
        onClose={handleCloseInfoDialog}
        open={shareFeedbackInfoDialogOpen}
      />

      <ShareFeedbackBar
        onOpenFeedbackDialog={handleOpenFeedbackDialog}
        onOpenInfoDialog={handleOpenInfoDialog}
      />
    </>
  )
}
