import { IndustryListItem } from '@gain/rpc/app-model'
import { formatScopeAndCountries } from '@gain/utils/countries'
import React, { useMemo } from 'react'

import { CardContentEmpty } from '../../../../common/card/card'
import VirtualTable, { createVirtualTableColumns } from '../../../../common/virtual-table'
import ContentLinkIndustry from '../../../../features/industry/industry-content-link'

export interface AssetFurtherContextIndustriesTableProps {
  industries: IndustryListItem[]
}

export default function AssetFurtherContextIndustriesTable({
  industries,
}: AssetFurtherContextIndustriesTableProps) {
  const columns = useMemo(
    () =>
      createVirtualTableColumns<IndustryListItem>(
        {
          headerName: 'Report',
          field: 'title',
          sortable: false,
          width: 0,
          flex: 1,
          renderCell: ({ row }) => (
            <ContentLinkIndustry
              id={row.id}
              industry={row}
              logoFileUrl={row.smallImageFileUrl}
              title={row.title}
            />
          ),
        },
        {
          field: 'regions',
          headerName: 'Region',
          width: 96,
          sortable: false,
          align: 'right',
          valueFormatter: ({ value, row }) => formatScopeAndCountries(value, { scope: row.scope }),
        }
      ),
    []
  )

  if (industries.length === 0) {
    return <CardContentEmpty message={'No related industry reports available'} />
  }

  return (
    <VirtualTable
      columns={columns}
      RowComponentProps={() => ({
        hover: false,
      })}
      rows={industries}
      variant={'inline'}
    />
  )
}
