import { CurrencyListItem } from '@gain/rpc/app-model'
import * as Shared from '@gain/rpc/shared-model'
import { isDefined } from '@gain/utils/common'
import { currencyDisplayedAs } from '@gain/utils/currency'
import { useElementWidth } from '@gain/utils/dom'
import { FinancialResult } from '@gain/utils/financials'
import { useValueCellWidth } from '@gain/utils/table'
import Divider from '@mui/material/Divider'
import Fade from '@mui/material/Fade'
import Stack from '@mui/material/Stack'
import { styled, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import ButtonScroll from '../../../common/button-scroll'
import FinancialRevenueAIGeneratedExplainer from './financial-ai-generated-explainer'
import FinancialLegendChip from './financial-legend-chip'
import FinancialResultsHeaderCell from './financial-results-header-cell'
import { FinancialResultsTableContext } from './financial-results-table-context'
import { useFinancialResultsTableContextType } from './financial-results-table-hooks'
import { isFirstForecastedYear, RowGroupConfig } from './financial-results-table-utils'
import FinancialRow from './financial-row'

const StyledContainer = styled('div')({
  position: 'relative',
})

const StyledTableContainer = styled('div')({
  overflow: 'auto',
  position: 'relative',
})

const StyledTable = styled('table')({
  width: '100%',
  borderCollapse: 'initial',
  borderSpacing: 0,
})

const StyledTBody = styled('tbody')({
  '&:last-of-type tr:last-of-type th:first-of-type': {
    borderBottomLeftRadius: 8,
  },
})

const StyledButtonScroll = styled(ButtonScroll)(({ theme, direction }) => ({
  top: 0,
  position: 'absolute',

  ...(direction === 'left' && {
    padding: '3px 0 3px 4px',
  }),

  ...(direction === 'right' && {
    right: 0,
    padding: '3px 4px 3px 0',
  }),
}))

interface StyledThProps {
  width: number
  paddingX: number
  borderRight?: boolean
  borderLeft?: boolean
  textAlign?: 'right'
}

const StyledTh = styled('th', {
  shouldForwardProp: (prop) =>
    !['width', 'paddingX', 'borderRight', 'borderLeft', 'textAlign'].includes(prop.toString()),
})<StyledThProps>(({ theme, width, paddingX, borderRight, borderLeft, textAlign }) => ({
  color: theme.palette.text.secondary,
  ...theme.typography.body2,
  height: 40,
  textAlign: textAlign || 'left',
  padding: theme.spacing(0, paddingX),
  boxSizing: 'border-box',
  minWidth: width,
  maxWidth: width,
  width: width,
  zIndex: 2,

  '&:first-of-type': {
    position: 'sticky',
    left: 0,
    backgroundColor: theme.palette.common.white,
  },

  ...(borderRight && {
    borderRight: `1px solid ${theme.palette.divider}`,
    boxShadow: '2px 0px 5px rgba(0, 0, 0, 0.09)',
    clipPath: 'inset(0px -15px 0px 0px)',
  }),

  ...(borderLeft && {
    borderLeft: `1px dashed ${theme.palette.divider}`,
  }),
}))

function getVisibleRowGroups(rowGroups: RowGroupConfig[], financials: FinancialResult[]) {
  const visibleGroups = rowGroups.reduce((acc, group) => {
    const hasDefinedValues = group.some((row) => {
      return financials.some((financial) => isDefined(row.valueFn?.(financial)))
    })

    if (hasDefinedValues) {
      acc.push(group)
    }

    return acc
  }, new Array<RowGroupConfig>())

  return visibleGroups.map((group) => {
    return group.filter((row) => {
      // If the row doesn't have a valueFn it is used to only display a label.
      // We only show a label row when there is more than 1 visible group.
      if (!row.valueFn && visibleGroups.length > 1) {
        return true
      }
      return financials.some((financial) => isDefined(row.valueFn?.(financial)))
    })
  })
}

export interface FinancialResultsTableProps {
  currency?: CurrencyListItem | null
  financialResults: FinancialResult[]
  rowGroups: RowGroupConfig[]
  rightColumnIndex: number
  hasEstimates?: boolean
  hasForecasts?: boolean
  hasAIEstimates?: boolean
}

export default function FinancialResultsTable({
  currency,
  financialResults,
  rowGroups,
  rightColumnIndex,
  hasEstimates,
  hasForecasts,
  hasAIEstimates,
}: FinancialResultsTableProps) {
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.only('xs'))
  const api = useFinancialResultsTableContextType()
  const ref = useRef<HTMLDivElement>(null)
  const width = useElementWidth(ref)
  const availableWidth = width - api.labelCellWidth
  const [isScrollStart, setIsScrollStart] = useState(false)
  const [isScrollEnd, setIsScrollEnd] = useState(false)
  const [canScroll, setCanScroll] = useState(false)
  const visibleRowGroups = getVisibleRowGroups(rowGroups, financialResults)

  const valueCellWidth = useValueCellWidth(
    availableWidth,
    financialResults.length,
    api.minValueCellWidth
  )

  useEffect(() => {
    if (ref.current) {
      setCanScroll(ref.current.scrollWidth > ref.current.clientWidth)
    }
  }, [width, ref])

  // Keep last reported financial result inside the viewport when available width changes
  // or on initialize. When more reported financial results fit inside the available width,
  // scroll all the way to the end.
  useEffect(() => {
    if (!ref.current) {
      return
    }

    let scrollTo = ref.current.scrollWidth - width

    if (rightColumnIndex !== -1) {
      scrollTo = Math.min(scrollTo, valueCellWidth * rightColumnIndex)
    }

    ref.current.scrollLeft = scrollTo
  }, [width, ref, financialResults, valueCellWidth, rightColumnIndex])

  const handleScrollTo = useCallback(
    (direction: 'left' | 'right') => () => {
      if (ref.current) {
        const offset =
          direction === 'left'
            ? ref.current.scrollLeft - availableWidth
            : ref.current.scrollLeft + availableWidth

        ref.current.scrollTo({
          left: offset,
          behavior: 'smooth',
        })
      }
    },
    [ref, availableWidth]
  )

  const handleScroll = useCallback(() => {
    const element = ref.current

    if (!element) {
      return
    }

    setIsScrollStart(element.scrollLeft === 0)
    setIsScrollEnd(element.scrollLeft + element.clientWidth >= element.scrollWidth)
  }, [])

  return (
    <FinancialResultsTableContext.Provider value={api}>
      <StyledContainer>
        {!isXs && canScroll && (
          <>
            <Fade in={!isScrollStart}>
              <StyledButtonScroll
                direction={'left'}
                onClick={handleScrollTo('left')}
                sx={{
                  left: api.labelCellWidth,
                }}
              />
            </Fade>
            <Fade in={!isScrollEnd}>
              <StyledButtonScroll
                direction={'right'}
                onClick={handleScrollTo('right')}
              />
            </Fade>
          </>
        )}
        <StyledTableContainer
          ref={ref}
          onScroll={handleScroll}>
          <StyledTable>
            <thead>
              <tr>
                <StyledTh
                  borderRight={canScroll}
                  paddingX={api.cellPaddingX}
                  sx={{ pl: api.leftCellPaddingLeft }}
                  width={api.labelCellWidth}>
                  {currency && `Amounts in ${currencyDisplayedAs(currency)}`}
                </StyledTh>
                {financialResults.map((financial, index) => (
                  <StyledTh
                    key={`${financial.year}-${financial.periodicity}`}
                    borderLeft={isFirstForecastedYear(financialResults, index)}
                    paddingX={api.cellPaddingX}
                    textAlign={'right'}
                    width={valueCellWidth}>
                    <FinancialResultsHeaderCell financial={financial} />
                  </StyledTh>
                ))}
              </tr>
            </thead>
            {visibleRowGroups.map((group, groupIndex, groups) => (
              <StyledTBody key={groupIndex}>
                {group.map((row, rowIndex) => (
                  <FinancialRow
                    key={rowIndex}
                    amountTypeFn={row.amountTypeFn}
                    bold={isDefined(row.bold) ? row.bold : rowIndex === 0}
                    divider={canScroll}
                    financials={financialResults}
                    formatFn={row.formatFn}
                    label={row.label}
                    labelLetterSpacing={row.labelLetterSpacing}
                    thickBorders={row.thickBorders}
                    valueFn={row.valueFn}
                  />
                ))}
              </StyledTBody>
            ))}
          </StyledTable>
        </StyledTableContainer>
      </StyledContainer>

      {(hasEstimates || hasForecasts || hasAIEstimates) && (
        <>
          <Divider />
          <Stack
            alignItems={'center'}
            direction={'row'}
            justifyContent={'center'}
            spacing={0.5}
            sx={{ height: 42 }}>
            <FinancialLegendChip
              amountType={Shared.FinancialResultAmountType.Actual}
              label={'Reported'}
            />
            {hasEstimates && (
              <FinancialLegendChip
                amountType={Shared.FinancialResultAmountType.Estimated}
                label={'Estimate'}
              />
            )}
            {hasForecasts && (
              <FinancialLegendChip
                isForecast={true}
                label={'Forecast'}
              />
            )}
            {hasAIEstimates && <FinancialRevenueAIGeneratedExplainer />}
          </Stack>
        </>
      )}
    </FinancialResultsTableContext.Provider>
  )
}
