import Flag from '@gain/components/flag'
import { AssetListItem } from '@gain/rpc/app-model'
import { ListSort } from '@gain/rpc/list-model'
import { ColumnVisibilityModel } from '@gain/utils/table'
import Checkbox from '@mui/material/Checkbox'
import Tooltip from '@mui/material/Tooltip'
import { useMemo } from 'react'

import { ContentLinkCellContainer } from '../../../common/content-link'
import { createTableColumns } from '../../../common/table'
import useCheckboxSelection from '../../../common/virtual-table/use-checkbox-selection'
import AssetContentLink from '../../asset/asset-content-link'
import AssetFte from '../../asset/asset-fte'
import FinancialValue from '../../financial/financial-value'
import { HandleSelectionChange } from '../../list-view/core/use-list-view-row-selection'
import AssetTags from './asset-tags'

export function useSimilarCompaniesDefaultSort() {
  return useMemo(
    (): ListSort<AssetListItem>[] => [{ field: 'matchingTagsCount', direction: 'desc' }],
    []
  )
}

export function useSimilarCompaniesSelectColumns(
  totalItems: number,
  handleSelectionChange: HandleSelectionChange,
  selectedRowIndexes: number[]
) {
  const columns = useCreateTableColumns()
  const checkboxSelection = useCheckboxSelection(
    selectedRowIndexes,
    totalItems,
    handleSelectionChange
  )

  return useMemo(
    () =>
      createTableColumns<AssetListItem>(
        {
          field: 'id',
          width: 64,
          renderCell: (params) => (
            <Checkbox
              checked={selectedRowIndexes?.includes(params.rowIndex)}
              onClick={checkboxSelection.toggleCheckbox(params.rowIndex)}
            />
          ),
          renderHeader: () => (
            <Tooltip
              title={checkboxSelection.isAllSelected ? 'Deselect all' : 'Select all'}
              disableInteractive>
              <Checkbox
                checked={checkboxSelection.isAllSelected}
                indeterminate={checkboxSelection.isIndeterminate}
                onChange={checkboxSelection.toggleSelection}
                onClick={(event) => event.stopPropagation()}
              />
            </Tooltip>
          ),
        },
        ...columns
      ),
    [checkboxSelection, columns, selectedRowIndexes]
  )
}

export const columnVisibility: ColumnVisibilityModel<AssetListItem> = {
  description: 800,
  tagIds: 1100,
  fte: 600,
  region: 440,
}

export function useCreateTableColumns() {
  return useMemo(
    () =>
      createTableColumns<AssetListItem>(
        {
          headerName: 'Name',
          field: 'name',
          renderCell: ({ row }) => (
            <ContentLinkCellContainer>
              <AssetContentLink
                asset={row}
                id={row.id}
                logoFileUrl={row.logoFileUrl}
                name={row.name}
              />
            </ContentLinkCellContainer>
          ),
        },
        {
          headerName: 'Business description',
          field: 'description',
        },
        {
          field: 'revenueEur',
          headerName: 'Revenue',
          align: 'right',
          width: 120,
          renderCell: ({ value, row }) => (
            <FinancialValue
              amount={value}
              year={row.revenueYear}
            />
          ),
        },
        {
          field: 'fte',
          headerName: 'FTEs',
          align: 'right',
          width: 144,
          renderCell: ({ row }) => (
            <AssetFte
              fte={row.fte}
              range={row.fteRange}
              year={row.fteYear}
            />
          ),
        },
        {
          field: 'region',
          headerName: 'Company HQ',
          align: 'right',
          width: 124,
          renderCell: ({ value }) =>
            value ? (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Flag code={value} />
              </div>
            ) : (
              <>-</>
            ),
        },
        {
          field: 'tagIds',
          headerName: 'Tags',
          align: 'left',
          width: 344,
          renderCell: ({ row }) => (
            <AssetTags
              tagIds={row.tagIds}
              tags={row.tags}
            />
          ),
        }
      ),
    []
  )
}
