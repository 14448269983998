import { UserProfile } from '@gain/rpc/app-model'
import { UserRole } from '@gain/rpc/shared-model'

export function isAdministrator(role?: UserRole | null): boolean {
  return role != null && (role === UserRole.Admin || role === UserRole.Superadmin)
}

export function isGainProUser(profile: UserProfile) {
  return profile.username.endsWith('@gain.pro')
}

export function getUserInitials(name: string): string {
  const nameParts = name.split(' ')
  return `${nameParts[0][0]}${nameParts[nameParts.length - 1][0]}`.toUpperCase()
}
