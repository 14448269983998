import { yupResolver } from '@hookform/resolvers/yup'
import LoadingButton from '@mui/lab/LoadingButton'
import Dialog, { dialogClasses, DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import { MouseEvent, ReactNode, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

import DialogHeader from '../../../common/dialog-header'
import { MAX_BOOKMARKS_LIST_NAME_LENGTH } from '../bookmark-list-contants'

interface FormData {
  title: string
}

const FormSchema = Yup.object().shape({
  title: Yup.string()
    .trim()
    .required('List name is required')
    .max(MAX_BOOKMARKS_LIST_NAME_LENGTH, 'List name must be less than 70 characters'),
})

const StyledDialog = styled(Dialog)({
  [`& .${dialogClasses.paper}`]: {
    maxWidth: 392,
  },
})

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(1.5),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}))

interface BookmarksListTitleDialogProps extends Omit<DialogProps, 'onSubmit'> {
  open: boolean
  onClose: () => void
  onSubmit: (title: string) => Promise<void> | void
  dialogTitle: string
  value?: string
  infoSlot?: ReactNode
  titleLabel?: boolean
}

export default function BookmarksListTitleDialog({
  open,
  onClose,
  onSubmit,
  dialogTitle,
  value = '',
  infoSlot,
  titleLabel = false,
  ...props
}: BookmarksListTitleDialogProps) {
  const [isSaving, setIsSaving] = useState(false)

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      title: value,
    },
    resolver: yupResolver(FormSchema),
  })

  useEffect(() => {
    if (open) {
      reset({
        title: value,
      })
    }
  }, [open, reset, value])

  const disableSubmit = watch('title').trim().length === 0

  const submit = handleSubmit(async (data) => {
    try {
      setIsSaving(true)
      await onSubmit(data.title)
    } finally {
      setIsSaving(false)
    }
  })

  const handleClose = (event: MouseEvent) => {
    event.stopPropagation()
    onClose()
  }

  return (
    <StyledDialog
      {...props}
      onClose={handleClose}
      open={open}
      fullWidth>
      <DialogHeader
        onCloseClick={handleClose}
        title={dialogTitle}
      />

      <form onSubmit={submit}>
        <StyledDialogContent>
          {infoSlot}

          <TextField
            autoCapitalize={'on'}
            error={!!errors.title}
            helperText={errors.title?.message}
            inputProps={{ ...register('title') }}
            label={titleLabel ? 'Name' : undefined}
            placeholder={'Enter list title'}
            type={'text'}
            variant={'outlined'}
            autoFocus
            fullWidth
          />
        </StyledDialogContent>

        <DialogActions>
          <LoadingButton
            color={'primary'}
            disabled={disableSubmit}
            loading={isSaving}
            type={'submit'}
            variant={'contained'}
            fullWidth>
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </StyledDialog>
  )
}
