import Typography from '@gain/components/typography'
import { DealListItem } from '@gain/rpc/app-model'
import { useSplitList } from '@gain/utils/list'
import { useVisibleColumns } from '@gain/utils/table'
import { styled } from '@mui/material/styles'
import { useCallback, useRef, useState } from 'react'

import Table from '../../../../common/table'
import { columnVisiblitity, useDealTableColumns } from './table-deals-columns'
import { useVisibleDealRows } from './table-deals-utils'

interface TableDealsContainerProps {
  deals: DealListItem[]
  disableDealMetrics?: boolean
  disableDealTargetMetrics?: boolean
}

const StyledMoreContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 3),
  minHeight: 40,
  display: 'flex',
  alignItems: 'center',
}))

export default function TableDealsContainer({
  deals,
  disableDealMetrics,
  disableDealTargetMetrics,
}: TableDealsContainerProps) {
  const tableRef = useRef<HTMLDivElement>(null)
  const [showAll, setShowAll] = useState<boolean>(false)
  const [initialDeals, additionalDeals] = useSplitList(deals, 3)
  const rows = useVisibleDealRows(initialDeals, additionalDeals, showAll)

  const columns = useDealTableColumns({
    disableDealTargetMetrics,
    disableDealMetrics,
  })

  const handleShowAll = useCallback(() => {
    setShowAll(true)
  }, [])

  const visibleColumns = useVisibleColumns(tableRef, columns, columnVisiblitity)

  return (
    <>
      <Table
        ref={tableRef}
        columns={visibleColumns}
        rows={rows.data}
      />
      {additionalDeals.length > 0 && !showAll && (
        <StyledMoreContainer>
          <Typography
            color={'primary'}
            onClick={handleShowAll}
            sx={{ cursor: 'pointer' }}
            variant={'body2'}>
            + {additionalDeals.length} more
          </Typography>
        </StyledMoreContainer>
      )}
    </>
  )
}
