import { paperClasses } from '@mui/material/Paper'
import { Components, Theme } from '@mui/material/styles'

export const MuiMenu = (theme: Theme): Components['MuiMenu'] => ({
  defaultProps: {
    autoFocus: false,
  },
  styleOverrides: {
    paper: {
      [`&.${paperClasses.root}`]: {
        boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.06)',
      },
      borderRadius: 8,
      padding: theme.spacing(1),
      border: `1px solid ${theme.palette.divider}`,
    },
    list: {
      minWidth: 152,
      padding: 0,
    },
  },
})
