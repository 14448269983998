import { DealItemStatus, DealReason, DealSideShare, DealStatus } from './lib/rpc-shared-model'
import { ChipOption, Option } from './shared-model-interfaces'

export const DEAL_STATUS_OPTIONS: ReadonlyArray<Option<DealStatus>> = [
  {
    label: 'Live / rumoured',
    value: DealStatus.Live,
  },
  {
    label: 'Aborted',
    value: DealStatus.Aborted,
  },
]

export const DEAL_ITEM_STATUS_OPTIONS: ReadonlyArray<ChipOption<DealItemStatus>> = [
  {
    label: 'Draft',
    value: DealItemStatus.New,
    color: 'info',
  },
  {
    label: 'Review',
    value: DealItemStatus.Review,
    color: 'warning',
  },
  {
    label: 'Published',
    value: DealItemStatus.Published,
    color: 'success',
  },
]

export const DEAL_SIDE_SHARE_OPTIONS: ReadonlyArray<Option<DealSideShare>> = [
  {
    label: 'Majority',
    value: DealSideShare.Majority,
  },
  {
    label: 'Minority',
    value: DealSideShare.Minority,
  },
  {
    label: 'Shared majority',
    value: DealSideShare.SharedMajority,
  },
]

export const DEAL_REASON_SELLER_OPTIONS: ReadonlyArray<ChipOption<DealReason>> = [
  {
    label: 'Divestiture',
    value: DealReason.Divestiture,
    color: 'error',
  },
  {
    label: 'Strategic exit',
    value: DealReason.StrategicExit,
    color: 'error',
  },
  {
    label: 'IPO',
    value: DealReason.IPO,
    color: 'success',
  },
]

export const DEAL_REASON_BUYER_OPTIONS: ReadonlyArray<ChipOption<DealReason>> = [
  {
    label: 'Platform',
    value: DealReason.Platform,
    color: 'info',
  },
  {
    label: 'Public-to-private',
    value: DealReason.PublicToPrivate,
    color: 'success',
  },
  {
    label: 'VC-Round',
    value: DealReason.VcRound,
    color: 'warning',
  },
]

export const DEAL_REASON_OPTIONS: ReadonlyArray<ChipOption<DealReason>> = [
  ...DEAL_REASON_BUYER_OPTIONS,
  ...DEAL_REASON_SELLER_OPTIONS,
]
