import { LinkedInIcon } from '@gain/components/icons'
import { fixUrl } from '@gain/utils/common'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'

const StyledIconButton = styled(IconButton)(({ disabled }) => ({
  fontSize: 18,
  ...(disabled && {
    opacity: 0.23,
    filter: 'grayscale(1)',
  }),
}))

const StyledPlaceholder = styled('div')({
  width: 30,
})

interface LinkedInButtonProps {
  url?: string | null
}

export default function LinkedInButton({ url }: LinkedInButtonProps) {
  if (!url) {
    return <StyledPlaceholder />
  }

  return (
    <StyledIconButton
      onClick={() => window.open(fixUrl(url), '_blank', 'noopener,noreferrer')}
      size={'small'}>
      <LinkedInIcon />
    </StyledIconButton>
  )
}
