import {
  BookmarkIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  HashIcon,
  ResetIcon,
} from '@gain/components/icons'
import Button from '@mui/material/Button'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Menu, { menuClasses } from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'
import { useState } from 'react'

import { MenuDivider } from '../../common/menu-divider'

enum MenuPage {
  Start = 'start',
  BenchmarkSize = 'benchmark-size',
}

interface BenchmarkingTableMenuProps {
  allowAdjustBenchmarkSize: boolean
  hasChanges: boolean
  benchmarkSize: number
  onSetBenchmarkSize: (size: number) => void
  onReset: () => void
  onSaveBookmark: () => void
}

const StyledMenu = styled(Menu)(({ theme }) => ({
  [`& .${menuClasses.paper}`]: {
    width: 238,
  },
}))

export default function BenchmarkingTableMenu({
  allowAdjustBenchmarkSize,
  hasChanges,
  benchmarkSize,
  onSetBenchmarkSize,
  onReset,
  onSaveBookmark,
}: BenchmarkingTableMenuProps) {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)
  const [menuPage, setMenuPage] = useState(MenuPage.Start)

  const closeMenu = () => setMenuAnchorEl(null)

  return (
    <>
      <Button
        color={'primary'}
        onClick={(event) => {
          setMenuPage(MenuPage.Start)
          setMenuAnchorEl(event.currentTarget)
        }}
        variant={'text'}>
        More <ChevronDownIcon />
      </Button>

      <StyledMenu
        anchorEl={menuAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        onClose={closeMenu}
        open={Boolean(menuAnchorEl)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        {menuPage === MenuPage.Start && (
          <div /* The MUI menu component doesn't support fragments */>
            {allowAdjustBenchmarkSize && (
              <MenuItem onClick={() => setMenuPage(MenuPage.BenchmarkSize)}>
                <ListItemIcon>
                  <HashIcon />
                </ListItemIcon>
                <ListItemText>Adjust benchmark size</ListItemText>
                <ChevronRightIcon />
              </MenuItem>
            )}
            <MenuItem
              onClick={() => {
                closeMenu()
                onSaveBookmark()
              }}>
              <ListItemIcon>
                <BookmarkIcon />
              </ListItemIcon>
              <ListItemText>Save to bookmark</ListItemText>
            </MenuItem>
            <MenuItem
              disabled={!hasChanges}
              onClick={() => {
                closeMenu()
                onReset()
              }}>
              <ListItemIcon>
                <ResetIcon />
              </ListItemIcon>
              <ListItemText>Reset</ListItemText>
            </MenuItem>
          </div>
        )}

        {menuPage === MenuPage.BenchmarkSize && (
          <div>
            <MenuItem onClick={() => setMenuPage(MenuPage.Start)}>
              <ListItemIcon>
                <ChevronLeftIcon />
              </ListItemIcon>
              <ListItemText>Back to menu</ListItemText>
            </MenuItem>
            <MenuDivider />
            {[10, 20, 30, 50, 75, 100, 150, 200, 300, 500].map((size) => (
              <MenuItem
                key={size}
                onClick={() => {
                  closeMenu()
                  onSetBenchmarkSize(size)
                }}
                selected={benchmarkSize === size}>
                <ListItemIcon />
                <ListItemText>{size}</ListItemText>
              </MenuItem>
            ))}
          </div>
        )}
      </StyledMenu>
    </>
  )
}
