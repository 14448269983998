import Flag from '@gain/components/flag'
import { AssetListItem } from '@gain/rpc/app-model'
import React, { useMemo } from 'react'

import Table, { createTableColumns } from '../../../../common/table'
import AssetContentLink from '../../../../features/asset/asset-content-link'
import FinancialValue from '../../../../features/financial/financial-value'

export interface AssetMarketTableProps {
  rows: AssetListItem[]
  disableHqColumn: boolean
}

export default function AssetMarketTable({ rows, disableHqColumn }: AssetMarketTableProps) {
  const columns = useMemo(() => {
    return createTableColumns<AssetListItem>(
      {
        headerName: 'Competitor',
        field: 'name',
        renderCell: ({ row }) => (
          <AssetContentLink
            id={row.id}
            logoFileUrl={row.logoFileUrl}
            name={row.name}
          />
        ),
      },
      !disableHqColumn && {
        field: 'region',
        headerName: 'HQ',
        align: 'center',
        width: 48,
        renderCell: ({ value }) => (value ? <Flag code={value} /> : <>-</>),
      },
      {
        field: 'revenueEur',
        headerName: 'Revenue',
        align: 'right',
        width: 110,
        renderCell: ({ value }) => <FinancialValue amount={value} />,
      }
    )
  }, [disableHqColumn])

  return (
    <Table
      columns={columns}
      rows={rows}
      dense
      disablePaddingBottomOnLastRow
    />
  )
}
