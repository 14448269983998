import { useSimilarAssets, useSimilarAssetsDeals } from '@gain/api/app/hooks'
import { CompanyIcon } from '@gain/components/icons'
import { Asset, SimilarAssetDealsListItem } from '@gain/rpc/app-model'
import { DealReason } from '@gain/rpc/shared-model'
import { listFilter, listFilters, serializeListSortArray } from '@gain/rpc/utils'
import { formatMonthYear } from '@gain/utils/date'
import { formatDealListItemTitle } from '@gain/utils/deal'
import { useSplitList } from '@gain/utils/list'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import { useMemo, useState } from 'react'

import Card, { CardHeader } from '../../common/card/card'
import ContentLink from '../../common/content-link'
import ViewMoreButton from '../../common/view-more-button'
import VirtualTable, { createVirtualTableColumns, VirtualSort } from '../../common/virtual-table'
import { generateDealPagePath } from '../../routes/utils'
import MultipleTableCell from '../asset/asset-list-item-table/table-cell/multiple-table-cell'
import TooltipDeal from '../deal/deal-tooltip'
import FilterBar, {
  createFilterMap,
  dealType,
  FilterModel,
  filterValueGroup,
  filterValueItem,
  fromFilterModel,
} from '../filter/filter-bar'
import FinancialValue from '../financial/financial-value'

const FILTER_FIELDS = ['reasons'] as const
type SimilarAssetDealsListItemFilterField = (typeof FILTER_FIELDS)[number]

const FILTER_MAP = createFilterMap<SimilarAssetDealsListItem, typeof FILTER_FIELDS>(
  dealType('reasons', 'Deal type')
)

type DealFilterModel = FilterModel<SimilarAssetDealsListItem, SimilarAssetDealsListItemFilterField>
const DEAL_DEFAULT_FILTERS: DealFilterModel = [filterValueGroup(filterValueItem('reasons'))]

const PAGE_SIZE = 10

const useColumns = (filterModel: DealFilterModel) =>
  useMemo(() => {
    const filterDealReasons = filterModel.flatMap<DealReason>(
      (group) => group.value.find((filter) => filter.filterId === 'reasons')?.value || []
    )

    return createVirtualTableColumns<SimilarAssetDealsListItem>(
      {
        headerName: 'Deal',
        field: 'asset',
        width: 208,
        sticky: true,
        defaultSortDirection: 'asc',
        renderCell: ({ row }) => (
          <TooltipDeal id={row.id}>
            <ContentLink
              avatarProps={{
                src: row.assetLogoFileUrl || undefined,
                children: !row.assetLogoFileUrl && <CompanyIcon />,
              }}
              href={generateDealPagePath({
                id: row.id,
                name: row.asset || '',
              })}
              label={row.asset || ''}
              region={row.region}
            />
          </TooltipDeal>
        ),
      },
      {
        field: 'linkedAssetDescription',
        headerName: 'Business description',
        width: 200,
        flex: 1,
        sortable: false,
        valueFormatter: ({ value }) => value || '-',
      },
      {
        field: 'dealType',
        headerName: 'Deal type',
        width: 112,
        sortFields: ['reasons', 'fundingRoundType'],
        valueFormatter: ({ row }) => formatDealListItemTitle(row, filterDealReasons),
      },
      {
        field: 'announcementDate',
        headerName: 'Date',
        align: 'right',
        width: 90,
        sortFields: ['announcementDate', 'publicationDate'],
        valueFormatter: ({ row }) =>
          formatMonthYear(row.announcementDateMonth, row.announcementDateYear),
      },
      {
        field: 'relevanceRank',
        headerName: 'Rank',
        headerExplainer:
          'This represents how relevant a transaction is for this company profile. The rank takes several factors into account including how similar the target company is to this company profile, how recent the deal was, and to what extent deal metrics are available.',
        align: 'right',
        width: 88,
        defaultSortDirection: 'asc',
        valueFormatter: ({ value }) => `#${value}`,
      },
      {
        field: 'evEur',
        headerName: 'EV',
        align: 'right',
        width: 110,
        renderCell: ({ value }) => <FinancialValue amount={value} />,
      },
      {
        field: 'evEbitdaMultiple',
        headerName: 'EV / EBITDA',
        align: 'right',
        width: 110,
        renderCell: ({ value }) => (
          <MultipleTableCell
            precision={1}
            value={value}
            enableNotMeaningful
          />
        ),
      },
      {
        field: 'evEbitMultiple',
        headerName: 'EV / EBIT',
        align: 'right',
        width: 110,
        renderCell: ({ value }) => (
          <MultipleTableCell
            precision={1}
            value={value}
            enableNotMeaningful
          />
        ),
      },
      {
        field: 'evRevenueMultiple',
        headerName: 'EV / Sales',
        align: 'right',
        width: 110,
        renderCell: ({ value }) => (
          <MultipleTableCell
            precision={1}
            value={value}
            enableNotMeaningful
          />
        ),
      }
    )
  }, [filterModel])

interface AssetSimilarDealsCardProps {
  asset: Asset
}

export default function AssetSimilarDealsCard({ asset }: AssetSimilarDealsCardProps) {
  const [onlyWithEv, setOnlyWithEv] = useState(false)
  const [filterModel, setFilterModel] = useState(DEAL_DEFAULT_FILTERS)
  const [sort, setSort] = useState<VirtualSort<SimilarAssetDealsListItem>[]>([
    { field: 'relevanceRank', direction: 'asc' },
  ])

  const columns = useColumns(filterModel)
  const filters = fromFilterModel(filterModel, FILTER_MAP)
  const hasFilters = filters.length > 0 || onlyWithEv

  // Fetch similar assets first
  const swrSimilarAssets = useSimilarAssets(asset.id, 500)

  // Fetch similar deals based on similar assets, it will not run until similar assets are fetched.
  const swrSimilarAssetsDeals = useSimilarAssetsDeals(
    swrSimilarAssets.data
      ? {
          similarToIds: swrSimilarAssets.data.items.map((s) => s.id),
          limit: 500,
          filter: listFilters<SimilarAssetDealsListItem>(
            onlyWithEv && listFilter('ev', '>', 0),
            ...filters
          ),
          sort: serializeListSortArray(sort),
        }
      : null
  )

  const isLoading = swrSimilarAssets.loading || swrSimilarAssetsDeals.loading

  const [showAmount, setShowAmount] = useState(PAGE_SIZE)
  const [rowsToShow, rowsToShowMore] = useSplitList(
    swrSimilarAssetsDeals.data?.items,
    showAmount,
    false
  )

  const handleFilterChange = (
    value: FilterModel<SimilarAssetDealsListItem, SimilarAssetDealsListItemFilterField>
  ) => {
    setShowAmount(PAGE_SIZE)
    setFilterModel(value)
  }

  const handleSortChange = (newSort: VirtualSort<SimilarAssetDealsListItem>[]) => {
    setSort(newSort)
  }

  // If there is no data, set the minimum height to 400 so the loading spinner and
  // empty table messages are nicely aligned. However, if there is data, the card
  // should be able to shrink if there are only a few rows.
  const cardMinHeight = swrSimilarAssetsDeals.data?.items.length ? undefined : 400

  return (
    <Box
      // This box is a container with a minimum height around the virtual table to reserve
      // space on the page and prevent the page from collapsing too much when the table
      // size is small. Without this reserved space the page will scroll upwards when
      // the table size changes when changing filters and loading, which is a UX annoyance.
      // Note: This solution can be removed if new cards are added below this table.
      minHeight={530}>
      <Card sx={{ minHeight: cardMinHeight }}>
        <CardHeader
          actions={
            <FormControlLabel
              color={'filter'}
              control={
                <Checkbox
                  checked={onlyWithEv}
                  onChange={() => setOnlyWithEv(!onlyWithEv)}
                />
              }
              label={'Only show deals with EV'}
              labelPlacement={'start'}
            />
          }
          title={'Precedent transactions'}
        />
        <FilterBar<SimilarAssetDealsListItem, SimilarAssetDealsListItemFilterField>
          defaultFilterModel={DEAL_DEFAULT_FILTERS}
          filterConfigMap={FILTER_MAP}
          gaCategory={'Deal'}
          initialFilterModel={filterModel}
          onFilterChange={handleFilterChange}
          variant={'inline'}
          disableAddFilter
          disableClear
          disableDeleteFilter
          disableOrFilter
          disablePadding
        />
        <VirtualTable
          columns={columns}
          loadingInitial={isLoading}
          onSort={handleSortChange}
          RowComponentProps={() => ({
            hover: false,
          })}
          rows={rowsToShow}
          sort={sort}
          tableEmptyProps={{
            message: hasFilters ? 'Please adjust your filter settings' : 'No data available',
            title: 'No deals',
          }}
          variant={'inline'}
          disablePaddingStart
        />
        {rowsToShowMore.length > 0 && (
          <>
            <Divider />
            <ViewMoreButton
              amount={Math.min(rowsToShowMore.length, PAGE_SIZE)}
              onClick={() => setShowAmount(showAmount + PAGE_SIZE)}
              variant={'chevron-down'}
            />
          </>
        )}
      </Card>
    </Box>
  )
}
