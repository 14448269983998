import { AssetListItem } from '@gain/rpc/app-model'
import { CartesianAxisOption } from 'echarts/types/src/coord/cartesian/AxisModel'

import { Axis, AxisConfig } from './axis-config'
import { axisLinearConfig, clamp } from './axis-linear'

// The maximum percentage of outliers in the data set. These outliers will appear
// at the edge of the chart.
const OUTLIERS_PCT = 0.04

// If the logarithmic span is just two (e.g. [1, 100] or [10, 1000]) the value
// spread coverage must be at least this percentage otherwise we use a linear
// scale instead.
const MIN_SPREAD_LINEAR_PCT = 0.7

/**
 * Configuration for a logarithmic axis.
 *
 * The scale min/max are determined automatically based on the data set.
 */
export interface AxisLogConfig
  extends Omit<
    AxisConfig,
    'scaleMin' | 'scaleMax' | 'scaleType' | 'generateLabels' | 'calculateAxisValue'
  > {
  logBase?: number // Defaults to 10
  assets: AssetListItem[]
}

/**
 * Configuration for a logarithmic axis. If the value spread is too low, a linear
 * scale is used instead.
 */
export function axisLogConfig({
  id,
  logBase = 10,
  formatLabel,
  getValue,
  assets,
  ...props
}: AxisLogConfig): AxisConfig {
  const values = assets.map(getValue).filter((v) => v !== null) as number[]
  const [scaleMin, scaleMax] = determineLogScaleMinMax(values, logBase)

  if (showLinearScale(values, logBase, scaleMin, scaleMax)) {
    return axisLinearConfig({
      id,
      getValue,
      assets,
      splitNumber: 8, // This is a suggestion, the actual can be higher or lower
      formatLabel,
      ...props,
    })
  }

  return {
    id,
    scaleType: 'log',
    calculateAxisValue: (asset: AssetListItem) => {
      const value = getValue(asset)
      if (value === null) {
        return null
      }
      return clamp(value, scaleMin, scaleMax)
    },
    generateLabels: (axis: Axis) =>
      generateLogLabelsOptions(axis, logBase, scaleMin, scaleMax, formatLabel),
    scaleMin,
    scaleMax,
    getValue,
    ...props,
  }
}

/**
 * Determines if a linear scale should be used instead of a logarithmic scale.
 *
 * - If the log range is just 1 we always use a linear scale (i.e. 10-100).
 * - If the log range is 2 (e.g. 10-100-1000), we use a linear scale if the value
 *   spread is too low.
 */
export function showLinearScale(
  values: number[],
  logBase: number,
  scaleMin: number,
  scaleMax: number
): boolean {
  // If the scale is [10, 1000], the log span is 2. If the scale is [10, 10000],
  // the span is 3.
  const logScaleMin = log(scaleMin, logBase)
  const logScaleMax = log(scaleMax, logBase)
  const span = Math.round(logScaleMax - logScaleMin)

  if (span === 2) {
    // If the span is 2, use a linear scale if the value spread is low. For the
    // spread we include the outliers as they will be plotted at the edges of the
    // chart.
    const [dataStart, dataEnd] = arrayMinMax(values)
    const logStart = clamp(log(dataStart, logBase), logScaleMin, logScaleMax)
    const logEnd = clamp(log(dataEnd, logBase), logScaleMin, logScaleMax)
    const covered = (logEnd - logStart) / (logScaleMax - logScaleMin)
    return covered < MIN_SPREAD_LINEAR_PCT
  }

  // If the span is 1, always use a linear scale
  return span === 1
}

/**
 * Determines the minimum and maximum values for a logarithmic scale. The
 * outliersPct determines how many values can be at the edge of the chart.
 */
export function determineLogScaleMinMax(
  values: number[],
  logBase: number,
  outlierPct = OUTLIERS_PCT
): [min: number, max: number] {
  let min = NaN
  let max: number

  if (values.length === 0) {
    return [1, Math.pow(logBase, 5)] // Just a default value that looks natural
  }

  // Remove outliers from the data set
  const filteredValues = removeOutliers(values, outlierPct)
  const [dataMin, dataMax] = arrayMinMax(filteredValues)

  // If log base is 10 this yields 1, 10, 100, 1000, ...
  for (let i = 1; ; i *= logBase) {
    // This axis value has sufficient data. If no minimum is set yet, mark
    // the previous value as the first value.
    if (Number.isNaN(min)) {
      // Skip axis values that are empty
      if (i < dataMin) {
        continue
      }

      // We found the first bucket boundary that has data. Set the previous
      // value as the minimum.
      min = i === 1 ? i : i / logBase
    }

    // If we've covered everything, set the current value as the maximum
    if (i > dataMax) {
      max = i
      return [min, max]
    }
  }
}

/**
 * Generates EChart label configuration for a logarithmic axis.
 */
function generateLogLabelsOptions(
  axis: Axis,
  logBase: number,
  scaleMin: number,
  scaleMax: number,
  formatLabel?: AxisConfig['formatLabel']
): CartesianAxisOption {
  const defaultFormatLabel = (value: number) => value.toString()

  return {
    splitLine: { show: true },
    axisTick: { show: false },
    axisLine: { show: false },
    type: 'log',
    logBase: logBase,
    min: scaleMin,
    max: scaleMax,
    axisLabel: {
      show: true,
      verticalAlignMaxLabel: axis === Axis.Y ? 'top' : undefined,
      verticalAlignMinLabel: axis === Axis.Y ? 'bottom' : undefined,
      alignMaxLabel: axis === Axis.X ? 'right' : undefined,
      alignMinLabel: axis === Axis.X ? 'left' : undefined,
      formatter: formatLabel ?? defaultFormatLabel,
    },
    position: axis === Axis.X ? 'bottom' : 'left',
  }
}

/**
 * Returns the logarithm of a value with a given base.
 */
function log(value: number, base: number) {
  return Math.log(value) / Math.log(base)
}

/**
 * Removes outliers from an array.
 */
function removeOutliers(arr: number[], outlierPercentage: number): number[] {
  const sortedArr = [...arr].sort((a, b) => a - b)
  const elementsToRemove = Math.floor(sortedArr.length * outlierPercentage)

  return sortedArr.slice(elementsToRemove, sortedArr.length - elementsToRemove)
}

/**
 * Returns the minimum and maximum value of an array.
 */
function arrayMinMax(values: number[]): [min: number, max: number] {
  return [Math.min(...values), Math.max(...values)]
}
