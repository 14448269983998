import { useIndustry } from '@gain/api/app/hooks'
import { listFilter } from '@gain/rpc/utils'
import { useIsXs } from '@gain/utils/responsive'
import React from 'react'

import { ListViewAsset } from '../../features/list-view'
import { useTrackPageView } from '../../features/planhat/use-track-activity'
import ListPageLayout from '../../layout/list-page-layout'
import { useIndustryPageParams } from '../utils'

export default function IndustryPortfolio() {
  const { id, preview } = useIndustryPageParams()
  const isXs = useIsXs()
  const { data: industry } = useIndustry({
    id,
    preview,
  })
  useTrackPageView('industry', { id, tab: 'assets', requireTab: true })

  if (!industry) {
    return null
  }

  const title = `${industry.generalInfo?.title || ''} - Companies`

  return (
    <ListPageLayout title={title}>
      <ListViewAsset
        defaultFilter={[listFilter('id', '=', industry.assets.map((asset) => asset.assetId) || [])]}
        disableFilters={isXs}
        inline={isXs}
      />
    </ListPageLayout>
  )
}
