import { useIndustryMarketSegment } from '@gain/api/app/hooks'
import { listFilter } from '@gain/rpc/utils'
import { useIsXs } from '@gain/utils/responsive'
import React from 'react'

import { ListViewAsset } from '../../features/list-view'
import { useTrackPageView } from '../../features/planhat/use-track-activity'
import ListPageLayout from '../../layout/list-page-layout'
import { useRouteSegmentParams } from '../utils'

export default function SegmentCompanies() {
  const { id } = useRouteSegmentParams()
  const isXs = useIsXs()
  const swrIndustryMarketSegment = useIndustryMarketSegment({ id })
  useTrackPageView('segment', { id, list: true })

  if (!swrIndustryMarketSegment.data) {
    return null
  }

  const title = `${swrIndustryMarketSegment.data.name || ''} - Companies`

  return (
    <ListPageLayout title={title}>
      <ListViewAsset
        defaultFilter={[listFilter('id', '=', swrIndustryMarketSegment.data.linkedAssetIds)]}
        disableFilters={isXs}
        inline={isXs}
      />
    </ListPageLayout>
  )
}
