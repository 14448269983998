import { fixUrl } from './fix-url'
import { isTruthy } from './is-truthy'

/**
 * Validates the url is valid, if not makes it so
 */
export const validateUrl = (url: URL | string | null | undefined): string | undefined => {
  if (!isTruthy(url)) {
    return undefined
  }

  if (typeof url === 'string') {
    return fixUrl(url)
  }

  return url.toString()
}
