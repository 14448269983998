import * as Shared from '@gain/rpc/shared-model'
import { FinancialResult } from '@gain/utils/financials'
import { formatNumber, formatPercentage } from '@gain/utils/number'

export function formatAmount(value: number | null | undefined, estimate: boolean) {
  return formatNumber(value, { round: estimate ? 0 : 1 })
}

export function formatGrowth(value: number | null | undefined, estimate: boolean) {
  return formatPercentage(value, { round: estimate ? 0 : 1 })
}

export function formatPercentageOfRevenue(value: number | null | undefined, estimate: boolean) {
  if (value && (value < -100 || value > 100)) {
    return 'n.m.'
  }

  return formatPercentage(value, { round: estimate ? 0 : 1 })
}

/**
 * Returns true if the given index matches the first forecasted financial results
 * while disregarding periodicity (NTM or Annual)
 */
export function isFirstForecastedYear(financials: FinancialResult[], index: number): boolean {
  return financials.findIndex((result) => result.isForecast) === index
}

export interface RowConfig {
  label: string
  labelLetterSpacing?: number
  formatFn?: (value: number | null | undefined, estimate: boolean) => string
  valueFn?: (financial: FinancialResult) => number | null | undefined
  amountTypeFn?: (financial: FinancialResult) => Shared.FinancialResultAmountType | undefined
  thickBorders?: boolean
  bold?: boolean
}

export type RowGroupConfig = RowConfig[]
