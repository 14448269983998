import { filledInputClasses } from '@mui/material/FilledInput'
import { Components, Theme } from '@mui/material/styles'
import { svgIconClasses } from '@mui/material/SvgIcon'

export const MuiFilledInput = (theme: Theme): Components['MuiFilledInput'] => ({
  defaultProps: {
    disableUnderline: true,
  },
  styleOverrides: {
    root: {
      backgroundColor: '#EFEFF3',
      border: '1px solid #EFEFF3',
      borderRadius: 8,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      transition: theme.transitions.create(['box-shadow']),
      fontSize: theme.typography.body2.fontSize,
      fontWeight: theme.typography.body2.fontWeight,
      lineHeight: theme.typography.body2.lineHeight,
      paddingTop: 0,
      [`&.${filledInputClasses.focused}`]: {
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.06)',
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.divider}`,
      },
      [`&.${filledInputClasses.disabled}`]: {
        backgroundColor: theme.palette.divider,
        color: theme.palette.text.primary,
      },
      [`&.${filledInputClasses.sizeSmall}`]: {
        borderRadius: 4,
      },
      '&:hover': {
        backgroundColor: '#EFEFF3',
      },
    },
    input: {
      padding: theme.spacing(0.5, 2),
      height: 32,
      '&::placeholder': {
        color: theme.palette.text.secondary,
        opacity: 1 /* Firefox */,
      },
      [`&.${filledInputClasses.inputSizeSmall}:first-child`]: {
        padding: `${theme.spacing(0, 1)} !important`,
        ...theme.typography.body2,
      },
      [`&.${filledInputClasses.inputAdornedStart}`]: {
        marginLeft: theme.spacing(2),
      },
    },
    adornedStart: {
      paddingLeft: theme.spacing(2),
      [`& > .${svgIconClasses.root}`]: {
        width: 16,
        height: 16,
        color: theme.palette.text.secondary,
      },
    },
  },
})
