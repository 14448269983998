import Tooltip from '@gain/components/tooltip'
import { isNumber } from '@gain/utils/typescript'
import { styled } from '@mui/material/styles'
import { extent, scaleBand, scaleLinear } from 'd3'
import React, { MouseEvent, useCallback, useMemo } from 'react'
import { animated, useTransition } from 'react-spring'

import { BarChartProps } from '../bar-chart'
import HorizontalBarChartContainer from './horizontal-bar-chart-container'
import HorizontalBarChartGrid from './horizontal-bar-chart-grid'

const MARGIN = { top: 0, bottom: 100 }
const BAR_WIDTH = 8
const BAR_PADDING = 0.66
const BORDER_RADIUS = 2

const StyledBar = styled(animated.rect)(({ onClick }) => ({
  fillOpacity: 1,
  strokeWidth: 1,
  clipPath: 'inset(0 0 round 2px 2px 0 0)',

  ...(onClick && {
    cursor: 'pointer',
  }),
}))

const StyledBarValue = styled(animated.text)({
  alignmentBaseline: 'central',
  fontSize: 12,
  textAnchor: 'middle',
})

function truncateValue(value: string, index: number) {
  const maxLength = index === 0 ? 6 : 15
  return value.length > maxLength ? `${value.substring(0, maxLength).trim()}...` : value
}

const StyledBarLabel = styled(animated.text)(({ theme }) => ({
  alignmentBaseline: 'central',
  fontSize: 12,
  fill: theme.palette.text.secondary,
  ...theme.typography.body2,
}))

type HorizontalBarChartProps<D> = Omit<BarChartProps<D>, 'orientation'>

export default function HorizontalBarChart<D>({
  width: containerWidth,
  height: containerHeight = 400,
  data,
  getId,
  getLabel,
  getValue,
  getColor,
  getTooltip,
  valueFormatter = (d) => (d || 0).toString(10),
  onBarClick,
}: HorizontalBarChartProps<D>) {
  const totalBarsWidth = data.length * 33 + 60

  // bounds = area inside the graph axis = calculated by substracting the margins
  const chartWidth = Math.max(totalBarsWidth, containerWidth)
  const chartHeight = containerHeight - MARGIN.top - MARGIN.bottom

  const labels = useMemo(() => {
    return data.slice().map((d) => getLabel(d))
  }, [getLabel, data])

  const xScale = useMemo(() => {
    return scaleBand()
      .domain(labels)
      .range([0, chartWidth])
      .paddingInner(BAR_PADDING)
      .paddingOuter(1.22)
  }, [chartWidth, labels])

  const maxValue = useMemo(() => {
    const [, max] = extent(data.map((d) => getValue(d)).filter(isNumber))

    // Add 5% so there is room at the top for the label
    return max ? max * 1.08 : 1
  }, [data, getValue])

  // y axis
  const yScale = useMemo(() => {
    return scaleLinear().domain([0, maxValue]).range([chartHeight, 0]).nice()
  }, [chartHeight, maxValue])

  const bars = useMemo(() => {
    return data.map((item, index) => {
      const value = getValue(item)
      const posValue = value && value > 0 ? value : 0
      const label = getLabel(item)
      const y = yScale(posValue)
      return {
        id: getId(item),
        color: getColor(item) as string,
        height: chartHeight - (posValue > 0 ? y : chartHeight),
        width: xScale.bandwidth(),
        x: xScale(label) as number,
        y: y,
        value: valueFormatter(getValue(item), item),
        label: truncateValue(label, index),
        tooltip: getTooltip?.(item) ?? '',
        item,
      }
    })
  }, [
    data,
    getValue,
    getLabel,
    yScale,
    getId,
    getColor,
    chartHeight,
    xScale,
    valueFormatter,
    getTooltip,
  ])

  const transitions = useTransition(bars, {
    initial: (item) => ({
      clipPath: `inset(${chartHeight}px 0 0 0 round ${BORDER_RADIUS}px ${BORDER_RADIUS}px 0 0)`,
      transform: `translate(${item.x + xScale.bandwidth() / 2 - BAR_WIDTH / 2}px, 0px)`,
      valueTransform: `translate(${item.x + xScale.bandwidth() / 2}px, ${chartHeight}px)`,
      labelTransform: `translate(${item.x + xScale.bandwidth() / 2}px, ${chartHeight + 8}px)`,
      opacity: 0,
      fill: item.color,
      stroke: item.color,
    }),
    update: (item) => ({
      clipPath: `inset(${item.y}px 0 0 0 round ${BORDER_RADIUS}px ${BORDER_RADIUS}px 0 0)`,
      transform: `translate(${item.x + xScale.bandwidth() / 2 - BAR_WIDTH / 2}px, 0px)`,
      valueTransform: `translate(${item.x + xScale.bandwidth() / 2}px, ${
        chartHeight - 16 - item.height
      }px)`,
      labelTransform: `translate(${item.x + xScale.bandwidth() / 2}px, ${chartHeight + 8}px)`,
      fill: item.color,
      stroke: item.color,
      opacity: 1,
    }),
    enter: (item) => ({
      clipPath: `inset(${item.y}px 0 0 0 round ${BORDER_RADIUS}px ${BORDER_RADIUS}px 0 0)`,
      transform: `translate(${item.x + xScale.bandwidth() / 2 - BAR_WIDTH / 2}px, 0px)`,
      valueTransform: `translate(${item.x + xScale.bandwidth() / 2}px, ${
        chartHeight - 16 - item.height
      }px)`,
      labelTransform: `translate(${item.x + xScale.bandwidth() / 2}px, ${chartHeight + 8}px)`,
      opacity: 1,
      fill: item.color,
      stroke: item.color,
    }),
    leave: (item) => ({
      clipPath: `inset(${chartHeight}px 0 0 0 round ${BORDER_RADIUS}px ${BORDER_RADIUS}px 0 0)`,
      transform: `translate(${item.x + xScale.bandwidth() / 2 - BAR_WIDTH / 2}px, 0px)`,
      valueTransform: `translate(${item.x + xScale.bandwidth() / 2}px, ${chartHeight}px)`,
      labelTransform: `translate(${item.x}px, ${chartHeight + 8}px)`,
      opacity: 0,
      fill: item.color,
      stroke: item.color,
    }),
    expires: false,
    keys: (item) => item.id,
  })

  const ticks = useMemo(
    () => yScale.ticks().map((value) => ({ value, y: yScale(value) })),
    [yScale]
  )

  const handleOnBarClick = useCallback(
    (item) => {
      if (!onBarClick) {
        return undefined
      }

      return (event: MouseEvent) => {
        onBarClick(item, event)
      }
    },
    [onBarClick]
  )

  return (
    <HorizontalBarChartContainer
      chartWidth={chartWidth}
      containerHeight={containerHeight}
      containerWidth={containerWidth}
      marginTop={MARGIN.top}>
      <HorizontalBarChartGrid
        height={chartHeight}
        ticks={ticks}
        x1={0}
        x2={chartWidth}
      />
      {transitions(({ valueTransform, labelTransform, opacity, ...itemStyle }, item) => (
        <g key={item.id}>
          <Tooltip title={item.tooltip}>
            <StyledBar
              height={chartHeight}
              onClick={handleOnBarClick(item.item)}
              style={itemStyle}
              width={BAR_WIDTH}
              x={0}
              y={0}
            />
          </Tooltip>

          <animated.g
            style={{
              transform: valueTransform,
              opacity,
            }}>
            <StyledBarValue>{item.value}</StyledBarValue>
          </animated.g>

          <animated.g
            style={{
              transform: labelTransform,
              opacity,
            }}>
            <StyledBarLabel
              style={{
                textAnchor: 'end',
                transform: 'rotate(-45deg)',
              }}
              x={0}
              y={0}>
              {item.label}
            </StyledBarLabel>
          </animated.g>
        </g>
      ))}
    </HorizontalBarChartContainer>
  )
}
