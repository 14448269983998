import { AssetListItem } from '@gain/rpc/app-model'
import { ListSort } from '@gain/rpc/list-model'
import { Option } from '@gain/rpc/shared-model'
import { useMemo, useState } from 'react'

import Card, { CardHeader } from '../../common/card/card'
import SelectMenu from '../../common/select-menu/select-menu'
import { useTrackEvent } from '../google-analytics'
import AssetValuationTable, { ValuationPeriod } from './asset-valuation-table'

interface ValuationCardProps {
  title: string
  assets: AssetListItem[]
  similarToAssetName?: string
  initialSort: ListSort<AssetListItem>[]
}

export default function ValuationCard({
  title,
  assets,
  similarToAssetName,
  initialSort,
}: ValuationCardProps) {
  const trackEvent = useTrackEvent()

  const [valuationPeriod, setValuationPeriod] = useState<ValuationPeriod>('LastFiscalYear')
  const valuationPeriods = useMemo(() => {
    const date = new Date()
    const year = date.getFullYear()

    return new Array<Option<ValuationPeriod>>(
      { label: `Last year (${year - 1})`, value: 'LastFiscalYear' },
      { label: 'LTM', value: 'LastTwelveMonths' },
      { label: `Current year (${year}E)`, value: 'CurrentFiscalYear' },
      { label: 'NTM', value: 'NextTwelveMonths' },
      { label: `Next year (${year + 1}E)`, value: 'NextFiscalYear' }
    )
  }, [])

  return (
    <Card>
      <CardHeader
        actions={
          <SelectMenu
            label={'Valuation period'}
            onChange={(value) => {
              setValuationPeriod(value)
              trackEvent('Set valuation period', 'Valuation table', value)
            }}
            options={valuationPeriods}
            sx={{ mr: -0.5 }}
            value={valuationPeriod}
          />
        }
        title={title}
      />
      <AssetValuationTable
        assets={assets}
        initialSort={initialSort}
        period={valuationPeriod}
        similarToAssetName={similarToAssetName}
      />
    </Card>
  )
}
