import { AssetShareholderShare } from './lib/rpc-shared-model'
import { Option } from './shared-model-interfaces'

export const ASSET_SHAREHOLDER_SHARE_OPTIONS: ReadonlyArray<Option<AssetShareholderShare>> = [
  {
    value: AssetShareholderShare.Minority,
    label: 'Minority',
  },
  {
    value: AssetShareholderShare.Majority,
    label: 'Majority',
  },
]
