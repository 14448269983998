import { Components, Theme } from '@mui/material/styles'
import { darken } from '@mui/material/styles'
import { tabClasses } from '@mui/material/Tab'

export const TAB_MARGIN_HORIZONTAL = 10

export const MuiTab = (theme: Theme): Components['MuiTab'] => ({
  defaultProps: {
    iconPosition: 'start',
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      textTransform: 'none',
      zIndex: 1,
      minHeight: 40,
      fontSize: theme.typography.button.fontSize,
      fontWeight: theme.typography.button.fontWeight,
      lineHeight: theme.typography.button.lineHeight,
      transition: 'color 250ms',
      '&:hover': {
        color: darken(theme.palette.text.secondary, 0.3),
      },
      [`&.${tabClasses.selected}`]: {
        color: theme.palette.primary.main,
      },
      padding: 0,
      [theme.breakpoints.up('xs')]: {
        minWidth: 0,
      },
      marginLeft: TAB_MARGIN_HORIZONTAL,
      marginRight: TAB_MARGIN_HORIZONTAL,
      [`& .${tabClasses.iconWrapper}`]: {
        fontSize: 16,
      },
    },
    wrapped: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
    textColorInherit: {
      opacity: 1,
    },
    fullWidth: {
      minWidth: 'auto',
    },
    textColorPrimary: {
      color: theme.palette.text.secondary,
    },
  },
})
