import type { AxisConfigId } from '../../chart/company-bubble-echart/axis/axis-config'

export interface ChartLibraryOption {
  title: string
  x: AxisConfigId
  y: AxisConfigId
}

export const chartLibraryOptions: ChartLibraryOption[] = [
  { title: 'Growth momentum', y: 'fteGrowthPctSixMonths', x: 'fteCagrPctTwoYears' },
  { title: 'Holding period', y: 'fteCagrPctTwoYears', x: 'holdingPeriod' },
  { title: 'Growth vs. profitability', y: 'revenueGrowthPctOneYear', x: 'ebitdaPctRevenue' },
  { title: 'Size vs. growth', y: 'revenueEur', x: 'fteGrowthPctOneYear' },
  { title: 'EBITDA health', y: 'ebitdaPctRevenue', x: 'ebitdaGrowthPctOneYear' },
  { title: 'Growth and efficiency', y: 'fteGrowthPctOneYear', x: 'revenueFteRatioEur' },
  { title: 'Margin conservation', y: 'grossMarginPctRevenue', x: 'ebitPctRevenue' },
  { title: '(In)organic growth', y: 'revenueCagrPctThreeYears', x: 'addOnDealCountL3Y' },
  { title: 'Revenue vs. FTE growth', y: 'revenueGrowthPctOneYear', x: 'fteGrowthPctOneYear' },
  { title: 'Strategic maturity', y: 'ratingMultinational', x: 'ratingBuyAndBuild' },
  { title: 'CEO experience', y: 'ceoTenure', x: 'ceoAge' },
]

export function findChartLibraryOptionById(
  x: AxisConfigId,
  y: AxisConfigId
): ChartLibraryOption | null {
  return chartLibraryOptions.find((option) => option.x === x && option.y === y) || null
}
