import Head from '@gain/modules/head'
import { Investor } from '@gain/rpc/app-model'
import Hidden from '@mui/material/Hidden'
import React, { useMemo } from 'react'

import Panel, { PanelHeader, PanelTitle } from '../../../common/panel'
import { TableEmpty } from '../../../common/table'
import { TableContainer } from '../../../common/table/table-container'
import { InvestorFundList } from '../../../features/investor-fund/investor-fund-list/investor-fund-list'
import { InvestorFundTable } from '../../../features/investor-fund/investor-fund-table/investor-fund-table'
import { INVESTOR_STRATEGY_LIST_ITEM_NO_STRATEGY_LABEL } from '../../../features/investor-strategy/investor-strategy-contants'
import { useTrackPageView } from '../../../features/planhat/use-track-activity'
import { useInvestorStrategyPageParams } from '../route-strategy-path'

export interface RouteFundsProps {
  investor: Investor
}

export default function RouteFunds({ investor }: RouteFundsProps) {
  const { strategyId, strategyName } = useInvestorStrategyPageParams()

  useTrackPageView('investor_strategy', { id: strategyId, tab: 'funds', requireTab: true })

  const funds = useMemo(
    () => investor.funds.filter((fund) => fund.investorStrategyId === strategyId) || [],
    [strategyId, investor.funds]
  )

  return (
    <>
      <Head>
        <title>
          {investor?.name || ''} - {strategyName || INVESTOR_STRATEGY_LIST_ITEM_NO_STRATEGY_LABEL} -
          Funds
        </title>
      </Head>

      <Hidden mdUp>
        <Panel>
          <PanelHeader align={'start'}>
            <PanelTitle>Funds</PanelTitle>
          </PanelHeader>
          <InvestorFundList funds={funds} />
        </Panel>
      </Hidden>
      <Hidden mdDown>
        <TableContainer>
          {funds.length === 0 ? (
            <TableEmpty
              message={'Please adjust your filter settings'}
              title={'No filter results'}
            />
          ) : (
            <InvestorFundTable rows={funds} />
          )}
        </TableContainer>
      </Hidden>
    </>
  )
}
