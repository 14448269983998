import { Asset, AssetListItem } from '@gain/rpc/app-model'
import { AssetOwnershipType } from '@gain/rpc/shared-model'
import { listFilter, listFilters, listSort } from '@gain/rpc/utils'
import Grid from '@mui/material/Grid'
import { useMemo } from 'react'

import Loading from '../../../common/loading'
import SimilarAssetDealsCard from '../../../features/valuation/similar-asset-deals-card'
import ValuationCard from '../../../features/valuation/valuation-card'
import { useValuationAssets } from '../../../features/valuation/valuation-hooks'

interface PrivateAssetValuationProps {
  asset: Asset
}

export function PrivateAssetValuation({ asset }: PrivateAssetValuationProps) {
  const filters = useMemo(() => {
    // Select listed companies similar to the given asset ID that have at least
    // one of the following ratios: EV/EBITDA, EV/EBIT, EV/Sales
    return listFilters<AssetListItem>(
      listFilter('relevanceRank', '=', asset.id),
      listFilter('ownership', '=', AssetOwnershipType.Listed),
      listFilter(
        '',
        'or',
        listFilters(
          listFilter('enterpriseValueEbitdaRatio', '>', 0),
          listFilter('enterpriseValueEbitRatio', '>', 0),
          listFilter('enterpriseValueRevenueRatio', '>', 0)
        )
      )
    )
  }, [asset.id])

  const [initialSort, swrAssets] = useValuationAssets(filters, 10, [
    listSort('relevanceRank', 'asc'),
  ])

  if (swrAssets.loading) {
    return <Loading />
  }

  return (
    <Grid
      spacing={2}
      container>
      <Grid
        xs={12}
        item>
        <ValuationCard
          assets={swrAssets.data.items}
          initialSort={initialSort}
          similarToAssetName={asset.generalInfo?.name}
          title={'Similar public companies'}
        />
      </Grid>
      <Grid
        xs={12}
        item>
        <SimilarAssetDealsCard asset={asset} />
      </Grid>
    </Grid>
  )
}
