import { Asset } from '@gain/rpc/app-model'
import React, { useMemo } from 'react'

import Card, { CardHeader } from '../../../../common/card/card'
import TableValuationEstimates from './table-valuation-estimates'

export interface CardAssetValuationEstimatesProps {
  asset: Asset
}

export default function CardAssetValuationEstimates({ asset }: CardAssetValuationEstimatesProps) {
  const predictedExitYear = useMemo(() => {
    if (asset.nextDeal && !asset.nextDeal.viewOnValuation) {
      return asset.nextDeal.year
    }

    return null
  }, [asset])

  return (
    <Card sx={{ paddingBottom: 0, overflow: 'hidden' }}>
      <CardHeader
        explainer={
          'These valuation estimates are a ballpark indication based on a continuously backtested algorithm. Click on the estimates to assess how your own reasoning aligns with these automated predictions.'
        }
        title={'Valuation estimates'}
      />

      <TableValuationEstimates
        dataCurrency={asset.generalInfo?.currency}
        financialPredictions={asset.financialPredictions}
        predictedExitYear={predictedExitYear}
        rating={asset.rating}
      />
    </Card>
  )
}
