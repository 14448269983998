import { useRpcClient } from '@gain/api/swr'
import { RpcMethodMap, SuggestCityResponse } from '@gain/rpc/app-model'
import { AutocompleteChangeReason } from '@mui/base/useAutocomplete/useAutocomplete'
import Autocomplete, { AutocompleteInputChangeReason } from '@mui/material/Autocomplete'
import { formLabelClasses } from '@mui/material/FormLabel'
import { inputBaseClasses } from '@mui/material/InputBase'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Control, FieldPath, useController } from 'react-hook-form'
import { FieldValues } from 'react-hook-form/dist/types/fields'
import { v4 as uuidv4 } from 'uuid'

import { GeoPointValue } from './filter-geo-point-util'

type AutocompleteOption = GeoPointValue | SuggestCityResponse

const StyledTextField = styled(TextField)(({ theme }) => ({
  [`& > .${inputBaseClasses.root}`]: { boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.06)' },
  [`& .${formLabelClasses.root}`]: {
    ...theme.typography.body2,
  },
}))

function isSuggestCityResponse(value: AutocompleteOption): value is SuggestCityResponse {
  return !!value && 'description' in value
}

function isOptionEqualToValue(option: AutocompleteOption, value: AutocompleteOption) {
  return value.googlePlaceId === option.googlePlaceId
}

interface FilterGeoPointAutocompleteValues extends FieldValues {
  point: GeoPointValue | null
}

interface FilterGeoPointAutocompleteProps<Values extends FilterGeoPointAutocompleteValues> {
  control: Control<Values>
  onBlur?: () => void
}

// FilterGeoPointAutocomplete helps the user find the lat and lon of a city
// It uses Google's geocoder api behind the scenes to find city suggestions
// and once an option is selected it fetches the lat and lon for the selected
// option using Google's place ID.
export default function FilterGeoPointAutocomplete<Values extends FilterGeoPointAutocompleteValues>(
  props: FilterGeoPointAutocompleteProps<Values>
) {
  const point = useController<Values>({
    control: props.control,
    name: 'point' as FieldPath<Values>,
  })
  const [open, setOpen] = useState(false)
  const selectedOption = point.field.value as GeoPointValue | null
  const [options, setOptions] = useState<Array<AutocompleteOption>>(
    point.field.value ? [point.field.value] : []
  )
  const fetcher = useRpcClient<RpcMethodMap>()
  const sessionTokenRef = useRef(uuidv4())

  // Returns an address for the given googlePlaceId in the locale of the given countryCode
  const getLngLatByPlaceId = useCallback(
    (googlePlaceId: string) => {
      return fetcher({
        method: 'data.getLngLatByPlaceId',
        params: {
          googlePlaceId: googlePlaceId,
        },
      })
    },
    [fetcher]
  )

  const handleInputValueChanged = useCallback(
    async (event, newInputValue, reason: AutocompleteInputChangeReason) => {
      if (newInputValue === '' || reason === 'reset') {
        // When the input is empty the only visible option is the currently selected option
        setOptions(selectedOption ? [selectedOption] : [])
      } else {
        // When there is an input value we merge the selected option with the suggested options
        const nextOptions = await fetcher({
          method: 'data.suggestCity',
          params: {
            input: newInputValue,
            sessionToken: sessionTokenRef.current,
          },
        })

        let newOptions = new Array<AutocompleteOption>()

        if (selectedOption) {
          newOptions = [selectedOption]
        }

        if (nextOptions) {
          newOptions = [...newOptions, ...nextOptions]
        }

        setOptions(newOptions)
        setOpen(newOptions.length > 0)
      }
    },
    [fetcher, selectedOption, setOpen]
  )

  const handleAutocompleteOptionSelected = useCallback(
    async (_, newValue: AutocompleteOption | null, reason: AutocompleteChangeReason) => {
      if (reason === 'clear') {
        setOptions([])
        // form onChange does not accept null, so we do as never
        point.field.onChange(null as never)
        return
      }

      if (newValue === null) {
        // When the new value equals null, we set the address id to null
        // form onChange does not accept null, so we do as never
        point.field.onChange(null as never)
        sessionTokenRef.current = uuidv4()
      } else if (isSuggestCityResponse(newValue)) {
        // When the new value is a suggested address we fetch the address details,
        // update or insert it, and store the id in the linked address id path
        try {
          const result = await getLngLatByPlaceId(newValue.googlePlaceId)
          const geoPointValue: GeoPointValue = {
            location: newValue.description,
            googlePlaceId: newValue.googlePlaceId,
            lon: result.lng,
            lat: result.lat,
          }
          // the selected option must be available within the options array
          // since we cannot control when the selectedOption(point.field) and
          // options are updated, there is a slight moment of inconsistency
          // causing a warning in the console.
          setOptions([geoPointValue])
          point.field.onChange(geoPointValue as never)
          sessionTokenRef.current = uuidv4()
        } catch (e) {
          setOptions([])
        }
      }

      setOpen(false)
    },
    [getLngLatByPlaceId, point.field, setOpen]
  )

  useEffect(() => {
    if (options.length === 0) {
      setOpen(false)
    }
  }, [options, setOpen])

  return (
    <Autocomplete
      filterOptions={(x) => x}
      forcePopupIcon={false}
      getOptionLabel={(option) => {
        return isSuggestCityResponse(option) ? option.description : option.location
      }}
      isOptionEqualToValue={isOptionEqualToValue}
      noOptionsText={'No locations'}
      onChange={handleAutocompleteOptionSelected}
      onInputChange={handleInputValueChanged}
      open={open}
      options={options}
      renderInput={(params) => (
        <StyledTextField
          {...params}
          label={''}
          placeholder={'Enter city'}
          fullWidth
        />
      )}
      renderOption={(optionProps, option) => (
        <li {...optionProps}>
          {isSuggestCityResponse(option) ? option.description : option.location}
        </li>
      )}
      size={'small'}
      value={
        selectedOption as
          | AutocompleteOption
          | undefined /* when disableClearable is true, value must be undefined instead of null according to the types, this triggers an uncontrolled to controlled error, so we "fix" it like this. */
      }
      autoComplete
      autoHighlight
      disableClearable
      filterSelectedOptions
      fullWidth
      includeInputInList
    />
  )
}
