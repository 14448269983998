import { useListedSecurityChartData } from '@gain/api/app/hooks'
import { CurrencyListItem, ListedSecurityListItem } from '@gain/rpc/app-model'
import { useElementWidthEffect } from '@gain/utils/dom'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import { styled, useTheme } from '@mui/material/styles'
import { addBusinessDays } from 'date-fns/addBusinessDays'
import { formatISO } from 'date-fns/formatISO'
import { isWeekend } from 'date-fns/isWeekend'
import { Payload } from 'echarts'
import * as echarts from 'echarts/core'
import React, { useCallback, useMemo, useRef, useState } from 'react'

import Card, { CardContent, CardHeader, CardHeaderProps } from '../../../../common/card/card'
import { ChartLegendChip } from '../../../../common/chart/chart-legend'
import EChart from '../../../../common/echart/echart'
import Loading from '../../../../common/loading'
import CurrencyToggle from '../../../../features/currency/currency-toggle'
import BrokerRecommendationTooltip from './broker-recommendation-tooltip'
import { useConvertSharePrices, usePrepareChartData } from './market-data-chart-utils'
import SharePriceTooltip from './share-price-tooltip'
import TimeRangePicker, { getDateForTimeRange, TimeRangeKey } from './time-range-picker'
import useMarketDataChartOption from './use-market-data-chart-option'
import useMarketDataInteraction from './use-market-data-interaction'

// Define and calculate fixed heights for the charts
export const SHARE_PRICE_CHART_HEIGHT = 356
export const PRICE_CHART_VERTICAL_PADDING = 24
export const RECOMMENDATION_CHART_HEIGHT = 141
const TOTAL_CHART_HEIGHT = SHARE_PRICE_CHART_HEIGHT + RECOMMENDATION_CHART_HEIGHT
const FOOTER_HEIGHT = 49
const TOTAL_CARD_HEIGHT = TOTAL_CHART_HEIGHT + FOOTER_HEIGHT

const StyledCardLoader = styled('div')({
  height: TOTAL_CARD_HEIGHT,
})

const StyledSharePriceChartPlaceholder = styled('div')({
  height: SHARE_PRICE_CHART_HEIGHT,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const StyledCardHeader = styled(CardHeader)<CardHeaderProps>(({ theme }) => ({
  padding: theme.spacing(2, 3, 1, 3),
  marginBottom: theme.spacing(2),

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1.5, 2, 0, 2),
  },
}))

const StyledLegendContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: theme.spacing(12),
  padding: theme.spacing(1.5, 2),

  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(4),
  },
}))

interface MarketDataCardProps {
  listedSecurity: ListedSecurityListItem
  currency: CurrencyListItem | null
  listedSecurityCurrency: string
  onCurrencyChange: (currency: CurrencyListItem) => void
}

/**
 * MarketDataChart renders the public market data chart. This chart combines the
 * share price series and a bar chart that shows the broker recommendations over
 * time. The share price always shows a data point per business day. The broker
 * recommendations are always aggregated on a different level, e.g. a (bi-)weekly,
 * (bi-)monthly or quarterly data point.
 */
export default function MarketDataChart({
  listedSecurity,
  currency,
  listedSecurityCurrency,
  onCurrencyChange,
}: MarketDataCardProps) {
  const theme = useTheme()
  const eChartsInstanceRef = useRef<echarts.ECharts | undefined>()
  const [timeRange, setTimeRange] = useState<TimeRangeKey>('5Y')

  // Determine which date we should start, make sure it's a business day
  const startDate = useMemo(() => {
    const date = getDateForTimeRange(timeRange)
    if (!isWeekend(date)) {
      return date
    }
    return addBusinessDays(date, 1)
  }, [timeRange])

  // Get the market data from the backend, prepare it for the chart and convert
  // currency when necessary
  const selectedCurrency = currency?.name ?? 'EUR'
  const swrMarketData = useListedSecurityChartData({
    listedSecurityId: listedSecurity.id,
    startDate: formatISO(startDate),
  })
  const preparedData = usePrepareChartData(swrMarketData.data)
  const data = useConvertSharePrices(preparedData, listedSecurityCurrency, selectedCurrency)

  // Generate the ECharts option, which tells ECharts what and how to render
  const hasBrokerRecommendations = data.brokerRecommendations.length > 0
  const option = useMarketDataChartOption(data, timeRange, hasBrokerRecommendations)

  // Helper function for dispatching actions on EChart's API
  const dispatchEChartsAction = useCallback((payload: Payload) => {
    eChartsInstanceRef.current?.dispatchAction(payload)
  }, [])

  // Keep the ECharts reference up-to-date each time it (re-)initializes
  const handleInit = useCallback((eChartsInstance: echarts.ECharts) => {
    eChartsInstanceRef.current = eChartsInstance
  }, [])

  // Handle interaction with the chart, like mouse move and data point highlighting
  const [
    highlightedSharePrice,
    highlightedBrokerRecommendation,
    handleMouseMoveZr,
    handleMouseOutZr,
  ] = useMarketDataInteraction(data)

  // Resize ECharts whenever the card size changes
  const cardRef = useRef<HTMLDivElement>(null)
  const resizeECharts = useCallback(() => eChartsInstanceRef.current?.resize(), [])
  useElementWidthEffect(cardRef, resizeECharts)

  return (
    <Card
      ref={cardRef}
      sx={{ pb: 0 }}>
      <StyledCardHeader
        actions={
          <CurrencyToggle
            dataCurrency={listedSecurityCurrency}
            onChange={onCurrencyChange}
            value={currency}
            symbolOnly
          />
        }
        title={'Market data'}
      />

      {!swrMarketData.data || swrMarketData.loading || !currency ? (
        <StyledCardLoader>
          {/* Show loader centered with respect to share price graph, which is
           more visually pleasing on fast internet connections */}
          <StyledSharePriceChartPlaceholder>
            <Loading />
          </StyledSharePriceChartPlaceholder>
        </StyledCardLoader>
      ) : (
        <>
          <CardContent>
            {/* Wrap the market data EChart with the share price tooltip. This way we
             leverage MUIs `followCursor` and don't have to manually position it */}
            <SharePriceTooltip
              currency={selectedCurrency}
              sharePrice={highlightedSharePrice ?? undefined}>
              <EChart
                // Explicitly re-render on timeRange change and force a new animation
                // instead of morphing into the new state. The default morph animation
                // is not very nice in our case.
                key={`${timeRange}-${selectedCurrency}`}
                onInit={handleInit}
                onMouseMoveZr={handleMouseMoveZr}
                onMouseOutZr={handleMouseOutZr}
                option={option}
                style={{ height: TOTAL_CHART_HEIGHT }}
              />
            </SharePriceTooltip>

            {!!highlightedBrokerRecommendation && (
              <BrokerRecommendationTooltip
                recommendation={highlightedBrokerRecommendation ?? undefined}
                open
              />
            )}
          </CardContent>

          <Divider />

          {/* Chart Legend */}
          <StyledLegendContainer>
            <TimeRangePicker
              onChange={setTimeRange}
              value={timeRange}
            />

            {hasBrokerRecommendations && (
              <Stack direction={'row'}>
                <ChartLegendChip
                  color={theme.palette.success.main}
                  label={'Buy'}
                  onMouseEnter={() =>
                    dispatchEChartsAction({ type: 'highlight', seriesId: ['buy', 'border-radius'] })
                  }
                  onMouseLeave={() => dispatchEChartsAction({ type: 'downplay', seriesId: 'buy' })}
                />
                <ChartLegendChip
                  color={theme.palette.warning.main}
                  label={'Hold'}
                  onMouseEnter={() =>
                    dispatchEChartsAction({
                      type: 'highlight',
                      seriesId: ['hold', 'border-radius'],
                    })
                  }
                  onMouseLeave={() => dispatchEChartsAction({ type: 'downplay', seriesId: 'hold' })}
                />
                <ChartLegendChip
                  color={theme.palette.error.main}
                  label={'Sell'}
                  onMouseEnter={() =>
                    dispatchEChartsAction({
                      type: 'highlight',
                      seriesId: ['sell', 'border-radius'],
                    })
                  }
                  onMouseLeave={() => dispatchEChartsAction({ type: 'downplay', seriesId: 'sell' })}
                />
              </Stack>
            )}
          </StyledLegendContainer>
        </>
      )}
    </Card>
  )
}
