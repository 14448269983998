import { ChevronRightIcon } from '@gain/components/icons'
import { IndustryMarketSegmentListItem } from '@gain/rpc/app-model'
import { useElementWidth } from '@gain/utils/dom'
import Avatar, { avatarClasses } from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import clsx from 'clsx'
import React, { useCallback, useRef, useState } from 'react'

import Card, { CardContent, CardHeader, CardTabs } from '../../../common/card/card'
import { generateIndustryPagePath } from '../../../routes/utils'
import IndustryEsgAssessmentList from './industry-esg-assessment-list'
import IndustryEsgAssessmentTable from './industry-esg-assessment-table'

const cardEsgAssessmentClasses = generateUtilityClasses('CardEsgAssessment', [
  'disableIndustryReportLink',
])

const StyledCard = styled(Card)(({ theme }) => ({
  [`&.${cardEsgAssessmentClasses.disableIndustryReportLink}`]: {
    paddingBottom: theme.spacing(3),
  },
}))

const StyledReadReportContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.grey[50],
  borderRadius: 8,
  padding: theme.spacing(0.5, 1),
  display: 'flex',
  flexDirection: 'column',

  [`& .${avatarClasses.root}`]: {
    height: 24,
    width: 24,
    marginRight: theme.spacing(),
    border: 'none',

    '& img': {
      objectFit: 'cover',
      padding: 0,
    },
  },

  [theme.breakpoints.up('md')]: {
    alignItems: 'center',
    flexDirection: 'row',
  },

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(1.5, 2, 1),

    '& a': {
      marginLeft: theme.spacing(-1),
    },

    [`& .${avatarClasses.root}`]: {
      display: 'none',
    },
  },
}))

export interface CardEsgAssessmentProps {
  title?: string
  segments: IndustryMarketSegmentListItem[]
  disableSubtitle?: boolean
  disableIndustryReportLink?: boolean
  className?: string
}

export default function CardEsgAssessment({
  title,
  disableSubtitle,
  disableIndustryReportLink,
  segments,
  className,
}: CardEsgAssessmentProps) {
  const [activeSegmentIndex, setActiveSegmentIndex] = useState(0)
  const ref = useRef<HTMLDivElement>(null)
  const width = useElementWidth(ref)

  const handleSegmentChange = useCallback((event, value) => {
    setActiveSegmentIndex(value)
  }, [])

  const segment = React.useMemo(() => segments[activeSegmentIndex], [segments, activeSegmentIndex])

  return (
    <StyledCard
      ref={ref}
      className={clsx(className, {
        [cardEsgAssessmentClasses.disableIndustryReportLink]: disableIndustryReportLink,
      })}>
      <CardHeader
        title={
          title
            ? title
            : `Industry ESG assessment${segments.length === 1 ? `: ${segments[0].name}` : ''}`
        }
      />

      {segments.length > 1 && (
        <CardTabs
          onChange={handleSegmentChange}
          options={segments.map(({ industryTitle }, index) => ({
            label: industryTitle || '',
            value: index,
          }))}
          value={activeSegmentIndex}
        />
      )}

      <CardContent>
        {!disableSubtitle && (
          <Typography
            mb={1}
            mt={2}
            variant={'subtitle2'}>
            Segment: {segment.name}
          </Typography>
        )}

        <Stack
          direction={'column'}
          mt={segments.length === 1 ? 1 : 0}
          spacing={3}>
          <Stack
            direction={'column'}
            spacing={1}>
            {width >= 888 && <IndustryEsgAssessmentTable segment={segment} />}
            {width < 888 && <IndustryEsgAssessmentList segment={segment} />}
          </Stack>
        </Stack>

        {!disableIndustryReportLink && (
          <Stack py={2}>
            <StyledReadReportContainer>
              {segment.industrySmallImageFileUrl && (
                <Avatar
                  src={segment.industrySmallImageFileUrl}
                  variant={'rounded'}
                />
              )}
              <Typography variant={'body2'}>
                {segment.name} is a segment of the industry: {segment.industryTitle}.
              </Typography>
              <div>
                <Button
                  color={'primary'}
                  endIcon={
                    <ChevronRightIcon
                      color={'inherit'}
                      fontSize={'inherit'}
                    />
                  }
                  href={generateIndustryPagePath({
                    title: segment.industryTitle,
                    id: segment.industryId,
                  })}>
                  Read industry report
                </Button>
              </div>
            </StyledReadReportContainer>
          </Stack>
        )}
      </CardContent>
    </StyledCard>
  )
}
