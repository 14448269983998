import { styled } from '@mui/material/styles'

import VirtualTableCell, { VirtualTableCellProps } from './virtual-table-cell'

export interface VirtualTableBodyCellProps extends VirtualTableCellProps {
  shadow: boolean
}

export const VirtualTableBodyCell = styled(VirtualTableCell)<VirtualTableBodyCellProps>(
  ({ position, size }) => ({
    display: 'inline-flex',

    ...(position === 'sticky' && {
      zIndex: 2,
    }),

    ...(size === 'small' && {
      borderBottomWidth: 0,
    }),
  })
)
