import { CheckIcon } from '@gain/components/icons'
import Typography from '@gain/components/typography'
import { alpha, styled } from '@mui/material/styles'
import ToggleButton, { toggleButtonClasses } from '@mui/material/ToggleButton'
import React from 'react'

import { DropdownMenuOptions } from '../../../common/dropdown-menu'
import { Axis, AxisConfig } from '../../chart/company-bubble-echart/axis/axis-config'
import { ChartLibraryOption } from './chart-library-config'
import { ChartToggleButtonAxis } from './chart-toggle-button-axis'

const StyledCheckIconContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0.5),
  backgroundColor: 'transparent',
  borderTopLeftRadius: 4,
  borderBottomRightRadius: 4,
  transition: 'background-color 300ms ease',

  svg: {
    height: 16,
    width: 16,
    color: theme.palette.background.paper,
    strokeWidth: 2,
  },
}))

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  padding: theme.spacing(1),
  gap: theme.spacing(0.5),
  border: `1px solid ${theme.palette.grey[200]}`,
  boxShadow: 'none',
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  transition: 'border-color 300ms ease',

  '&:hover': {
    boxShadow: theme.palette.shadow.level1c,
    transform: 'scale(1.01)',
    backgroundColor: 'transparent',
    borderColor: theme.palette.grey[200],

    '> div:first-of-type': {
      backgroundColor: theme.palette.grey[200],
    },
  },

  [`& .${toggleButtonClasses.root}`]: {
    color: theme.palette.grey[200],
  },

  [`&.${toggleButtonClasses.selected}`]: {
    borderColor: alpha(theme.palette.primary.main, 0.16),

    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },

    '> div:first-of-type': {
      backgroundColor: alpha(theme.palette.primary.main, 0.16),
      svg: {
        color: theme.palette.primary.main,
      },
    },
  },
}))

const StyledChartButtonTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(0.25),
}))

interface ChartToggleButtonProps {
  axisConfig: DropdownMenuOptions<AxisConfig>
  option: ChartLibraryOption
  onChange: (option: ChartLibraryOption) => void
  selected: boolean
}

export default function ChartToggleButton({
  axisConfig,
  option,
  onChange,
  selected,
}: ChartToggleButtonProps) {
  return (
    <StyledToggleButton
      onChange={() => onChange(option)}
      selected={selected}
      value={option}
      fullWidth>
      <StyledCheckIconContainer>
        <CheckIcon />
      </StyledCheckIconContainer>

      <StyledChartButtonTitle
        color={'text.primary'}
        variant={'h6'}>
        {option.title}
      </StyledChartButtonTitle>

      <ChartToggleButtonAxis
        axis={Axis.Y}
        axisConfig={axisConfig}
        configId={option.y}
      />

      <ChartToggleButtonAxis
        axis={Axis.X}
        axisConfig={axisConfig}
        configId={option.x}
      />
    </StyledToggleButton>
  )
}
