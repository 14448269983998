import React from 'react'

import { ListViewDeal } from '../../features/list-view'
import { useTrackPageView } from '../../features/planhat/use-track-activity'
import ListPageLayout from '../../layout/list-page-layout'

export default function DealListPage() {
  useTrackPageView('deal', { list: true })

  return (
    <ListPageLayout title={'Deals'}>
      <ListViewDeal />
    </ListPageLayout>
  )
}
