import { truncate } from '@gain/utils/string'
import { isNumber } from '@gain/utils/typescript'
import { styled } from '@mui/material/styles'
import { deviation, median, scaleBand, scaleLinear } from 'd3'
import React, { useMemo } from 'react'
import { animated, useTransition } from 'react-spring'

import { BarChartProps } from '../bar-chart'
import VerticalBarChartContainer from './vertical-bar-chart-container'

const MARGIN = { top: 0, right: 48, bottom: 0, left: 128 }
const BAR_SIZE = 8
const BAR_PADDING = 0.66
const BORDER_RADIUS = 4

const StyledBar = styled(animated.rect)({
  fillOpacity: 1,
  strokeWidth: 1,
  clipPath: 'inset(0 0 round 2px 2px 0 0)',
})

const StyledBarValue = styled(animated.text)(({ theme }) => ({
  alignmentBaseline: 'central',
  textAnchor: 'start',
  ...theme.typography.overline,
}))

const StyledGridLine = styled('line')(({ theme }) => ({
  strokeWidth: 1,
  stroke: theme.palette.divider,
}))

const BREAK_HEIGHT = 12.3
const BREAK_WIDTH = 5

const StyledBreak = styled(animated.rect)(({ theme }) => ({
  fill: theme.palette.background.paper,
  height: BREAK_HEIGHT,
  width: BREAK_WIDTH,
  strokeWidth: 1,
  stroke: theme.palette.text.primary,
  transformOrigin: 'right',
  transformBox: 'fill-box',
  strokeDasharray: `0,${BREAK_WIDTH},${BREAK_HEIGHT},${BREAK_WIDTH},${BREAK_HEIGHT}`,
}))

const StyledBarLabel = styled(animated.text)(({ theme }) => ({
  alignmentBaseline: 'central',
  textAnchor: 'end',
  transformOrigin: 'right',
  transformBox: 'fill-box',
  fill: theme.palette.text.secondary,
  ...theme.typography.overline,
}))

type VerticalBarChartProps<D> = Omit<BarChartProps<D>, 'orientation'>

export default function VerticalBarChart<D>({
  width: containerWidth,
  height: fixedContainerHeight,
  data,
  getId,
  getLabel,
  getValue,
  getColor,
  valueFormatter = (d) => (d || 0).toString(10),
}: VerticalBarChartProps<D>) {
  const totalBarSize = data.length * 33 + 60
  const containerHeight = fixedContainerHeight || totalBarSize

  // bounds = area inside the graph axis = calculated by substracting the margins
  const chartHeight = Math.max(totalBarSize, containerHeight)
  const chartWidth = containerWidth - MARGIN.left - MARGIN.right

  const labels = useMemo(() => {
    return data.slice().map((d) => getLabel(d))
  }, [getLabel, data])

  const yScale = useMemo(() => {
    return scaleBand()
      .domain(labels)
      .range([0, chartHeight])
      .paddingInner(BAR_PADDING)
      .paddingOuter(BAR_PADDING)
  }, [chartHeight, labels])

  const xScale = useMemo(() => {
    const xValues = data.map((d) => getValue(d)).filter(isNumber)
    const xMaxValue = Math.max(...xValues)
    const xMedian = median(xValues) || 1
    const xDeviation = deviation(xValues) || 1
    const xOutlierCap = xMedian + xDeviation * 0.1
    const xMax = Math.min(xMaxValue, xOutlierCap)

    return scaleLinear().domain([0, xMax]).range([0, chartWidth])
  }, [chartWidth, data, getValue])

  const bars = useMemo(() => {
    return data.map((item) => {
      const value = getValue(item)
      const maxValue = xScale.domain()[1]
      const posValue = value && value > 0 ? Math.min(value, maxValue) : 0
      const label = getLabel(item)
      const x = xScale(posValue)
      return {
        id: getId(item),
        color: getColor(item) as string,
        width: chartWidth - (posValue > 0 ? x : 0),
        height: yScale.bandwidth(),
        x: x,
        y: yScale(label) as number,
        value: valueFormatter(getValue(item), item),
        label: truncate(label, 18),
        isOutlier: value && value > maxValue,
      }
    })
  }, [chartWidth, getColor, getId, getLabel, getValue, valueFormatter, data, yScale, xScale])

  const transitions = useTransition(bars, {
    initial: (item) => ({
      clipPath: `inset(0 ${chartWidth}px 0 0 round 0 ${BORDER_RADIUS}px ${BORDER_RADIUS}px 0 )`,
      transform: `translate(0px, ${item.y + yScale.bandwidth() / 2 - BAR_SIZE / 2}px)`,
      valueTransform: `translate(0px, ${item.y + yScale.bandwidth() / 2}px)`,
      labelTransform: `translate(0px, ${item.y + yScale.bandwidth() / 2}px)`,
      opacity: 0,
      fill: item.color,
      stroke: item.color,
      breakOpacity: 0,
      breakTransform: 'translateX(0px) skewX(-10deg)',
    }),
    update: (item) => ({
      clipPath: `inset(0 ${item.width}px 0 0 round 0 ${BORDER_RADIUS}px ${BORDER_RADIUS}px 0 )`,
      transform: `translate(0px, ${item.y + yScale.bandwidth() / 2 - BAR_SIZE / 2}px)`,
      valueTransform: `translate(${item.x}px, ${item.y + yScale.bandwidth() / 2}px)`,
      labelTransform: `translate(0px, ${item.y + yScale.bandwidth() / 2}px)`,
      fill: item.color,
      stroke: item.color,
      opacity: 1,
      breakOpacity: item.isOutlier ? 1 : 0,
      breakTransform: `translateX(${item.x * 0.85}px) skewX(-10deg)`,
    }),
    enter: (item) => ({
      clipPath: `inset(0 ${item.width}px 0 0 round 0 ${BORDER_RADIUS}px ${BORDER_RADIUS}px 0 )`,
      transform: `translate(0px, ${item.y + yScale.bandwidth() / 2 - BAR_SIZE / 2}px)`,
      valueTransform: `translate(${item.x}px, ${item.y + yScale.bandwidth() / 2}px)`,
      labelTransform: `translate(0px, ${item.y + yScale.bandwidth() / 2}px)`,
      fill: item.color,
      stroke: item.color,
      opacity: 1,
      breakOpacity: item.isOutlier ? 1 : 0,
      breakTransform: `translateX(${item.x * 0.85}px) skewX(-10deg)`,
    }),
    leave: (item) => ({
      clipPath: `inset(0 ${chartWidth}px 0 0 round 0 ${BORDER_RADIUS}px ${BORDER_RADIUS}px 0 )`,
      transform: `translate(0px, ${item.y + yScale.bandwidth() / 2 - BAR_SIZE / 2}px)`,
      valueTransform: `translate(0px, ${item.y + yScale.bandwidth() / 2}px)`,
      labelTransform: `translate(0px, ${item.y + yScale.bandwidth() / 2}px)`,
      opacity: 0,
      fill: item.color,
      stroke: item.color,
      breakOpacity: 0,
      breakTransform: 'translateX(0px) skewX(-10deg)',
    }),
    expires: false,
    keys: (item) => item.id,
  })

  return (
    <VerticalBarChartContainer
      chartHeight={chartHeight}
      containerHeight={containerHeight}
      containerWidth={containerWidth}
      disableScroll={containerHeight === totalBarSize}
      marginLeft={MARGIN.left}>
      <StyledGridLine
        x1={0}
        x2={0}
        y1={0}
        y2={chartHeight}
      />
      {transitions(
        (
          { valueTransform, labelTransform, opacity, breakOpacity, breakTransform, ...itemStyle },
          item
        ) => (
          <g key={item.id}>
            <StyledBar
              height={BAR_SIZE}
              style={itemStyle}
              width={chartWidth}
              x={0}
              y={0}
            />

            <StyledBreak
              style={{ opacity: breakOpacity, transform: breakTransform }}
              x={0}
              y={item.y}
            />

            <StyledBarValue
              style={{
                transform: valueTransform,
                opacity,
              }}
              x={4}
              y={0}>
              {item.value}
            </StyledBarValue>

            <StyledBarLabel
              style={{
                transform: labelTransform,
                opacity,
              }}
              x={0 - 8}
              y={0}>
              {item.label}
            </StyledBarLabel>
          </g>
        )
      )}
    </VerticalBarChartContainer>
  )
}
