import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import { swrLogMiddleware, useRpcClient } from '@gain/api/swr'
import Maintenance from '@gain/components/maintenance'
import { SlotProvider } from '@gain/components/slot'
import { SnackbarProvider } from '@gain/components/snackbar'
import { AuthContext, useAuthContextValue } from '@gain/modules/auth'
import { DefaultHeadProps, HeadProvider } from '@gain/modules/head'
import CssBaseline from '@mui/material/CssBaseline'
import { StyledEngineProvider, ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { useEffect } from 'react'
import { Route, useHistory } from 'react-router-dom'
import { SWRConfig } from 'swr'
import { QueryParamProvider } from 'use-query-params'

import { rpcClient } from './api/rpc-client'
import DialogProvider from './common/dialog'
import AppVersionProvider from './features/app-version'
import useLogout from './features/auth/use-logout'
import ColumnPickerProvider from './features/column-picker/column-picker-provider'
import { usePageTracking } from './features/google-analytics'
import MenuProvider from './features/menu/menu-provider'
import SearchProvider from './features/search/search-provider'
import { ZendeskProvider } from './features/zendesk'
import { MainRoutes } from './routes/main-routes'
import { theme } from './theme'

// Create emotion cache to be able to set compat to true
//  - https://mui.com/material-ui/guides/interoperability/#css-injection-order
const cache = createCache({
  key: 'css',
  prepend: true,
})

// Disable Emotion SSR warnings
//  - https://github.com/emotion-js/emotion/issues/1105#issuecomment-1058225197
cache.compat = true

const defaultHeadProps: DefaultHeadProps = {
  titleTemplate: '%s - Gain.pro',
  defaultTitle: 'Gain.pro',
  defer: false,
}

/**
 * When updating, make sure to also update the browser extension (When adding new providers)
 */
export function App() {
  const history = useHistory()
  const rpcClientFetcher = useRpcClient(rpcClient)
  const authContextValue = useAuthContextValue()
  const logout = useLogout(authContextValue, rpcClientFetcher)
  usePageTracking()

  useEffect(() => {
    // Scroll to top on navigation
    history.listen((location, action) => {
      if (action === 'PUSH' || action === 'REPLACE') {
        window.scrollTo(0, 0)
      }
    })

    // Mark initial point in history, so we can check if the user can go back
    // in history or not
    history.replace(history.location, {
      isFirstLocationInHistory: true,
    })
  }, [history])

  // Configure RPC client to perform client side logout on 401
  rpcClient.onUnauthorized(logout)

  return (
    <SWRConfig
      value={{
        fetcher: rpcClientFetcher,
        revalidateOnMount: true,
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        use: [swrLogMiddleware],
      }}>
      {/* When updating, make sure to also update the browser extension (When adding new providers) */}
      <AuthContext.Provider value={authContextValue}>
        <HeadProvider defaultHeadProps={defaultHeadProps}>
          <QueryParamProvider ReactRouterRoute={Route}>
            <ZendeskProvider>
              <StyledEngineProvider injectFirst>
                <CacheProvider value={cache}>
                  <MuiThemeProvider theme={theme}>
                    <CssBaseline />
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <SnackbarProvider>
                        <Maintenance client={rpcClient}>
                          <DialogProvider>
                            <AppVersionProvider>
                              <MenuProvider>
                                <SlotProvider>
                                  <SearchProvider>
                                    <ColumnPickerProvider>
                                      <MainRoutes />
                                    </ColumnPickerProvider>
                                  </SearchProvider>
                                </SlotProvider>
                              </MenuProvider>
                            </AppVersionProvider>
                          </DialogProvider>
                        </Maintenance>
                      </SnackbarProvider>
                    </LocalizationProvider>
                  </MuiThemeProvider>
                </CacheProvider>
              </StyledEngineProvider>
            </ZendeskProvider>
          </QueryParamProvider>
        </HeadProvider>
      </AuthContext.Provider>
    </SWRConfig>
  )
}
