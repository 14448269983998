import Head from '@gain/modules/head'
import { Asset, CurrencyListItem } from '@gain/rpc/app-model'
import { useConvertFinancialResults, useDefinedRatios } from '@gain/utils/financials'
import Grid from '@mui/material/Grid'
import React, { useCallback, useState } from 'react'

import CardNotes from '../../../common/card/card-notes'
import RatiosCard from '../../../features/financial/ratios-card/ratios-card'
import SourcesList from '../../../features/source/source-list'
import { useAssetSources } from '../route-asset-hooks'
import AssetFinancialsCard from './asset-financials-card'
import AssetLegalEntitiesCard from './asset-legal-entities-card'

interface AssetFinancialsProps {
  asset: Asset
}

export default function AssetFinancials({ asset }: AssetFinancialsProps) {
  const sources = useAssetSources(asset.sources, 'financials')

  const usedRatios = useDefinedRatios(asset.financialResults)
  const hasRatios = usedRatios.hasReturnRatios || usedRatios.hasOtherRatios
  const [currency, setCurrency] = useState<CurrencyListItem | null>(null)
  const financials = useConvertFinancialResults(
    asset.financialResults,
    asset.generalInfo?.currency,
    currency?.name ?? 'EUR'
  )

  const handleCurrencyChange = useCallback((newCurrency: CurrencyListItem) => {
    setCurrency(newCurrency)
  }, [])

  return (
    <>
      <Head>
        <title>{asset.generalInfo?.name} - Financials</title>
      </Head>

      <Grid
        spacing={2}
        container>
        {!!asset.financialResults.length && (
          <Grid
            xs={12}
            item>
            <AssetFinancialsCard
              asset={asset}
              currency={currency}
              financialResults={financials}
              onCurrencyChange={handleCurrencyChange}
            />
          </Grid>
        )}
        {hasRatios && (
          <Grid
            xs={12}
            item>
            <RatiosCard
              currency={currency}
              dataCurrency={asset.generalInfo?.currency}
              financialResults={financials}
              onCurrencyChange={handleCurrencyChange}
            />
          </Grid>
        )}
        {!!asset.financialFootnotes.length && (
          <Grid
            xs={12}
            item>
            <CardNotes notes={asset.financialFootnotes} />
          </Grid>
        )}
        {!!asset.legalEntities.length && (
          <Grid
            xs={12}
            item>
            <AssetLegalEntitiesCard
              legalEntities={asset.legalEntities}
              processedAnnualReportFileIds={asset.annualReports.map((ar) => ar.annualReportFileId)}
            />
          </Grid>
        )}
        {sources.length > 0 && (
          <Grid
            xs={12}
            item>
            <SourcesList sources={sources} />
          </Grid>
        )}
      </Grid>
    </>
  )
}
