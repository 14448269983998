import Tooltip from '@gain/components/tooltip'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { ElementType, isValidElement, ReactNode } from 'react'

export interface ListItemIconValueProps {
  icon: ElementType
  tooltip?: ReactNode
  children: ReactNode
  textComponent?: 'div' | 'p'
}

export default function ListItemIconValue({
  icon: IconComponent,
  tooltip,
  children,
  textComponent = 'p',
}: ListItemIconValueProps) {
  return (
    <ListItem
      disableGutters
      disablePadding>
      <ListItemIcon>
        <IconComponent />
      </ListItemIcon>
      <ListItemText primaryTypographyProps={{ component: textComponent }}>
        <Tooltip
          title={tooltip}
          disableInteractive>
          {isValidElement(children) ? children : <span>{children}</span>}
        </Tooltip>
      </ListItemText>
    </ListItem>
  )
}
