import { CmdClickEvent, isCmdClickEvent } from '@gain/utils/event'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

/**
 * openLink ensures sure that ctrl + click or cmd + click opens in a new tab
 */
export function useOpenLink() {
  const history = useHistory()

  return useCallback(
    (path: string, event: CmdClickEvent) => {
      if (isCmdClickEvent(event)) {
        window.open(path, '_blank')
      } else {
        history.push(path)
      }
    },
    [history]
  )
}
