import { CurrencyListItem } from '@gain/rpc/app-model'
import { CurrencyDisplayType } from '@gain/rpc/shared-model'
import { formatNumber } from '@gain/utils/number'
import { round as lodashRound } from 'lodash'

export function isAtLeastThousand(value: number | null | undefined): boolean {
  if (typeof value !== 'number') {
    return false
  }

  return value >= 1_000 || value <= -1_000
}

export interface FormatCurrencyOptions {
  round?: number | 'auto' | 'estimate' | 'significant'
  currency?: CurrencyListItem | null
  emptyValue?: string
  disablePrefix?: boolean
  disableSuffix?: boolean

  // Currency format:
  // - 'millions': the value is in millions and will always be displayed as millions.
  // - 'billions': the value is in millions and will always be displayed as billions.
  // - 'millions-or-billions': the value is in millions and if the value is at least
  //    1000 it will be displayed as billions.
  // - 'thousands':the value is a raw value and will always be displayed as thousands.
  // - 'thousands-or-millions': the value is a raw value and will be displayed as
  //    thousands or as millions if the value is at least 1,000,000.
  format?:
    | 'billions'
    | 'millions'
    | 'millions-or-billions'
    | 'thousands'
    | 'thousands-or-millions'
    | 'raw'
}

export function formatCurrency(
  num: number | null | undefined,
  {
    round = 'auto',
    currency = null,
    disablePrefix = false,
    disableSuffix = false,
    emptyValue = '-',
    format = 'millions-or-billions',
  }: FormatCurrencyOptions = {}
) {
  if (num === null || num === undefined) {
    return emptyValue
  }

  // If the value is an estimate we apply some rough rounding to avoid
  // people from thinking the value is more precise than it is.
  let value = num
  if (round === 'estimate') {
    value = roundEstimate(Math.abs(value))
  }

  // If format is 'millions-or-billions' choose billions if the value is
  // at least 1 billion.
  if (format === 'millions-or-billions') {
    format = isAtLeastThousand(num) ? 'billions' : 'millions'
  }

  // Change billions to millions if the number is > 1_000 and add
  // the appropriate suffix (m or bn).
  let suffix: string
  switch (format) {
    case 'raw':
      // Don't divide / no suffix
      suffix = ''
      break
    case 'billions':
      value /= 1_000
      suffix = 'bn'
      break
    case 'millions':
      suffix = 'm'
      break
    case 'thousands':
      value /= 1_000
      suffix = 'k'
      break
    case 'thousands-or-millions':
      value /= 1_000 // show 1_000 as 1k
      if (isAtLeastThousand(value)) {
        value /= 1_000
        suffix = 'm'
      } else {
        suffix = 'k'
      }
      break
  }

  // Remove suffix if it's disabled
  if (disableSuffix) {
    suffix = ''
  }

  // Determine if the number is negative and append the negative sign
  const negative = num < 0 ? '-' : ''

  // Append the prefix and currency symbol depending on the currency
  // display type.
  let currencyAndPrefix = `${negative}`
  if (currency && !disablePrefix) {
    switch (currency.display) {
      case CurrencyDisplayType.Symbol:
        currencyAndPrefix = `${negative}${currency.symbol}`
        break
      case CurrencyDisplayType.Code:
        currencyAndPrefix = `${currency.name} ${negative}`
        break
      default:
        throw new Error(`Unknown currency display type: ${currency.display}`)
    }
  }

  // Determine the number of decimals to round to
  const decimals = determineRoundDecimals(round, value, format === 'billions')

  // Round the value
  const roundedValue = formatNumber(Math.abs(value), { round: decimals })

  return `${currencyAndPrefix}${roundedValue}${suffix}`
}

/**
 * Returns by how many decimals the value should be rounded.
 *
 * - auto: returns 1 if num is between -10 and 10, otherwise it returns 0.
 * - estimate: returns 0 for millions. It returns 1 if the value is in billions
 *   and less than 10 or greater than -10.
 * - significant: returns the number of digits needed to see the 3 digits.
 * - number: if the round-value is a number it simply returns
 *   the same number.
 */
function determineRoundDecimals(
  round: FormatCurrencyOptions['round'],
  num: number | null | undefined,
  showAsBillion: boolean
): number {
  switch (round) {
    case 'estimate': {
      if (!num || !showAsBillion) {
        return 0 // Always show 0 decimals when the value is in millions
      }
      return num > 10 || num < -10 ? 0 : 1
    }
    case 'auto':
      if (num === 0) {
        return 0
      }

      return num && (num > 10 || num < -10) ? 0 : 1
    case 'significant':
      return roundThreeSignificantDigits(num)
    default:
      return round ?? 0
  }
}

/**
 * Determines the number of decimal places to round a given number
 * to maintain three significant digits, while avoiding redundant
 * zeros in decimals.
 *
 * If the integer part of the number has three or more digits, the
 * function returns 0, indicating no rounding of decimals is needed.
 * Otherwise, it calculates the necessary decimal places to ensure
 * the number has three significant digits, ignoring leading zeros
 * in the fractional part for numbers less than 1 and avoiding trailing
 * zeros.
 *
 * Some examples:
 *
 *  2 => 0
 *  2.00 => 0
 *  2.10 => 1
 *  2.11 => 2
 *  1234.56 => 0
 *  123.456 => 0
 *  23.456 => 1
 *  0.3456 => 3
 *  0.3401 => 2
 *  0.03456 => 4
 */
function roundThreeSignificantDigits(value: number | null | undefined): number {
  if (!value) {
    return 0
  }

  const absValue = Math.abs(value)
  const nrOfIntegerDigits = Math.floor(absValue).toString().length

  let result = 0
  if (absValue >= 1) {
    // If value is greater than or equal to 1, return the number of decimals left over
    result = Math.min(3 - nrOfIntegerDigits, countDecimals(absValue))
  } else {
    // For values less than 1, count the number of leading zeros after the decimal point.
    const [, decimalPart] = value.toString().split('.')
    const leadingZeros = decimalPart.match(/^0*/)
    if (!leadingZeros) {
      throw new Error('Expected leading zeros to be defined') // This can never happen
    }

    // Number of leading zeros + 3 significant digits, we strip trailing zeros later
    result = leadingZeros[0].length + 3
  }

  if (result > 0) {
    // If result is 2 and the value is 1.203 we want to display 1.2 and not 1.20.
    // Use lodash round to avoid floating point issues.
    const rounded = lodashRound(value, result)
    return countDecimals(rounded)
  }

  return 0
}

/**
 * Returns the number of decimals in a number.
 */
function countDecimals(value: number) {
  const text = value.toString()
  const index = text.indexOf('.')
  return index === -1 ? 0 : text.length - index - 1
}

/**
 * When values represent estimates, we want to avoid people from
 * thinking the value is more precise than it is.
 *
 * This function rounds the value to the nearest 1, 5 or 10 depending
 * on the value thresholds of <25, <100 or >= 100.
 *
 * Some examples:
 *
 *  0.4 => 0
 *  0.5 => 1
 *  19 => 19
 *  27.5 => 30
 *  32.4 => 30
 *  32.5 => 35
 *  97.5 => 100
 *  114.9 => 110
 *  115 => 120
 */
function roundEstimate(value: number) {
  let n = 10
  if (value < 25) {
    n = 1
  } else if (value < 100) {
    n = 5
  }
  return Math.round(value / n) * n
}
