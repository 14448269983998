import { ArrowUpRightIcon, CompanyIcon } from '@gain/components/icons'
import { GlobalUltimateOwner } from '@gain/rpc/app-model'
import { fixUrl } from '@gain/utils/common'
import React from 'react'

import ContentLinkLegalEntity from '../../../../features/legal-entity/legal-entity-content-link'
import UltimateOwnershipLink from './ultimate-ownership-link'

interface UltimateOwnerProps {
  owner: GlobalUltimateOwner
}

export default function UltimateOwner({ owner }: UltimateOwnerProps) {
  if (!owner) {
    return null
  }

  if (owner.linkedLegalEntityId && owner.linkedLegalEntityName) {
    return (
      <ContentLinkLegalEntity
        id={owner.linkedLegalEntityId}
        name={owner.linkedLegalEntityName}
        region={owner.linkedLegalEntityRegion}
      />
    )
  }

  return (
    <UltimateOwnershipLink
      avatarProps={{ children: <CompanyIcon /> }}
      endIcon={owner.webUrl && <ArrowUpRightIcon />}
      href={owner.webUrl && fixUrl(owner.webUrl)}
      label={owner.name}
      labelTypographyProps={{ disableTooltip: true }}
      region={owner.region}
    />
  )
}
