import { useAssetListItem } from '@gain/api/app/hooks'
import Tooltip, { TooltipProps } from '@gain/components/tooltip'
import { AssetListItem } from '@gain/rpc/app-model'
import { isDefined } from '@gain/utils/common'
import React, { PropsWithChildren, useCallback, useState } from 'react'

import AssetTooltipTitle from './asset-tooltip-title'

export type AssetTooltipProps = PropsWithChildren<
  Omit<TooltipProps, 'title' | 'id' | 'open'> & {
    id?: number
    asset?: AssetListItem | null
  }
>

export default function AssetTooltip({
  id,
  asset: defaultAsset,
  variant = 'preview',
  disablePadding = true,
  children,
  ...props
}: AssetTooltipProps) {
  const [fetchAsset, setFetchAsset] = useState<boolean>(false)
  const asset = useAssetListItem(fetchAsset && isDefined(id) ? id : null, {
    suspense: false,
  })
  const handleOpen = useCallback(() => setFetchAsset(true), [])

  return (
    <Tooltip
      key={id}
      disablePadding={disablePadding}
      onOpen={handleOpen}
      title={
        asset.data ? (
          <AssetTooltipTitle
            asset={asset.data}
            onClick={(event) => event.stopPropagation()}
          />
        ) : (
          ''
        )
      }
      variant={variant}
      disableInteractive
      {...props}>
      {children}
    </Tooltip>
  )
}
