import { useAdvisorDealsLastFiveYearsList } from '@gain/api/app/hooks'
import {
  ArrowUpRightIcon,
  GlobeIcon,
  LinkedInOutlinedIcon,
  MoneyBagIcon,
  ShuffleIcon,
  UsersIcon,
} from '@gain/components/icons'
import Tooltip from '@gain/components/tooltip'
import { Advisor, SearchItemType } from '@gain/rpc/app-model'
import { useDealAdvisorDistributionRows } from '@gain/utils/advisor'
import { createUrl, formatUrlHostname, isTruthy } from '@gain/utils/common'
import { useMedianDealsEbitda } from '@gain/utils/deal'
import { formatPercentage } from '@gain/utils/number'
import Link from '@mui/material/Link'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import React from 'react'

import Skeleton from '../../../../../common/skeleton'
import {
  ADVISOR_DEAL_SECTOR_CONFIG,
  DEAL_ADVISOR_REGION_CONFIG,
  useValueDistributionRows,
} from '../../../../../common/table-value-distribution'
import { useFlattenedDistributionRows } from '../../../../../common/table-value-distribution/table-value-distribution-utils'
import { ObjectListItemAvatar, ObjectListItemText } from '../../../../object-list-item'
import { useSearchAPI } from '../../../search-hooks'
import SearchTopResultLocation from './search-top-result-location'
import SearchTopResultSideInfo from './search-top-result-side-info'
import { StyledSubTitle } from './search-top-result-skeleton'
import SearchTopResultTitle from './search-top-result-title'

const StyledBarsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(2),
  gap: theme.spacing(),

  '& > div': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& p': {
      minWidth: 100,
      maxWidth: 100,
    },
  },
}))

const StyledBarContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  height: theme.spacing(2),
  flex: 1,
}))

const StyledBar = styled('div', {
  shouldForwardProp: (prop) => !['width', 'backgroundColor'].includes(prop as string),
})<{ width: string; backgroundColor: string }>(({ theme, width, backgroundColor }) => ({
  ...theme.typography.overline,
  color: theme.palette.common.white,
  height: theme.spacing(2),
  flex: 1,
  width,
  maxWidth: width,
  backgroundColor,

  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',

  '& span': {
    marginLeft: theme.spacing(0.5),
  },
}))

const StyledEllipsesSpan = styled('span')({
  display: 'flex',
  alignItems: 'center',
  minWidth: 0,

  '& span': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  '& svg': {
    fontSize: 16,
  },
})

export default function SearchTopResultAdvisor({ advisor }: { advisor?: Advisor }) {
  const {
    result: { topResult },
  } = useSearchAPI()

  const swrDeals = useAdvisorDealsLastFiveYearsList(advisor?.id)
  const medianDealsEbitda = useMedianDealsEbitda(swrDeals.data.items)

  const sectorKnown = swrDeals.data.items.filter(({ subsector }) => !!subsector)
  const regionRows = useValueDistributionRows(swrDeals.data.items, DEAL_ADVISOR_REGION_CONFIG)
  const sectorRows = useValueDistributionRows(sectorKnown, ADVISOR_DEAL_SECTOR_CONFIG)
  const activityRows = useDealAdvisorDistributionRows(swrDeals.data.items)

  const flattenedRegionRows = useFlattenedDistributionRows(regionRows)

  return (
    <Stack
      direction={'row'}
      gap={4}>
      <Stack
        flex={1}
        minWidth={0}>
        <Stack
          alignItems={'center'}
          direction={'row'}
          gap={2}>
          <ObjectListItemAvatar
            size={'big'}
            src={topResult?.imageUrl ?? undefined}
            type={SearchItemType.Advisor}
            variant={'rounded'}>
            <UsersIcon />
          </ObjectListItemAvatar>
          <ObjectListItemText variant={'top-result'}>
            <SearchTopResultTitle />
            <StyledSubTitle>Advisor</StyledSubTitle>
          </ObjectListItemText>
        </Stack>

        <StyledBarsContainer>
          {(!advisor || swrDeals.loading) &&
            Array(3)
              .fill(null)
              .map((_, index) => (
                <Stack
                  key={index}
                  gap={1}>
                  <Skeleton
                    height={22}
                    width={100}
                    enabled
                  />

                  <Skeleton
                    height={22}
                    width={'100%'}
                    enabled
                  />
                </Stack>
              ))}

          {!swrDeals.loading && activityRows.length > 0 && (
            <div>
              <Typography variant={'body2'}>Activity</Typography>

              <StyledBarContainer>
                {activityRows.map(({ label, color, percentage }) => (
                  <Tooltip
                    key={label}
                    title={`${label} (${formatPercentage(percentage)})`}
                    disableInteractive>
                    <StyledBar
                      backgroundColor={color}
                      width={`${percentage}%`}>
                      <span>{label}</span>
                    </StyledBar>
                  </Tooltip>
                ))}
              </StyledBarContainer>
            </div>
          )}

          {!swrDeals.loading && flattenedRegionRows.length > 0 && (
            <div>
              <Typography variant={'body2'}>Region focus</Typography>

              <StyledBarContainer>
                {flattenedRegionRows.map(({ label, color, percentage }) => (
                  <Tooltip
                    key={label}
                    title={`${label} (${formatPercentage(percentage)})`}
                    disableInteractive>
                    <StyledBar
                      backgroundColor={color}
                      width={`${percentage}%`}>
                      <span>{label}</span>
                    </StyledBar>
                  </Tooltip>
                ))}
              </StyledBarContainer>
            </div>
          )}

          {!swrDeals.loading && sectorRows.length > 0 && (
            <div>
              <Typography variant={'body2'}>Sector focus</Typography>

              <StyledBarContainer>
                {sectorRows.map(({ label, color, percentage }) => (
                  <Tooltip
                    key={label}
                    title={`${label} (${formatPercentage(percentage)})`}
                    disableInteractive>
                    <StyledBar
                      backgroundColor={color}
                      width={`${percentage}%`}>
                      <span>{label}</span>
                    </StyledBar>
                  </Tooltip>
                ))}
              </StyledBarContainer>
            </div>
          )}
        </StyledBarsContainer>
      </Stack>

      <SearchTopResultSideInfo>
        <List
          dense
          disablePadding>
          <SearchTopResultLocation />

          <ListItem
            disableGutters
            disablePadding>
            <ListItemIcon>
              <Skeleton
                enabled={!advisor || swrDeals.loading}
                width={20}>
                <ShuffleIcon />
              </Skeleton>
            </ListItemIcon>
            <Skeleton
              enabled={!advisor || swrDeals.loading}
              width={150}>
              <ListItemText>{swrDeals.data.items.length} advised deals (L5Y)</ListItemText>
            </Skeleton>
          </ListItem>

          {isTruthy(!advisor || swrDeals.loading || medianDealsEbitda) && (
            <ListItem
              disableGutters
              disablePadding>
              <ListItemIcon>
                <Skeleton
                  enabled={!advisor || swrDeals.loading}
                  width={20}>
                  <MoneyBagIcon />
                </Skeleton>
              </ListItemIcon>
              <ListItemText>
                <Skeleton
                  enabled={!advisor || swrDeals.loading}
                  width={150}>
                  <StyledEllipsesSpan>
                    <Tooltip title={'Median deal EBITDA'}>
                      <span>{medianDealsEbitda} median deal EBITDA</span>
                    </Tooltip>
                  </StyledEllipsesSpan>
                </Skeleton>
              </ListItemText>
            </ListItem>
          )}

          {(!advisor || advisor?.linkedInUrl) && (
            <ListItem
              disableGutters
              disablePadding>
              <ListItemIcon>
                <Skeleton
                  enabled={!advisor}
                  width={20}>
                  <LinkedInOutlinedIcon />
                </Skeleton>
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  component: Link,
                  target: '_blank',
                  rel: 'noopener noreferrer',
                  href: advisor?.linkedInUrl ?? '#',
                }}>
                <Skeleton
                  enabled={!advisor}
                  width={150}>
                  <Tooltip
                    title={'LinkedIn'}
                    disableInteractive>
                    <StyledEllipsesSpan>
                      LinkedIn
                      <ArrowUpRightIcon />
                    </StyledEllipsesSpan>
                  </Tooltip>
                </Skeleton>
              </ListItemText>
            </ListItem>
          )}

          {(!advisor || advisor?.webUrl) && (
            <ListItem
              disableGutters
              disablePadding>
              <ListItemIcon>
                <Skeleton
                  enabled={!advisor}
                  width={20}>
                  <GlobeIcon />
                </Skeleton>
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  component: Link,
                  target: '_blank',
                  rel: 'noopener noreferrer',
                  href: advisor?.webUrl ?? '#',
                }}>
                <Skeleton
                  enabled={!advisor}
                  width={150}>
                  <Tooltip
                    title={'Website'}
                    disableInteractive>
                    <StyledEllipsesSpan>
                      <span>{formatUrlHostname(createUrl(advisor?.webUrl ?? 'example.com'))}</span>
                      <ArrowUpRightIcon />
                    </StyledEllipsesSpan>
                  </Tooltip>
                </Skeleton>
              </ListItemText>
            </ListItem>
          )}
        </List>
      </SearchTopResultSideInfo>
    </Stack>
  )
}
