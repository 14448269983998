import { listItemClasses } from '@mui/material/ListItem'
import { listItemButtonClasses } from '@mui/material/ListItemButton'
import { listItemIconClasses } from '@mui/material/ListItemIcon'
import { listItemSecondaryActionClasses } from '@mui/material/ListItemSecondaryAction'
import { listItemTextClasses } from '@mui/material/ListItemText'
import { alpha, Components, Theme } from '@mui/material/styles'
import { svgIconClasses } from '@mui/material/SvgIcon'

export const MuiListItem = (theme: Theme): Components['MuiListItem'] => ({
  styleOverrides: {
    button: {
      borderRadius: 4,
      [`&.${listItemClasses.selected}`]: {
        color: theme.palette.primary.main,
        backgroundColor: alpha(theme.palette.primary.main, 0.16),
        [`& .${svgIconClasses.root}, & + .${listItemSecondaryActionClasses.root}`]: {
          color: theme.palette.primary.main,
        },
      },
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
      },
      [`&.${listItemClasses.selected}:hover`]: {
        backgroundColor: alpha(theme.palette.primary.main, 0.16),
      },
      transition: 'unset',
    },
    dense: {
      minHeight: 32,
      [`& .${listItemIconClasses.root}`]: {
        minWidth: 32,
        fontSize: 16,
      },
    },
    secondaryAction: {
      [`& .${listItemTextClasses.root}`]: {
        marginRight: 24,
      },
      [`& > .${listItemButtonClasses.root}`]: {
        paddingRight: 24,
      },
    },
  },
})
