import { ConferenceEditionListItem, IndustryListItem } from '@gain/rpc/app-model'
import { isTruthy } from '@gain/utils/common'
import { styled } from '@mui/material/styles'
import React, { MouseEvent, useCallback, useState } from 'react'

import Card, { CardHeader, CardProps, CardTabs } from '../../../../common/card/card'
import AssetFurtherContextConferencesTable from './asset-further-context-conferences-table'
import AssetFurtherContextIndustriesTable from './asset-further-context-industries-table'

export interface AssetFurtherContextCardProps extends CardProps {
  industries: IndustryListItem[]
  conferences: ConferenceEditionListItem[]
}

const StyledCard = styled(Card)({
  display: 'flex',
  flexDirection: 'column',
})

enum FurtherContextTabs {
  IndustryReports,
  Conferences,
}

export default function AssetFurtherContextCard({
  industries,
  conferences,
  ...rest
}: AssetFurtherContextCardProps) {
  const [activeTab, setActiveTab] = useState(
    industries.length > 0 ? FurtherContextTabs.IndustryReports : FurtherContextTabs.Conferences
  )

  const handleTabClick = useCallback((event: MouseEvent) => {
    // Make sure the event doesn't bubble up to the card
    event.preventDefault()
    event.stopPropagation()
  }, [])

  return (
    <StyledCard {...rest}>
      <CardHeader title={'Further context'} />

      <CardTabs
        onChange={(_, newValue) => setActiveTab(newValue)}
        options={[
          industries.length > 0 && {
            label: 'Industry reports',
            value: FurtherContextTabs.IndustryReports,
            onClick: handleTabClick,
          },
          conferences.length > 0 && {
            label: 'Conferences',
            value: FurtherContextTabs.Conferences,
            onClick: handleTabClick,
          },
        ].filter(isTruthy)}
        value={activeTab}
      />

      {activeTab === FurtherContextTabs.IndustryReports && (
        <AssetFurtherContextIndustriesTable industries={industries} />
      )}

      {activeTab === FurtherContextTabs.Conferences && (
        <AssetFurtherContextConferencesTable conferences={conferences} />
      )}
    </StyledCard>
  )
}
