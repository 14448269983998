import { Asset } from '@gain/rpc/app-model'
import { AssetProfileType } from '@gain/rpc/shared-model'
import { styled, Theme } from '@mui/material/styles'
import { SxProps } from '@mui/system'
import { useCallback, useMemo, useState } from 'react'

import { CardContent, CardTabs } from '../../../common/card/card'
import { TabOption } from '../../../common/card/card/card-tabs'
import FteMeasurements from '../../fte-measurements/fte-measurements'
import CardAssetFteGainProTab from './card-asset-fte-gain-pro-tab'

export interface CardAssetFteProps {
  asset: Asset
  sx?: SxProps<Theme>
  showDivider?: boolean
}

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  height: '100%',
  paddingTop: theme.spacing(),
  display: 'flex',
  flexDirection: 'column',
}))

enum AssetFteTab {
  GainPro = 'gain-pro',
  SocialMedia = 'social-media',
}

function useTabs(asset: Asset) {
  return useMemo(() => {
    const tabs = new Array<TabOption>()
    const fteResults = asset.financialResults.filter((result) => result.fte !== null)

    if (fteResults.length > 0 && asset.profileType !== AssetProfileType.Automated) {
      tabs.push({
        label: 'Gain.pro',
        value: AssetFteTab.GainPro,
      })
    }

    if (asset.fteMeasurements.length > 0) {
      tabs.push({
        label: 'Social media',
        value: AssetFteTab.SocialMedia,
      })
    }

    return tabs
  }, [asset])
}

function useInitialActiveTab(tabs: TabOption[]) {
  if (tabs.find((tab) => tab.value === AssetFteTab.GainPro)) {
    return AssetFteTab.GainPro
  }

  return AssetFteTab.SocialMedia
}

export function CardAssetFteTabs({ asset, sx, showDivider = false }: CardAssetFteProps) {
  const tabs = useTabs(asset)
  const initialTab = useInitialActiveTab(tabs)
  const [currentTab, setCurrentTab] = useState(initialTab)
  const handleTabChange = useCallback((_, value) => setCurrentTab(value), [])

  // Format the label to display something like: "1,001-5,000", "201-500" etc.
  const fteRangeLabel = asset.generalInfo?.fteRange?.replace('employees', '').trim()

  return (
    <>
      {tabs.length > 1 && (
        <CardTabs
          onChange={handleTabChange}
          options={tabs}
          value={currentTab}
        />
      )}
      <StyledCardContent sx={sx}>
        {currentTab === AssetFteTab.GainPro && <CardAssetFteGainProTab asset={asset} />}
        {currentTab === AssetFteTab.SocialMedia && (
          <FteMeasurements
            companyFteRangeLabel={fteRangeLabel}
            fteMeasurements={asset.fteMeasurements}
            showDivider={showDivider}
          />
        )}
      </StyledCardContent>
    </>
  )
}
