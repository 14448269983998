import Typography from '@gain/components/typography'
import { alpha, styled } from '@mui/material/styles'
import React from 'react'

import { Link, TLinkProps } from '../../../common/link'
import { generateIndustryPagePath } from '../../utils'

const StyledLink = styled(Link)({
  display: 'flex !important',
  flexDirection: 'column',
  height: 168,
  borderRadius: 8,
  overflow: 'hidden',
  boxShadow: '0px 2px 14px rgba(0, 0, 0, 0.08)',
  position: 'relative',
})

const StyledBackgroundImage = styled('div')({
  flex: 1,
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
})

const StyledHeader = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '10.5px 16px',
  overflow: 'hidden',
  position: 'relative',
})

const StyledTitle = styled(Typography)(({ theme }) => ({
  zIndex: 1,
  color: theme.palette.common.white,
}))

const StyledScope = styled(Typography)(({ theme }) => ({
  zIndex: 1,
  color: alpha(theme.palette.common.white, 0.7),
}))

const StyledHeaderBackgroundContainer = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  borderRadius: '0 0 8px 8px',
  overflow: 'hidden',
  zIndex: 0,
})

const StyledHeaderBackground = styled('div')(({ theme }) => ({
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
  transform: 'scaleX(-1) rotate(180deg)',
  height: 114,
  '&::after': {
    display: 'block',
    position: 'absolute',
    borderRadius: '8px 8px 0 0',
    overflow: 'hidden',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    content: '""',
    backdropFilter: 'blur(100px)',
    backgroundColor: alpha(theme.palette.common.black, 0.08),
  },
}))

export type IndustryListItemProps = Omit<TLinkProps, 'to' | 'title' | 'id'> & {
  id: number
  title: string | null
  scope: string | null
  imageSrc?: string | null
}

export function IndustryListItem({
  id,
  title,
  scope,
  imageSrc,
  ...linkProps
}: IndustryListItemProps) {
  return (
    <StyledLink
      style={{ backgroundImage: `url('${imageSrc}')` }}
      to={generateIndustryPagePath({
        id: id,
        title: title || undefined,
      })}
      disableStyle
      {...linkProps}>
      <StyledBackgroundImage style={{ backgroundImage: `url('${imageSrc}')` }} />
      <StyledHeader>
        <StyledTitle
          color={'inherit'}
          variant={'h6'}
          noWrap>
          {title}
        </StyledTitle>
        {scope && (
          <StyledScope
            color={'inherit'}
            variant={'overline'}
            noWrap>
            In {scope}
          </StyledScope>
        )}
        <StyledHeaderBackgroundContainer>
          <StyledHeaderBackground style={{ backgroundImage: `url('${imageSrc}')` }} />
        </StyledHeaderBackgroundContainer>
      </StyledHeader>
    </StyledLink>
  )
}
