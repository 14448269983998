import { useMemo } from 'react'

import { fixUrl } from './fix-url'
import { isDefined } from './is-defined'

export function toUrl(url: string | null | undefined) {
  if (!isDefined(url)) {
    return null
  }

  try {
    return new URL(fixUrl(url))
  } catch (e) {
    return null
  }
}

export function useUrl(url: string | null | undefined) {
  return useMemo(() => toUrl(url), [url])
}
