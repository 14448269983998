import Tooltip from '@gain/components/tooltip'
import { useResizeObserver } from '@gain/utils/dom'
import Chip, { ChipProps } from '@mui/material/Chip'
import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import { styled } from '@mui/material/styles'
import { SxProps } from '@mui/system'
import React, {
  MouseEvent,
  PropsWithChildren,
  startTransition,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react'

export const overflowContainerClasses = generateUtilityClasses('OverflowContainer', [
  'child',
  'hidden',
  'visible',
])

const StyledRoot = styled('div', {
  shouldForwardProp: (prop) =>
    !['maxLines', 'lineHeight', 'spacing', 'showOverflow', 'sx'].includes(prop as string),
})<{ maxLines: number; lineHeight: number; spacing: number; showOverflow: boolean }>(
  ({ theme, maxLines, lineHeight, spacing, showOverflow }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
    lineHeight: `${lineHeight}px`,
    width: '100%',

    ...(!showOverflow && {
      overflow: 'hidden',
      maxHeight: lineHeight * maxLines,

      '& > *:not(:last-child)': {
        marginRight: theme.spacing(spacing),
      },
    }),

    ...(showOverflow && {
      gap: theme.spacing(spacing),
    }),

    ...(maxLines === 1 && {
      alignItems: 'center',
    }),
  })
)

const StyledChildContainer = styled('span', {
  shouldForwardProp: (prop) => prop !== 'lineHeight',
})<{ lineHeight: number }>(({ lineHeight }) => ({
  display: 'inline-flex',
  alignItems: 'start',
  height: lineHeight,
  [`&.${overflowContainerClasses.hidden}`]: {
    display: 'none',
  },
}))

const StyledCountChip = styled(Chip)({
  [`&.${overflowContainerClasses.hidden}`]: {
    display: 'none',
  },
})

const StyledTooltipTitle = styled('div', {
  shouldForwardProp: (prop) => prop !== 'spacing',
})<{ spacing: number }>(({ theme, spacing }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(spacing),
}))

export interface OverflowContainerProps {
  maxLines?: number
  maxChildren?: number
  lineHeight?: number
  spacing?: number
  chipSize?: 'small' | 'medium'
  disableObserver?: boolean
  disableCountChip?: boolean
  disableChipCountTooltip?: boolean
  showAllOnClick?: boolean
  sx?: SxProps
  countChipProps?: ChipProps
  className?: string
}

function show(element: HTMLElement | null) {
  if (element) {
    element.classList.add(overflowContainerClasses.visible)
    element.classList.remove(overflowContainerClasses.hidden)
  }
}

function hide(element: HTMLElement | null) {
  if (element) {
    element.classList.add(overflowContainerClasses.hidden)
    element.classList.remove(overflowContainerClasses.visible)
  }
}

export default function OverflowContainer({
  children,
  maxLines = 2,
  lineHeight = 22,
  maxChildren,
  spacing = 1,
  chipSize = 'small',
  disableObserver = false,
  disableChipCountTooltip = false,
  disableCountChip = false,
  showAllOnClick = false,
  sx,
  countChipProps = {},
  className,
}: PropsWithChildren<OverflowContainerProps>) {
  const childrenArray = React.Children.toArray(children)

  const rootRef = useRef<HTMLDivElement>(null)
  const childrenRef = useRef<HTMLElement[]>([])
  const chipRef = useRef<HTMLDivElement>(null)
  const [showOverflow, setShowOverflow] = useState(false)
  const nrOfHiddenChildrenRef = useRef(0)
  const [, forceRerender] = useReducer((acc) => acc + 1, 0)

  // If there are many children, delay the rendering until later to speed up the initial render.
  // This is especially noticeable when the data is preloaded, and you're navigating between tabs.
  const [shouldRender, setShouldRender] = useState(
    maxChildren ? childrenArray.length < maxChildren : true
  )
  if (!shouldRender) {
    startTransition(() => {
      setShouldRender(true)
    })
  }

  const isElementVisible = useCallback(
    (element: HTMLElement) => {
      return element.offsetTop / lineHeight < maxLines
    },
    [lineHeight, maxLines]
  )

  const handleChildRef = useCallback(
    (index: number) => (element: HTMLElement) => {
      childrenRef.current[index] = element
    },
    []
  )

  const updateChipCountLabel = useCallback(
    (label: string) => {
      // Only update the chip text if no label was provided through countChipProps
      if (!countChipProps.label && chipRef.current?.firstChild) {
        chipRef.current.firstChild.textContent = label
      }
    },
    [countChipProps.label]
  )

  const handleResize = useCallback(() => {
    // Reset visibility of children and count chip to make sure we can determine visibility correctly
    childrenRef.current.forEach(show)
    hide(chipRef.current)
    nrOfHiddenChildrenRef.current = 0

    // After a user has clicked "show all", there is no need to recalculate visibility;
    // we show all children anyway.
    if (showOverflow) {
      return
    }

    // If maxChildren is set, the excess children will not be rendered at all,
    // so add them to hidden count.
    const maxChildrenHiddenCount = childrenArray.length - (maxChildren ?? 0)

    if (maxChildren && childrenArray.length > maxChildren) {
      nrOfHiddenChildrenRef.current += maxChildrenHiddenCount
    }

    // First, hide all the children that are not visible
    childrenRef.current
      .slice()
      .reverse()
      .forEach((child) => {
        if (child && !isElementVisible(child)) {
          hide(child)
          nrOfHiddenChildrenRef.current++
        }
      })

    // We're done when all children are visible
    if (nrOfHiddenChildrenRef.current === 0) {
      return
    }

    // Otherwise, we need to make sure that the count chip is visible
    if (chipRef.current && chipRef.current.firstChild) {
      updateChipCountLabel(`+${nrOfHiddenChildrenRef.current}`)
      show(chipRef.current)

      // Keep hiding children until the count chip is the last visible item
      while (!isElementVisible(chipRef.current)) {
        const lastVisibleIndex = childrenRef.current.findIndex((child) =>
          child.classList.contains(overflowContainerClasses.visible)
        )

        hide(childrenRef.current[lastVisibleIndex])
        nrOfHiddenChildrenRef.current++
        updateChipCountLabel(`+${nrOfHiddenChildrenRef.current}`)
      }
    }

    forceRerender()
  }, [showOverflow, maxChildren, childrenArray.length, isElementVisible, updateChipCountLabel])

  // When showAllOnClick is true, the count chip is clickable and will show all children
  const handleShowAllClick = useCallback(
    (event: MouseEvent) => {
      if (!showAllOnClick) {
        return
      }

      event.preventDefault()
      event.stopPropagation()
      setShowOverflow(true)
    },
    [showAllOnClick, setShowOverflow]
  )

  // Recalculate on resize
  useResizeObserver(disableObserver ? null : rootRef, handleResize)

  // If the resize observer is disabled, we need to trigger handleResize manually on init
  useEffect(() => {
    if (disableObserver) {
      handleResize()
    }
  }, [disableObserver, handleResize])

  // When there are many children, we delay the rendering
  if (!shouldRender) {
    return null
  }

  return (
    <StyledRoot
      ref={rootRef}
      className={className}
      lineHeight={lineHeight}
      maxLines={maxLines}
      showOverflow={showOverflow}
      spacing={spacing}
      sx={sx}>
      {childrenArray.slice(0, showOverflow ? undefined : maxChildren).map((child, index) => (
        <StyledChildContainer
          key={index}
          ref={handleChildRef(index)}
          className={overflowContainerClasses.child}
          lineHeight={lineHeight}>
          {child}
        </StyledChildContainer>
      ))}
      {!disableCountChip && (
        <Tooltip
          title={
            !disableChipCountTooltip && (
              <StyledTooltipTitle spacing={spacing}>
                {childrenArray.slice(childrenArray.length - nrOfHiddenChildrenRef.current)}
              </StyledTooltipTitle>
            )
          }
          variant={'preview'}>
          <StyledCountChip
            ref={chipRef}
            clickable={Boolean(showAllOnClick)}
            label={'+0'}
            onClick={handleShowAllClick}
            size={chipSize}
            variant={'outlined'}
            {...countChipProps}
          />
        </Tooltip>
      )}
    </StyledRoot>
  )
}
