/**
 * Determines the number of decimal places to use for axis labels based on the
 * given value, the range of values, and the number of splits.
 *
 * For linear graphs we want to generate the following types of labels:
 * - 1k, 2k, 3k, 4k
 * - 1k, 1.5k, 2k, 2.5k
 * - 600k, 700k, 800k, 900k, 1m, 1.1m
 */
export function determineAxisLabelDecimals(value: number): number {
  // 1000 => 1k, 1000000 => 1m, etc
  let scaledValue = value
  while (scaledValue > 1000) {
    scaledValue /= 1000
  }

  // If there is a remainder, we need to add a decimal
  const remainder = scaledValue % 1
  return remainder !== 0 ? 1 : 0
}
