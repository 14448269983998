import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'

const listHeaderClasses = generateUtilityClasses('ListHeader', ['disableBorderTop'])

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  padding: theme.spacing(0, 3),
  borderTop: `1px solid ${theme.palette.divider}`,
  borderBottom: `1px solid ${theme.palette.divider}`,
  height: 38,
  [`&.${listHeaderClasses.disableBorderTop}`]: {
    borderTop: 'none',
  },
}))

export interface ListHeaderProps {
  title: string
  secondaryTitle: string
  disableBorderTop?: boolean
  className?: string
}

export default function ListHeader({
  title,
  secondaryTitle,
  disableBorderTop,
  className,
}: ListHeaderProps) {
  return (
    <StyledRoot
      className={clsx(className, {
        [listHeaderClasses.disableBorderTop]: disableBorderTop,
      })}>
      <span>{title}</span>
      <span>{secondaryTitle}</span>
    </StyledRoot>
  )
}
