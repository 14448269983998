import Flag from '@gain/components/flag'
import { AssetListItem } from '@gain/rpc/app-model'
import { ColumnVisibilityModel } from '@gain/utils/table'
import { styled } from '@mui/material/styles'
import React from 'react'

import { ColumnConfig } from '../../../../common/table'
import AssetContentLink from '../../../../features/asset/asset-content-link'
import FinancialValue from '../../../../features/financial/financial-value'

const StyledFlagContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
})

export const columnVisiblitity: ColumnVisibilityModel<AssetListItem> = {
  description: 650,
  region: 448,
}

export const assetCompetitorsColumns: Array<ColumnConfig<AssetListItem>> = [
  {
    headerName: 'Name',
    field: 'name',
    renderCell: ({ row }) => (
      <AssetContentLink
        id={row.id}
        logoFileUrl={row.logoFileUrl}
        name={row.name}
      />
    ),
  },
  {
    headerName: 'Business description',
    field: 'description',
    width: 240,
  },
  {
    field: 'revenueEur',
    headerName: 'Revenue',
    align: 'right',
    width: 130,
    renderCell: ({ value, row }) => (
      <FinancialValue
        amount={value}
        year={row.revenueYear}
      />
    ),
  },
  {
    field: 'region',
    headerName: 'Company HQ',
    align: 'right',
    width: 120,
    renderCell: ({ value }) =>
      value ? (
        <StyledFlagContainer>
          <Flag code={value} />
        </StyledFlagContainer>
      ) : (
        <>-</>
      ),
  },
]
