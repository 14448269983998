import { useContext } from 'react'

import { CardContext } from './card-context'

export default function useCardContext() {
  const context = useContext(CardContext)

  if (!context) {
    throw new Error('CardContext not provided')
  }

  return context
}
