import Flag from '@gain/components/flag'
import { LegalEntity } from '@gain/rpc/app-model'
import { getAnnualReportYear } from '@gain/utils/annual-report'
import { compareNumberDesc, isTruthy } from '@gain/utils/common'
import { useElementWidth } from '@gain/utils/dom'
import { compareLegalEntitiesByLastArYearDesc, formatExternalId } from '@gain/utils/legal-entity'
import { parseJSON } from 'date-fns/parseJSON'
import { uniq } from 'lodash'
import { useMemo, useRef } from 'react'

import Table, { ColumnConfig } from '../../../../common/table'
import LegalEntityLink from '../../../../features/legal-entity/legal-entity-content-link'
import AssetLegalEntitiesTableAllAnnualReportsCell from './asset-legal-entities-table-all-annual-reports-cell'
import AssetLegalEntitiesTableAnnualReportCell from './asset-legal-entities-table-annual-report-cell'
import { AssetLegalEntitiesTableEarlierAnnualReportsCell } from './asset-legal-entities-table-earlier-annual-reports-cell'

function useAssetLegalEntitiesTableColumns(width: number, rows: LegalEntity[]) {
  return useMemo(() => {
    const isSmall = width <= 552
    const nameCellMinWidth = 208
    const regionCellWidth = 64
    const externalIdCellWidth = 124
    const allArsCellWidth = 140
    const arCellWidth = 116

    const availableWidth =
      width - nameCellMinWidth - regionCellWidth - externalIdCellWidth - allArsCellWidth
    const availableArColumns = Math.floor(availableWidth / arCellWidth)
    const arYears = uniq(
      rows.flatMap((row) => row.annualReports.map((ar) => parseJSON(ar.bookYearEnd).getFullYear()))
    ).sort(compareNumberDesc)
    const yearColumns =
      availableArColumns > 1 ? arYears.slice(0, availableArColumns - 1).reverse() : []
    const remainingArYears = availableArColumns > 1 ? arYears.slice(availableArColumns - 1) : []

    return new Array<ColumnConfig<LegalEntity> | false>(
      {
        field: 'name',
        headerName: 'Entity',
        renderCell: ({ row }) => (
          <LegalEntityLink
            id={row.id}
            name={row.name}
          />
        ),
      },
      !isSmall && {
        field: 'region',
        headerName: 'Region',
        align: 'left',
        width: regionCellWidth,
        renderCell: ({ value }) => <Flag code={value} />,
      },
      {
        field: 'externalId',
        headerName: 'Entity number',
        align: isSmall ? 'right' : 'left',
        width: externalIdCellWidth,
        valueFormatter: ({ value, row }) => formatExternalId(value, row.region),
      },
      remainingArYears.length > 0 && {
        field: 'annualReports',
        headerName: 'Earlier ARs',
        width: arCellWidth,
        renderCell: ({ value }) => (
          <AssetLegalEntitiesTableEarlierAnnualReportsCell
            annualReports={value.filter((ar) => remainingArYears.includes(getAnnualReportYear(ar)))}
          />
        ),
      },
      ...yearColumns.map(
        (year) =>
          ({
            field: 'annualReports',
            headerName: `AR ${year}`,
            width: arCellWidth,
            renderCell: ({ row }) => (
              <AssetLegalEntitiesTableAnnualReportCell
                annualReports={row.annualReports.filter(
                  (ar) => parseJSON(ar.bookYearEnd).getFullYear() === year
                )}
              />
            ),
          } as ColumnConfig<LegalEntity>)
      ),
      !isSmall &&
        !!arYears.length && {
          field: 'annualReports',
          headerName: 'All ARs',
          width: allArsCellWidth,
          renderCell: ({ row }) => (
            <AssetLegalEntitiesTableAllAnnualReportsCell legalEntity={row} />
          ),
        }
    ).filter(isTruthy)
  }, [width, rows])
}

export interface AssetLegalEntitiesTableProps {
  legalEntities: LegalEntity[]
}

export default function AssetLegalEntitiesTable({ legalEntities }: AssetLegalEntitiesTableProps) {
  const ref = useRef<HTMLDivElement>(null)
  const width = useElementWidth(ref)
  const rows = useMemo(
    () => legalEntities.slice().sort(compareLegalEntitiesByLastArYearDesc),
    [legalEntities]
  )
  const columns = useAssetLegalEntitiesTableColumns(width, rows)

  return (
    <Table
      ref={ref}
      columns={columns}
      rows={rows}
    />
  )
}
