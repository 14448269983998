import { CountryCode } from './country-code-map'
import { isCountryCode, isCountryCodeArray } from './format-scope-and-countries'

export interface Region {
  name: string
  options: CountryCode[] | Subregion[]
}

export interface Subregion {
  name: string
  options: CountryCode[]
}

export const REGIONS: ReadonlyArray<Region> = [
  {
    name: 'Americas',
    options: [
      { name: 'US', options: ['US'] },
      { name: 'Canada', options: ['CA'] },
      { name: 'LATAM', options: ['AR', 'BR', 'CL', 'CO', 'MX'] },
    ],
  },
  {
    name: 'Europe',
    options: [
      { name: 'UK & Ireland', options: ['GB', 'IE'] },
      { name: 'DACH', options: ['DE', 'AT', 'CH'] },
      { name: 'Benelux', options: ['NL', 'BE', 'LU'] },
      { name: 'France', options: ['FR'] },
      { name: 'Italy', options: ['IT'] },
      { name: 'Iberia', options: ['ES', 'PT'] },
      { name: 'Nordics', options: ['SE', 'NO', 'DK', 'FI'] },
      {
        name: 'CEE',
        options: ['BG', 'HR', 'CZ', 'EE', 'HU', 'LV', 'LT', 'PL', 'RO', 'RS', 'SK', 'SI'],
      },
    ],
  },
  {
    name: 'APAC',
    options: [
      {
        name: 'Greater China',
        options: ['CN', 'HK', 'TW'],
      },
      {
        name: 'Asia Pacific',
        options: ['AU', 'BD', 'IN', 'ID', 'JP', 'MY', 'NZ', 'PK', 'PH', 'SG', 'KR', 'TH', 'VN'],
      },
    ],
  },
  {
    name: 'Rest of world',
    options: ['EG', 'IR', 'IL', 'NG', 'RU', 'ZA', 'TR', 'AE'],
  },
]

/**
 * Retrieves the region name associated with a given country code.
 * If the country code is not found, it defaults to "Rest of world". If the
 * country code is invalid it returns null.
 */
export function getRegionForCountryCode(countryCode?: CountryCode | string | null): string | null {
  // Check if the provided countryCode is valid
  if (!isCountryCode(countryCode)) {
    return null
  }

  // Iterate through the regions to find the matching countryCode
  for (const region of REGIONS) {
    // Handle regions with a direct list of country codes
    if (isCountryCodeArray(region.options)) {
      if (region.options.includes(countryCode)) {
        return region.name
      }
    } else {
      // Handle regions with subregions
      for (const subregion of region.options) {
        if (subregion.options.includes(countryCode)) {
          return subregion.name
        }
      }
    }
  }

  // Return "Rest of world" for country codes not found in any region
  return 'Rest of world'
}
