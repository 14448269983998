import Typography from '@gain/components/typography'
import { CurrencyListItem, LegalEntity } from '@gain/rpc/app-model'
import {
  FinancialResult,
  useDefinedAmountTypes,
  useFinancialsWithMissingYears,
} from '@gain/utils/financials'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'

import Card, { CardHeader, CardHeaderProps, CardSubHeader } from '../../../common/card/card'
import ContentLink from '../../../common/content-link'
import InfoButton from '../../../common/info-button'
import { generateLegalEntityPagePath } from '../../../routes/utils'
import CurrencyToggle from '../../currency/currency-toggle'
import {
  ExportButton,
  ExportContainerMethod,
  ExportContainerProps,
} from '../../export/export-button'
import { useVisibleFinancials } from '../financial-results-table/financial-results-table-hooks'
import FinancialsTable from './financials-table'

const StyledCard = styled(Card)({
  paddingBottom: 0,
})

const StyledCardHeader = styled(CardHeader)<CardHeaderProps>(({ theme }) => ({
  padding: theme.spacing(2, 2, 1, 3),

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1.5, 2, 0, 2),
  },
}))

export interface FinancialsCardProps<ExportMethod extends ExportContainerMethod> {
  dataCurrency?: string | null
  financialResults: FinancialResult[]
  financialsFromEntity?: LegalEntity
  automated?: boolean
  exportContainerProps?: Omit<ExportContainerProps<ExportMethod>, 'children'>
  currency: CurrencyListItem | null
  onCurrencyChange: (newCurrency: CurrencyListItem) => void
}

export default function FinancialsCard<ExportMethod extends ExportContainerMethod>({
  dataCurrency,
  financialResults,
  financialsFromEntity,
  automated,
  exportContainerProps,
  currency,
  onCurrencyChange,
}: FinancialsCardProps<ExportMethod>) {
  const financialsWithMissingYears = useFinancialsWithMissingYears(financialResults)
  const visibleFinancials = useVisibleFinancials(financialsWithMissingYears)
  const usedAmountTypes = useDefinedAmountTypes(visibleFinancials)

  return (
    <StyledCard>
      <StyledCardHeader
        actions={
          <Stack
            direction={'row'}
            gap={1}>
            <CurrencyToggle
              dataCurrency={dataCurrency}
              onChange={onCurrencyChange}
              value={currency}
            />
            {usedAmountTypes.hasActuals && exportContainerProps && (
              <ExportButton
                {...exportContainerProps}
                color={'primary'}
                tooltipMode={'always'}
              />
            )}
          </Stack>
        }
        title={
          <Stack
            alignItems={'center'}
            direction={'row'}
            gap={1}>
            <Typography
              color={'text.primary'}
              variant={'h5'}
              noWrap>
              Financials
            </Typography>

            {automated && (
              <InfoButton
                color={'warning'}
                dialogMessage={
                  'The financials are automatically retrieved from available annual reports.'
                }
                dialogTitle={'Financials source'}
                label={'Automated'}
                size={'medium'}
              />
            )}
          </Stack>
        }
      />

      {financialsFromEntity && (
        <CardSubHeader disableGuttersBottom>
          <Typography
            color={'text.secondary'}
            display={'flex'}
            flexDirection={'row'}
            variant={'body2'}>
            <Stack direction={['column', 'row']}>
              The financials are retrieved from:&nbsp;
              <Stack
                component={'span'}
                direction={'row'}>
                <ContentLink
                  href={generateLegalEntityPagePath({
                    id: financialsFromEntity.id,
                    name: financialsFromEntity.name,
                  })}
                  label={financialsFromEntity.name}
                />
                <InfoButton
                  dialogMessage={
                    'The financials are automatically taken from a legal entity that has the same website as the company, and is located in the same region. If there are multiple entities, we use the one that has the most consolidated financials.'
                  }
                  dialogTitle={'Financials source'}
                />
              </Stack>
            </Stack>
          </Typography>
        </CardSubHeader>
      )}

      {currency && (
        <FinancialsTable
          currency={currency}
          financials={visibleFinancials}
        />
      )}
    </StyledCard>
  )
}
