import {
  useCurrencyListItems,
  useUpdateBookmarksFilteredLastViewedAt,
  useUpdateBookmarksListLastViewedAt,
} from '@gain/api/app/hooks'
import { useUserProfileContext } from '@gain/modules/user'
import { PropsWithChildren, useEffect } from 'react'

import { setUserProperties } from '../features/google-analytics'
import useSaveRecentlyFilteredBookmarks from './bookmarks/route-filtered/use-save-recently-filtered'

/**
 * Triggers requests to warm up the SWR cache
 */
export default function InitialDataLoader({ children }: PropsWithChildren) {
  // RouteListeners registers route change listeners that auto-update
  // backend state during certain navigation events.
  useUpdateBookmarksListLastViewedAt()
  useUpdateBookmarksFilteredLastViewedAt()
  useSaveRecentlyFilteredBookmarks()

  const currencyList = useCurrencyListItems()
  const userProfile = useUserProfileContext()

  // Set google analytics user properties
  useEffect(() => {
    setUserProperties(userProfile)

    return () => {
      // When leaving private routes clear user properties in GA
      setUserProperties(null)
    }
  }, [userProfile])

  if (!userProfile || !currencyList || currencyList.loading) {
    // Don't show loader, it causes too many layout shifts and distracts the user
    return null
  }

  return children
}
